<template>
  <div class="mt-3" id="odinIndicadorRealAtoProcessamento">

    <card :loading-full="loading">
      <card-content v-if="!loading">

        <div class="alert alert-outline-danger mb-3 w-100 small" v-if="atosSemTexto.length > 0">
          Antes de realizar a análise via IA dos atos, certifique-se de que todos eles estejam cadastrados e com o texto correto.
        </div>

        <div class="d-flex">
          <div class="mr-auto">
            <p class="text-muted pb-2 mb-2 small">
              <template v-if="atosSelecionados.length > 0">{{atosSelecionados.length}} ato(s) selecionado(s)</template>
              <template v-else>Nenhum ato selecionado</template>
            </p>
          </div>
          <v-button :run="processarAtos" :button="true" v-if="atosSelecionados.length > 0"
                    :popover="true" position="dropleft" class="btn btn-sm btn-outline-success icon ml-auto">
            Processar Atos
          </v-button>
        </div>

        <card-table class="table-striped mb-2">
          <thead>
            <tr>
              <th style="width: 40px">
                <i v-if="atosSelecionados.length > 0" @click="toggleSelectAll(false)"
                   title="Limpar Seleção" class="pointer fa-solid fa-circle-check text-success"></i>
                <i v-else @click="toggleSelectAll(true)"
                   title="Selecionar Todos" class="pointer fa-solid fa-circle-dashed"></i>
              </th>
              <th style="width: 40px">Número</th>
              <th style="width: 45px">Tipo</th>
              <th class="">Data</th>
              <th class="">Título</th>
              <th></th>
              <th class=""style="width: 70px"></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="ato in atos">
              <tr>

                <td class="text-center">
                  <i v-if="ato.validado" class="pointer fa-solid fa-check-circle text-success" title="Ato Já Validado no Sistema"></i>
                  <i v-else-if="ato.selecionado" @click="$set(ato, 'selecionado', false)" class="pointer fa-solid fa-circle-check text-primary"></i>
                  <i v-else @click="$set(ato, 'selecionado', true)" class="pointer fa-solid fa-circle-dashed"></i>
                </td>

                <td class="text-center">{{ato.codigo}}</td>

                <td class="text-center">{{ato.tipo == 'AVERBACAO' ? 'AV' : ato.tipo.charAt(0)}}</td>
                <td>{{$formatters.date(ato.registro)}}</td>
                <td class="small">{{ato.titulo || '-'}}</td>

                <td class="lborder text-center text-success" v-if="ato.possuiTexto" title="Possui texto cadastrado"><i class="fa-solid fa-text"></i></td>
                <td class="lborder text-center text-danger" v-else title="Não possui texto cadastrado"><i class="fa-solid fa-text-slash"></i></td>

                <td class="lborder">
                  <template v-if="!ato.validado && ato.possuiTexto">
                    <i class="ml-2 fa-solid fa-microchip-ai pointer" @click.prevent="abrirAto(ato)"></i>
                  </template>
                </td>

              </tr>
            </template>
          </tbody>
        </card-table>
      </card-content>

    </card>

  </div>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import ModalOdinProcessamentoAto from "@/views/Odin/modais/ModalOdinProcessamentoAto.vue";
import OdinBusiness from "@/views/Odin/OdinBusiness";

export default {
  name: "OdinIndicadorRealAtosProcessamento",
  components: {CardTable, CardContent, Card},
  props: {
    indicadorRealId: String,
    dataBus: {
      type: Object,
      request: true
    }
  },
  data() {
    return {
      loading: true,
      textos: [],
      atos:[],
      ctrl:{
        toggleSelecao:false
      }
    };
  },
  computed:{
    atosSelecionados(){
      return this.atos?.filter(r => !!r.selecionado)
    },
    atosSemTexto(){
      return this.atos?.filter(r => !r.possuiTexto)
    },
  },
  async mounted() {
    await this.load();
  },
  methods:{
    async load(){
      this.$set(this, 'loading', true);
      const atos = await this.$api.get(`api/odin/acompanhamento-matriculas/${this.indicadorRealId}/atos`)
        .catch(e => FrontBusiness.showError('', e.message));
      this.$set(this, 'atos', atos);
      this.$set(this, 'loading', false);
    },

    toggle(key, ato, valor){
      this.$set(ato, key, valor);
    },

    abrirAto(ato){
      FrontBusiness.openModal(ModalOdinProcessamentoAto,
        {atoId: ato.id, indicadorRealId: this.indicadorRealId});
    },

    toggleSelectAll(result){
        this.atos.forEach(ato => {
          this.$set(ato, 'selecionado', result);
        });
    },

    async processarAtos(){
      for(let pos in this.atosSelecionados) {
        const id = this.atosSelecionados[pos]?.id;
        if(id){
          await OdinBusiness.realizarExtracaoAto(id, false).catch(r => {
            FrontBusiness.showError('', r.message);
          });
        }
      }

      FrontBusiness.showSuccess('', 'Atos Processados');

      this.load();

    }

  }
};
</script>

<style lang="scss">
#odinIndicadorRealAtoProcessamento{
  th.rborder, td.rborder{
    border-right: 1pt solid gray;
  }
  th.lborder, td.lborder{
    border-left: 2pt solid var(--asgard-input);
  }
}
</style>
