<template>
  <modal :titulo="`Odin - Processamento de Matrícula - ${titulo}`" id="odin-processamento-ato"
         :fechar="true" @close="close" :loading="loading">

    <template  v-if="!loading">

      <card>
        <card-content>

          <p><span class="font-weight-500">{{titulo}}</span></p>
          <p><span class="font-weight-500">CNM: </span><span>{{ficha.cnm}}</span></p>
          <p v-if="ficha.livroLetra"><span class="font-weight-500">Livro (Letra): </span><span>{{ficha.livroLetra}}</span></p>
          <p><span class="font-weight-500">Último Ato: </span><span>{{ficha.ultimoAto}}</span></p>

          <hr/>

          <p><span class="font-weight-500">Atos Registrados no Sistema: </span>
            <span>{{dataBus.info.atosRegistrados}} [Códigos {{dataBus.info.menorAtoRegistrado}} até {{dataBus.info.maiorAtoRegistrado}}]</span></p>
          <p><span class="font-weight-500">Atos Marcados como Validados: </span><span>{{dataBus.info.atosValidados}}</span></p>
          <p><span class="font-weight-500">Atos Sem Texto: </span><span>{{dataBus.info.atosSemTexto}}</span></p>
          <p><span class="font-weight-500">Digitalizações: </span>
            <span>{{dataBus.info.digitalizacoes}}
              <router-link :to="{ name: 'digitalizacao', params: { id: indicadorRealId, entidade : 'INDICADOR_REAL', tipoEntidade : 'INDICADOR_REAL' }}"
                           target="_blank" title="Digitalização">
                <i class="fal fa-file-image"></i>
              </router-link>
            </span></p>
          <p><span class="font-weight-500">Digitalizações Sem OCR: </span><span>{{dataBus.info.digitalizacoesSemOcr}}</span></p>

          <hr/>

<!--          <template v-if="dataBus.info.digitalizacoesSemOcr == 0">-->
<!--            <v-button class="btn btn-outline-primary btn-sm mt-2" :run="run" :popover="true">-->
<!--              Processar Matrícula-->
<!--            </v-button>-->
<!--          </template>-->

        </card-content>
      </card>

      <template v-if="dataBus.info.digitalizacoesSemOcr > 0">

        <div class="alert alert-outline-danger my-2 w-100">
          Necessário realizar o OCR das digitalizações antes de dar continuidade.
          <div class="w-100"></div>

          <v-button v-if="!['PROCESSING', 'WAITING'].includes(dataBus.info.statusOcr)"
                    class="btn btn-outline-primary btn-sm mt-2" :run="runOcr" :popover="true">
            Agendar OCR
          </v-button>
          <span v-else>Processo em andamento</span>

        </div>

      </template>

      <card class="border-transparent" v-if="dataBus.info.digitalizacoesSemOcr == 0">

        <template #acoes>
          <ul class="nav nav-tabs">
            <li class="nav-item" v-for="menu in menus">
              <a class="nav-link" :class="{ active: activeTab === menu.id }"
                 href="#" @click.prevent="activeTab = menu.id">
                {{menu.nome}}
              </a>
            </li>
          </ul>
        </template>

        <card-content id="conteudo-processamento">
          <odin-indicador-real-ocr-processamento :data-bus="dataBus" v-if="activeTab === 'ocr'" :indicador-real-id="indicadorRealId" />
          <odin-indicador-real-atos-processamento :data-bus="dataBus" v-if="activeTab === 'atos'" :indicador-real-id="indicadorRealId" />
        </card-content>

      </card>

    </template>

  </modal>
</template>

<script>

import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import Modal from "@/components/Modal.vue";
import FrontBusiness from "@/business/FrontBusiness";
import SeeModal from "@/components/SeeModal.vue";
import OdinAtosMatricula from "@/views/Odin/componentes/OdinAtosMatricula.vue";
import OdinEnvolvidosAto from "@/views/Odin/componentes/OdinEnvolvidosAto.vue";
import TextExtractorBusiness from "@/views/Odin/componentes/TextExtractorBusiness";
import OdinEnvolvidosAtoProcessamentoAtoProcessamento
  from "@/views/Odin/modais/componentes/OdinEnvolvidosAtoProcessamento.vue";
import OdinIndicadorRealAtosProcessamento from "@/views/Odin/modais/componentes/OdinIndicadorRealAtosProcessamento.vue";
import OdinIndicadorRealOcrProcessamento from "@/views/Odin/modais/componentes/OdinIndicadorRealOcrProcessamento.vue";
import OdinIndicadorRealProcessamento from "@/views/Odin/modais/componentes/OdinIndicadorRealProcessamento.vue";

export default {
  name: 'ModalOdinProcessamentoMatricula',
  components: {
    OdinIndicadorRealAtosProcessamento,
    OdinIndicadorRealOcrProcessamento,
    OdinIndicadorRealProcessamento,
    OdinEnvolvidosAtoProcessamentoAtoProcessamento,
    OdinAtosMatricula, OdinEnvolvidosAto, Card, CardContent, Modal},
  props:{
    indicadorRealId: String
  },

  modal: {
    width: 1200
  },
  async mounted() {
    this.load();
  },

  data(){
    return {
      activeTab: "atos",
      menus: [
        {id: 'ocr', nome: 'OCR'},
        {id: 'atos', nome: 'Atos'},
        // {id: 'indicadorreal', nome: 'Indicador Real'},
        // {id: 'envolvidosmatricula', nome: 'Envolvidos do Ato'},
      ],
      loading: true,
      dataBus: {
        info: null,
        ficha: null,
      }
    }
  },

  computed:{
    titulo(){
      return this.ficha?.livro ? FrontBusiness.nomearFicha(this.ficha) : '';
    },
    ficha(){
      return this.dataBus.ficha;
    }
  },

  methods:{
    async close(retorno) {
      FrontBusiness.closeModal(retorno);
    },

    async run(){
      this.$set(this, 'loading', true);
      //
      // await TextExtractorBusiness.extrairMatricula('atos-matricula','', this.indicadorRealId)
      //   .then(resp => this.$set(this.dataBus, 'atomatricula', resp))
      //   .catch(e => { FrontBusiness.showError('', e.message); return null})
      //
      // await TextExtractorBusiness.extrairMatricula('indicador-real','', this.indicadorRealId)
      //   .then(resp => this.$set(this.dataBus, 'indicadorreal', resp))
      //   .catch(e => { FrontBusiness.showError('', e.message); return null})

      this.$set(this, 'loading', false);

    },

    async load(){

      const p1 = IndicadorRealBusiness.getById(this.indicadorRealId)
        .then(r => this.$set(this.dataBus, 'ficha', r));

      const p2 = this.$api.get(`api/odin/acompanhamento-matriculas/${this.indicadorRealId}`)
        .then(r => this.$set(this.dataBus, 'info', r));

      const p3 = this.statusOcr();

      await Promise.all([p1, p2, p3]);

      this.loading = false;
    },

    async statusOcr(){
      // return TextExtractorBusiness.getStatus('ocr-digitalizacoes-matricula', this.indicadorRealId)
      //   .then(r => this.$set(this.dataBus, 'statusOcr', r));
    },

    async runOcr(){
      this.$set(this, 'loading', true);
      await FrontBusiness.openModal(SeeModal, {titulo : 'OCR Matrícula', url : TextExtractorBusiness.ocrMatricula(this.indicadorRealId)});;
      await this.load();
    },

    showError(e) {
      FrontBusiness.showError('', e.message);
    },
  }
}
</script>
