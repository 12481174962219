import AnexoBusiness from "@/business/AnexoBusiness.js";
import EnderecoBusiness from "@/business/EnderecoBusiness";
import RestricaoBusiness from "@/business/RestricaoBusiness";
import CachedApi from "@/commons/CachedApi.js";
import Http from "@/commons/Http";
import Utils from "@/commons/Utils";
import VueBusiness from "@/business/VueBusiness";

const path = 'api/indicador-real';

const tratarAto = (a) => {
  if(a.tipoServico) a.tipoServico = JSON.parse(a.tipoServico);
  if(a.tipoServico?.opcoes && typeof a.tipoServico?.opcoes === 'string'){
    a.tipoServico.opcoes = JSON.parse(a.tipoServico.opcoes || false)
  }
  if(a.validador) a.validador = JSON.parse(a.validador);
  if(a.protocolo) a.protocolo = JSON.parse(a.protocolo);
  return a;
};

export default {

  async gerarCNM(id) {
    return Http.post(`${path}/${id}/gerar-cnm`);
  },

  async pagination(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/pagination?sort=${sort || 'i.codigo'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async paginationV2(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/v2/pagination?sort=${sort || 'i.codigo'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async getById(id){
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/${id}`);
  },

  async delete(id){
    return Http.delete(`${path}/${id}`);
  },

  async atosRegistrados(id){
    return Http.get(`${path}/${id}/atos`).then(l => l.map(tratarAto));
  },

  async buscarAtosRegistrados(id){
    return Http.get(`${path}/${id}/atos-registrados`).then(l => l.map(tratarAto));
  },

  async buscarUltimaAuditoriaImpressaoAto(id){
    return Http.get(`${path}/${id}/ultima-auditoria-impressao`);
  },

  async verificarAtosImpressao(id){
    return Http.get(`${path}/${id}/atos-impressao`);
  },

  async ultimoAtoRegistrado(id){
    return Http.get(`${path}/${id}/ultimo-ato-registrado`).then(a => tratarAto(a));
  },

  async historico(id, page, size){
    return Http.get(`${path}/${id}/historico?page=${page || 1}&size=${size || 10}`);
  },

  async cancelarUltimoAto(indicadorRealId) {
    return await Http.post(`${path}/${indicadorRealId}/ato/cancelar-ultimo-ato`);
  },

  async setImpressao(id, imprimindo) {
    return Http.post(`${path}/${id}/ato-impressao/${imprimindo}`);
  },

  async buscarProtocolosAtivos(id){
    return Http.get(`${path}/${id}/protocolos-ativos`);
  },

  async buscarProtocolos(id){
    return Http.get(`${path}/${id}/protocolos`);
  },

  async listarUnidades(id, sort, direction){
    return Http.get(`${path}/${id}/unidades?sort=${sort || 'ordem'},${direction || 'asc'}`);
  },

  async buscarExProprietarios(id){
    return Http.get(`${path}/${id}/ex-proprietarios`);
  },

  async proprietariosAtuais(id){
    return Http.get(`${path}/${id}/proprietarios`);
  },

  async restricoesVigentes(id){
    return Http.get(`${path}/${id}/restricoes-vigentes`);
  },

  async restricoes(id, registroAto){
    if(Utils.isEmptyNullOrUndefined(registroAto)) registroAto = '';
    return Http.get(`${path}/${id}/restricoes/${registroAto}`).then(r => r.map(RestricaoBusiness.tratarRestricoes));
  },

  async buscarAtosPorVersaoIndicadorPessoal(indicadorRealId, indicadorPessoalId){
    return Http.get(`${path}/${indicadorRealId}/${indicadorPessoalId}/atos-por-versao-indicador-pessoal`);
  },

  async possuiIndisponibilidades(id) {
    return CachedApi.getCached('indisponibilidades-'+id, async () => {
      return Http.get(`${path}/${id}/possui-indisponibilidades`);
    });
  },

  async save(id, dto, origemId = '') {
    if (!id) {
      return Http.post(`${path}/lote/1/${origemId}`, dto);
    }
    return Http.post(`${path}/${id}`, dto);
  },

  async validarDadosCadastroIndicador(indicador){
    return Http.post(`${path}/validar-dados-cadastro-indicador/`, indicador);
  },

  async validarDadosCadastroIndicadorConfirmacao(indicador, ci, qlb){
    return Http.post(`${path}/validar-dados-cadastro-indicador-confirmacao/${ci}/${qlb}`, indicador);
  },


  async lote(dto) {
    return Http.post(`${path}/lote`, dto);
  },

  async editarTextoLegado(id, dto) {
    return Http.post(`${path}/${id}/texto-legado`, dto);
  },

  async saveImportacao(origemId, file) {
    const formData = new FormData();
    formData.append("file", file);

    let config = {
      timeout: 0,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    return Http.post(`${path}/lote-importacao/${origemId}`, formData, config);
  },

  async criarTranscricao(dto) {
    return Http.post(`${path}/transcricao`, dto);
  },

  async criarLote(quantidade, dto) {
    return Http.post(`${path}/lote/${quantidade}`, dto);
  },

  async criarLoteArquivo(file) {

    const formData = new FormData();
    formData.append("file", file);

    let config = {
      timeout: 0,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    return Http.post(`${path}/lote-arquivo`, formData, config);
  },

  async getFichaPorCodigo(livro, codigo) {
    return Http.get(`${path}/codigo/${livro}/${codigo}`);
  },

  async getFichasPorCodigo(params = {}) {
    const {livro, status, dto, completo} = params;
    return await Http.post(`${path}/${completo ? 'completo-' : ''}codigos/${status}/${livro}`, dto);
  },

  async getFichasPorId(ids) {
    return await Http.post(`${path}/ids`, ids);
  },

  async sugerirServentia(query) {
    return Http.get(`${path}/sugerir/serventia/${query}`)
      .catch(e => e);
  },

  async removerUnidade(unidadeId) {
    return Http.post(`${path}/${unidadeId}/remover-unidade`);
  },

  async ordenarUnidades(id, unidades) {
    return Http.post(`${path}/${id}/unidades/ordenar`, unidades);
  },

  async digitalizacaoDesatualizada(id){
    return Http.get(`${path}/${id}/digitalizacao-desatualizada`);
  },

  async impressaoDesatualizada(id){
    return Http.get(`${path}/${id}/impressao-desatualizada`);
  },

  async indicadorRealValidado(id){
    return Http.get(`${path}/valido/${id}`);
  },

  async validaCadastroImoliliario(id, dto) {
    return Http.post(`${path}/valida-cadastro-imobiliario${id ? `/${id}` : ''}`, dto);
  },

  async validaQuadraLoteBairro(id, dto) {
    return Http.post(`${path}/valida-quadra-lote-bairro${id ? `/${id}` : ''}`, dto);
  },

  async getAtos(indicadorRealId, atoId = '', registrado = false, selado = false){
    const tratarObjeto = (item) => {
      item.ato = JSON.parse(item.ato);
      return item;
    };
    return Http.get(`${path}/atos-indicador/${indicadorRealId}/${atoId}/${registrado}/${selado}`).then(response => response.map(tratarObjeto));
  },

  async getVinculos(id){
    return Http.get(`${path}/${id}/vinculos`);
  },

  exportarRegistrosPosteriores(id, direction) {
    return `${path}/${id}/exportar-registros-posteriores?sort=codigo,${direction || 'desc'}`;
  },

  async sugerirBairro(query){
    return Http.get(`${path}/sugerir/bairro/${query}`);
  },

  async sugerirCampo(campo, query){
    if (query.trim().length < 3) {
      return null;
    }
    return Http.get(`${path}/sugerir/${campo}/${query}`);
  },

  getMedidas() {
    return [
      {id: 'PERCENTUAL', nome: '%', singular: 'inteiro', plural: 'inteiros', decimal: 'centésimo por cento', decimalPlural: 'centésimos por cento'},
      {id: 'M2', nome: 'm²', singular: 'metro quadrado', plural: 'metros quadrados', decimal: 'decímetro', decimalPlural: 'decímetros'},
      {id: 'HA', nome: 'hectare', singular: 'hectare', plural: 'hectares', decimal: 'are', decimalPlural: 'ares'},
      {id: 'ACRE', nome: 'acre', singular: 'acre', plural: 'acres', decimal: 'centésimo de acre', decimalPlural: 'centésimos de acre'},
      {id: 'ARE', nome: 'are', singular: 'are', plural: 'ares', decimal: 'centiare', decimalPlural: 'centiares'},
      {id: 'LITRO', nome: 'litro', singular: 'litro', plural: 'litros', decimal: 'centésimo de litro', decimalPlural: 'centésimos de litro'},
      {id: 'ALQUEIRE', nome: 'alqueire', singular: 'alqueire', plural: 'alqueires', decimal: 'centésimo de alqueire', decimalPlural: 'centésimos de alqueire'},
      {id: 'ALQUEIRE_PAULISTA', nome: 'alqueire paulista', singular: 'alqueire paulista', plural: 'alqueires paulista', decimal: 'centésimo de alqueire', decimalPlural: 'centésimos de alqueire'},
    ];
  },

  getMedidasUniao() {
    return [
      {id: 'M2', nome: 'm²', singular: 'metro quadrado', plural: 'metros quadrados', decimal: 'decímetro', decimalPlural: 'decímetros'},
      {id: 'KM2', nome: 'km²', singular: 'quilômetro quadrado', plural: 'quilômetros quadrado', decimal: ''},
      {id: 'HA', nome: 'hectare', singular: 'hectare', plural: 'hectares', decimal: 'are', decimalPlural: 'ares'},
    ];
  },

  getRegimesImovelUniao(){
    return [
      {id: 'FORO_OCUPACAO', nome: 'Foro/Ocupação'},
      {id: 'AFORAMENTO', nome: 'Aforamento'},
      {id: 'OCUPACAO', nome: 'Ocupação'},
      {id: 'CDRU', nome: 'CDRU'},
      {id: 'CUEM', nome: 'CUEM'},
      {id: 'TAU', nome: 'TAU'},
    ]
  },

  getTiposImovel() {
    return [
      {id: 'APARTAMENTO', nome: 'Apartamento'},
      {id: 'LOTE', nome: 'Lote'},
      {id: 'TERRENO', nome: 'Terreno'},
      {id: 'CASA', nome: 'Casa'},
      {id: 'FAZENDA', nome: 'Fazenda'},
      {id: 'SITIO', nome: 'Sítio'},
      {id: 'CHACARA', nome: 'Chácara'},
      {id: 'GALPAO', nome: 'Galpão'},
      {id: 'PREDIO_COMERCIAL', nome: 'Prédio Comercial'},
      {id: 'PREDIO_RESIDENCIAL', nome: 'Prédio Residencial'},
      {id: 'SALA_COMERCIAL', nome: 'Sala/Conjunto'},
      {id: 'LOJA', nome: 'Loja'},
      {id: 'ESCANINHO', nome: 'Escaninho'},
      {id: 'HOBBY_BOX', nome: 'Hobby Box'},
      {id: 'VAGA_GARAGEM', nome: 'Vaga de Garagem'},
      {id: 'BOXE_GARAGEM', nome: 'Box de Garagem'},
      {id: 'SALA', nome: 'Sala'},
      {id: 'CONJUNTO_SALAS', nome: 'Conjunto de Salas'},
      {id: 'SOBRELOJA', nome: 'Sobreloja'},
      {id: 'SALA_LOJA', nome: 'Sala ou Loja'},
      {id: 'CONSTRUCOES', nome: 'Construções'},
      {id: 'DESMEMBRAMENTO', nome: 'Desmembramento'},
      {id: 'LAJE', nome: 'Laje'},
      {id: 'ESTACIONAMENTO', nome: 'Estacionamento'},
      {id: 'BARRACO', nome: 'Barraco'},
      {id: 'OUTROS', nome: 'Outros'}
    ];
  },

  getCategorias() {
    return [
      {id: 'URBANO', nome: 'Urbano'},
      {id: 'RURAL', nome: 'Rural'}
    ];
  },

  getTiposReferencia() {
    return [
      {id: 'RESTRICAO', nome: 'Restrição'},
      {id: 'CANCELAMENTO_RESTRICAO', nome: 'Cancelamento de Restrição'},
      {id: 'ABERTURA', nome: 'Abertura de Matrícula'},
      {id: 'TRANSMISSAO', nome: 'Transmissão'},
      {id: 'MENCAO', nome: 'Menção'}
    ];
  },

  getPapeisEnvolvidos(params = {}) {
    const {documentacao, dominio} = params;
    let options = [
      {id: 'AUTOR', nome: 'Autor', dominio : ['PROTOCOLO_RI']},
      {id: 'REU', nome: 'Réu', dominio : ['PROTOCOLO_RI']},
      {id: 'ADQUIRENTE', nome: 'Adquirente', dominio : ['PROTOCOLO_RI', 'PROTOCOLO_RTD', 'PROTOCOLO_RCPJ', 'NOTIFICACAO', 'OFICIO', 'OCORRENCIA']},
      {id: 'AVALISTA_FIADOR', nome: 'Avalista Fiador', dominio : ['PROTOCOLO_RI']},
      {id: 'COMPROMISSADO', nome: 'Compromissado', dominio : ['PROTOCOLO_RI']},
      {id: 'CREDOR', nome: 'Credor', dominio : ['PROTOCOLO_RI', 'PROTOCOLO_RTD', 'PROTOCOLO_RCPJ', 'NOTIFICACAO', 'OFICIO', 'OCORRENCIA']},
      {id: 'CEDENTE', nome: 'Cedente', dominio : ['PROTOCOLO_RI', 'PROTOCOLO_RTD', 'PROTOCOLO_RCPJ']},
      {id: 'CESSIONARIO', nome: 'Cessionário', dominio : ['PROTOCOLO_RI', 'PROTOCOLO_RTD', 'PROTOCOLO_RCPJ']},
      {id: 'NU_PROPRIETARIO', nome: 'Nu-Proprietário', dominio : ['PROTOCOLO_RI']},
      {id: 'DEVEDOR', nome: 'Devedor', dominio : ['PROTOCOLO_RI', 'PROTOCOLO_RTD', 'PROTOCOLO_RCPJ', 'NOTIFICACAO', 'OFICIO', 'OCORRENCIA']},
      {id: 'GARANTIDOR', nome: 'Garantidor', dominio : ['PROTOCOLO_RI', 'PROTOCOLO_RTD', 'PROTOCOLO_RCPJ']},
      {id: 'EMITENTE', nome: 'Emitente', dominio : ['PROTOCOLO_RI']},
      {id: 'INTERESSADO', nome: 'Interessado', dominio : ['PROTOCOLO_RI', 'PROTOCOLO_RTD', 'PROTOCOLO_RCPJ', 'NOTIFICACAO', 'OFICIO', 'OCORRENCIA']},
      {id: 'ANUENTE', nome: 'Anuente', dominio : ['PROTOCOLO_RI', 'PROTOCOLO_RTD', 'PROTOCOLO_RCPJ', 'NOTIFICACAO', 'OFICIO', 'OCORRENCIA']},
      {id: 'MEEIRO', nome: 'Meeiro', dominio : ['PROTOCOLO_RI']},
      {id: 'TESTEMUNHA', nome: 'Testemunha', dominio : ['PROTOCOLO_RI', 'PROTOCOLO_RTD', 'PROTOCOLO_RCPJ']},
      {id: 'TRANSMITENTE', nome: 'Transmitente', dominio : ['PROTOCOLO_RI', 'PROTOCOLO_RTD', 'PROTOCOLO_RCPJ', 'NOTIFICACAO', 'OFICIO', 'OCORRENCIA']},
      {id: 'CONTRATANTE', nome: 'Contratante', dominio : ['PROTOCOLO_RI']},
      {id: 'INTERVENIENTE', nome: 'Interveniente', dominio : ['PROTOCOLO_RI']},
      {id: 'USUFRUTUARIO', nome: 'Usufrutuário', dominio : ['PROTOCOLO_RI']},
      {id: 'LOCADOR', nome: 'Locador', dominio : ['PROTOCOLO_RI']},
      {id: 'LOCATARIO', nome: 'Locatário', dominio : ['PROTOCOLO_RI']},
      {id: 'EXECUTADO', nome: 'Executado', dominio : ['PROTOCOLO_RI']},
      {id: 'EXEQUENTE', nome: 'Exequente', dominio : ['PROTOCOLO_RI']},
      {id: 'REQUERENTE', nome: 'Requerente', dominio : ['PROTOCOLO_RI', 'PROTOCOLO_RTD', 'PROTOCOLO_RCPJ']},
      {id: 'REQUERIDO', nome: 'Requerido', dominio : ['PROTOCOLO_RI']},
      {id: 'PROMITENTE_VENDEDOR', nome: 'Promitente Transmitente', dominio : ['PROTOCOLO_RI']},
      {id: 'PROMITENTE_COMPRADOR', nome: 'Promitente Adquirente', dominio : ['PROTOCOLO_RI']},
      {id: 'REPRESENTANTE', nome: 'Representante', dominio : ['PROTOCOLO_RI', 'PROTOCOLO_RTD', 'PROTOCOLO_RCPJ', 'OCORRENCIA']},
      {id: 'PROCURADOR', nome: 'Procurador', dominio : ['PROTOCOLO_RI', 'PROTOCOLO_RTD', 'PROTOCOLO_RCPJ', 'OCORRENCIA']},
      {id: 'FIEL_DEPOSITARIO', nome: 'Fiel Depositário', dominio : ['PROTOCOLO_RI', 'PROTOCOLO_RTD', 'PROTOCOLO_RCPJ']},

      {id: 'ARRENDATARIO', nome: 'Arrendatário', dominio : ['PROTOCOLO_RTD', 'PROTOCOLO_RCPJ']},
      {id: 'DECLARANTE', nome: 'Declarante', dominio : ['PROTOCOLO_RTD', 'PROTOCOLO_RCPJ']},
      {id: 'DESTINATARIO', nome: 'Destinatário', dominio : ['PROTOCOLO_RTD', 'PROTOCOLO_RCPJ', 'NOTIFICACAO', 'OFICIO', 'OCORRENCIA']},
      {id: 'MANDANTE', nome: 'Mandante', dominio : ['PROTOCOLO_RTD', 'PROTOCOLO_RCPJ']},
      {id: 'MANDATARIO', nome: 'Mandatário', dominio : ['PROTOCOLO_RTD', 'PROTOCOLO_RCPJ']},
      {id: 'PERMUTANTE', nome: 'Permutante', dominio : ['PROTOCOLO_RTD', 'PROTOCOLO_RCPJ']},
      {id: 'PROPRIETARIO', nome: 'Proprietário', dominio : ['PROTOCOLO_RTD', 'PROTOCOLO_RCPJ']},
      {id: 'REMETENTE', nome: 'Remetente', dominio : ['PROTOCOLO_RTD', 'PROTOCOLO_RCPJ', 'NOTIFICACAO', 'OFICIO', 'OCORRENCIA']},
      {id: 'SINDICO', nome: 'Síndico', dominio : ['PROTOCOLO_RTD', 'PROTOCOLO_RCPJ']},
      {id: 'NOTIFICADO', nome: 'Notificado', dominio : ['PROTOCOLO_RTD', 'PROTOCOLO_RCPJ', 'NOTIFICACAO', 'OFICIO', 'OCORRENCIA']},
      {id: 'NOTIFICANTE', nome: 'Notificante', dominio : ['PROTOCOLO_RTD', 'PROTOCOLO_RCPJ', 'NOTIFICACAO', 'OFICIO', 'OCORRENCIA']},

      {id: 'PESSOA_JURIDICA_PRINCIPAL', nome: 'Pessoa Jurídica Principal', dominio : ['PROTOCOLO_RCPJ']},
      { id : 'BENEFICIARIO_FINAL', nome : 'Beneficiário Final', dominio: ['OCORRENCIA']},
      { id : 'TITULAR', nome : 'Títular', dominio: ['OCORRENCIA']},
      {id: 'INVENTARIANTE', nome: 'Inventariante', dominio : ['PROTOCOLO_RI']},

    ].filter((item) => {
      return !dominio || item.dominio.includes(dominio);
    });

    if(documentacao){
      options.push(
        {id: 'ADMINISTRADOR', nome: 'Administrador', dominio: ['DOCUMENTACAO']},
        {id: 'OUTORGANTE', nome: 'Outorgante', dominio: ['DOCUMENTACAO']},
        {id: 'OUTORGADO', nome: 'Outorgado', dominio: ['DOCUMENTACAO']},
        {id: 'SOCIO', nome: 'Sócio', dominio: ['DOCUMENTACAO']},
        {id: 'SOCIO_ADMINISTRADOR', nome: 'Sócio administrador', dominio: ['DOCUMENTACAO']},
      );
    }
    return Utils.orderBy(options, 'nome');
  },

  getSituacoesDoi(){
    return [
      {id : 'CONSTRUCAO_AVERBADA', nome : 'Construção averbada'},
      {id : 'EM_CONSTRUCAO', nome : 'Em construção'},
      {id : 'NAO_SE_APLICA', nome : 'Não se aplica'},
    ]
  },

  getFormasDoi(){
    return [
      {id : 'A_VISTA', nome : 'À vista'},
      {id : 'A_PRAZO', nome : 'A prazo'},
      {id : 'NAO_SE_APLICA', nome : 'Não se aplica'},
    ]
  },

  getTiposOrigens() {
    return [
      {id: 'MATRICULA', nome: 'Matrícula', livro: "MATRICULA", status: 'ABERTA'},
      {id: "PRE_MATRICULA", nome: "Pré-Matrícula", livro: "MATRICULA", status: 'PENDENTE'},
      {id: 'TRANSCRICAO', nome: 'Transcrição', livro: "TRANSCRICAO"},
      {id: 'TORRENS', nome: 'Torrens', livro: "TORRENS"},
      {id: "TEXTO", nome: "Texto"},
      {id: 'MATRICULA_EXTERNA', nome: 'Indicador Real Externo', livro: "MATRICULA"},
    ]
  },

  getErrosFormReferenciaImovel(form) {
    let validation = {};
    let invalid = false;
    if (!form.tipo) {
      validation.tipo = 'O campo tipo é obrigatório';
      invalid = true;
    }
    if (!form.ato) {
      validation.ato = 'O campo ato é obrigatório';
      invalid = true;
    }
    if (!form.referenciaId) {
      validation.referenciaId = 'O campo de vínculo é obrigatório';
      invalid = true;
    }
    return invalid ? validation : undefined;

  },

  getErrosFormRegistroAnterior(form) {
    let validation = {};
    let invalid = false;
    // if (!form.serventia) {
    //   validation.serventia = 'O campo serventia é obrigatório';
    //   invalid = true;
    // }
    if (!form.tipo) {
      validation.tipo = 'O campo tipo é obrigatório';
      invalid = true;
    }
    if (!form.registro) {
      validation.registro = 'O campo registro é obrigatório';
      invalid = true;
    }
    if (form.tipo !== 'MATRICULA') {
      // if (!form.livro) {
      //   validation.livro = 'O campo livro é obrigatório';
      //   invalid = true;
      // }
      // if (!form.folha) {
      //   validation.folha = 'O campo folha é obrigatório';
      //   invalid = true;
      // }
    }
    return invalid ? validation : undefined;

  },

  getLabelRegistroAnterior(registro) {
    if (!registro) {
      return 'Novo registro anterior';
    }
    if (registro.interno) {
      registro.serventia = 'Esta Serventia';
    }
    registro.livroNumero = registro.livroNumero === 'null' ? null : registro.livroNumero;
    registro.livroLetra = registro.livroLetra === 'null' ? null : registro.livroLetra;
    const externa = registro.tipo == 'MATRICULA_EXTERNA';
    let livro = externa ? registro.tipoIndicadorRealExterno : registro.tipo;

    let prefix = (registro.tipo != 'TEXTO' && registro.tipo != 'LEGADO' ? livro?.substring(0, 1)?.toUpperCase() : '') || '';
    let label =  prefix + registro.registro;

    label += livro === 'MATRICULA' || livro === 'PRE_MATRICULA' ? `${registro.livroLetra || ''}` : `${registro.livroLetra ? ' Livro ' + (registro.livroNumero||'') + '' + (registro.livroLetra||'') : ''}${registro.folha ? ' Folha ' + registro.folha : ''}`;
    return `${registro.serventia ? registro.serventia + ' - ' : ''}${label}${externa ? ' (externa)': ''}`;
  },

  getCamposInfoComplementar(categoria, unidadeAutonoma) {
    let optionsUA = [];
    if(unidadeAutonoma){
      optionsUA = [{id: 'unidade', tipo: 'text', nome: 'Número da Unidade'},
        {id: 'andar', tipo: 'text', nome: 'Andar'},
        {id: 'bloco', tipo: 'text', nome: 'Bloco'},
        {id: 'pavimento', tipo: 'text', nome: 'Pavimento'},
        {id: 'grupo', tipo: 'text', nome: 'Grupo (Tipo)'}]
    }

    let opt = [
      {id: 'livroLetra', tipo: 'text', nome: 'Livro (Letra)'},
      {id: 'quadra', tipo: 'text', nome: 'Quadra'},
      {id: 'lote', tipo: 'text', nome: 'Lote'},
      {id: 'localizacao', tipo: 'text', nome: 'Localização'},
      {id: 'empreendimento', tipo: 'text', nome: 'Empreendimento/Condomínio'}
    ];

    // if (categoria == 'RURAL') {
    //   opt = [
    //     {id: 'quadra', tipo: 'text', nome: 'Quadra'},
    //     {id: 'lote', tipo: 'text', nome: 'Lote'},
    //     {id: 'localizacao', tipo: 'text', nome: 'Localização'},
    //     {id: 'empreendimento', tipo: 'text', nome: 'Empreendimento'}
    //   ]
    // }

    return Utils.orderBy([...optionsUA, ...opt], 'nome');
  },

  getCamposRegistroAnterio() {
    return [
      {id: 'origens', nome: 'Registro Anterior'},
      {id: 'registroAnterior', nome: 'Registro Anterior'},
      {id: 'serventia', nome: 'Serventia'},
      {id: 'tipo', nome: 'Tipo'},
      {id: 'livro', nome: 'Livro'},
      {id: 'folha', nome: 'Folha'},
      {id: 'registro', nome: 'Registro'},
    ];
  },

  getCamposEndereco() {
    return [
      {id: 'cep', tipo: 'cep', nome: 'CEP'},
      {id: 'tipoLogradouro', tipo: 'text', nome: 'Tipo Logradouro'},
      {id: 'logradouro', tipo: 'text', nome: 'Logradouro (Rua)'},
      {id: 'numero', tipo: 'text', nome: 'Número'},
      {id: 'bairro', tipo: 'text', nome: 'Bairro (Setor)'},
      {id: 'cidade', tipo: 'select', nome: 'Cidade', options: VueBusiness.getVueRoot()?.cidades},
      {id: 'estado', tipo: 'select', nome: 'Estado', options: EnderecoBusiness.getEstados() },
      {id: 'complemento', tipo: 'text', nome: 'Complemento'},
    ];
  },

  getCamposArea(unidadeAutonoma) {
    if (unidadeAutonoma) {
      return [
        {id: 'medidaArea', tipo: 'text', nome: 'Medida Área'},
        {id: 'area', tipo: 'text', nome: 'Área Total'},
        {id: 'fracaoSolo', tipo: 'text', nome: 'Fração Solo'},
        {id: 'fracaoSoloPercentual', tipo: 'text', nome: 'Fração Solo (%)'},
        {id: 'coeficienteProporcionalidade', tipo: 'text', nome: 'Coeficiente de proporcionalidade'},
      ];
    } else {
      return [
        {id: 'medidaArea', tipo: 'text', nome: 'Medida Área'},
        {id: 'area', tipo: 'text', nome: 'Área Total'},
        {id: 'medidaAreaDoi', tipo: 'text', nome: 'Medida Área DOI'},
        {id: 'areaTotalDoi', tipo: 'text', nome: 'Área total DOI'},
      ];
    }
  },

  getCamposEspecificao() {
    return [
      {id: 'especificacao', tipo: 'editor', nome: 'Especificações do Imóvel'},
    ];
  },

  getCamposCadastroIdentificacao(categoria, imovelUniao) {
    let retorno = [];
    if (categoria != 'RURAL' && categoria != '') {
      retorno = [
        {id: 'cadastroImobiliario', tipo: 'text', nome: 'Cadastro Imobiliário'},
        {id: 'cadastroImobiliarioBrasileiro', tipo: 'text', nome: 'Cadastro Imobiliário Brasileiro (CIB)'},
        {id: 'cadastroFiscal', tipo: 'text', nome: 'Cadastro fiscal'},
      ];
    } else {
      retorno = [
        {id: 'denominacao', tipo: 'text', nome: 'Denominação da Área Rural'},
        {id: 'incra', tipo: 'text', nome: 'INCRA (CCIR)'},
        {id: 'itr', tipo: 'text', nome: 'ITR'},
        {id: 'car', tipo: 'text', nome: 'CAR'},
        {id: 'certificacaoIncra', tipo: 'text', nome: 'Certificação no SIGEF'},
        {id: 'cadastroImobiliario', tipo: 'text', nome: 'Cadastro Imobiliário'},
        {id: 'cadastroImobiliarioBrasileiro', tipo: 'text', nome: 'Cadastro Imobiliário Brasileiro (CIB)'},
        {id: 'cadastroFiscal', tipo: 'text', nome: 'Cadastro fiscal'},
      ];
    }

    if (imovelUniao) {
      retorno.push({id: 'numeroRipSpu', tipo: 'mask', nome: 'Número RIP SPU'})
    }

    return Utils.orderBy(retorno, 'nome');
  },

  getStatusImovel(completa = true) {
    if (completa) {
      return [
        {id: 'PENDENTE', nome: 'Pendente'},
        {id: 'ABERTA', nome: 'Aberta'},
        {id: 'ENCERRADA', nome: 'Encerrada'},
        {id: 'CANCELADA', nome: 'Cancelada'},
        {id: 'VIRTUAL', nome: 'Virtual'}
      ]
    } else {
      return [
        {id: 'ABERTA', nome: 'Aberta'},
        {id: 'ENCERRADA', nome: 'Encerrada'},
        {id: 'CANCELADA', nome: 'Cancelada'},
      ]
    }
  },

  getLivrosTranscricao(){
    return [
        {id: 'IG', nome: "Livro 1 – Protocolo Geral", sigla: "IG"},
        {id: 'IH', nome: "Livro 2 – Inscrições Hipotecárias", sigla: "IH"},
        {id: 'TT', nome: "Livro 3 – Transcrições das Transmissões", sigla: "TT"},
        {id: 'A', nome: "Livro 3 – Auxiliar", sigla: "A"},
        {id: 'RD', nome: "Livro 4 – Registros Diversos", sigla: "RD"},
        {id: 'ED', nome: "Livro 5 – Emissão de Debêntures", sigla: "ED"},
        {id: 'IR', nome: "Livro 6 – Indicador Real", sigla: "IR"},
        {id: 'IP', nome: "Livro 7 – Indicador Pessoal", sigla: "IP"},
        {id: 'RE', nome: "Livro 8 – Registro Especial", sigla: "RE"},
        {id: 'RCR', nome: "Livro 9 – Registro de Cédula Rural", sigla: "RCR"},
        {id: 'RCI', nome: "Livro 10 – Registro de Cédula Industrial", sigla: "RCI"},
        {id: 'LT', nome: "Livro Talão", sigla: "LT"},
    ]
  },

  equivalenciaUnidadesImportacao() {
    return [
      {id: 'categoria', label: 'Categoria'},
      {id: 'unidadeAutonoma', label: 'Unidade Autônoma'},
      {id: 'tipoImovel', label: 'Tipo de Imóvel'},
      {id: 'descricaoTipoImovelOutros', label: 'Descrição'},
      {id: 'ultimoAto', label: 'Último Ato'},
      {id: 'unidade', label: 'Número da Unidade'},
      {id: 'bloco', label: 'Bloco'},
      {id: 'quadra', label: 'Quadra'},
      {id: 'lote', label: 'Lote'},
      {id: 'localizacao', label: 'Localização'},
      {id: 'incra', label: 'INCRA (CCIR)'},
      {id: 'itr', label: 'ITR'},
      {id: 'car', label: 'CAR'},
      {id: 'andar', label: 'Andar'},
      {id: 'pavimento', label: 'Pavimento'},
      {id: 'grupo', label: 'Grupo (Tipo)'},
      {id: 'cadastroImobiliario', label: 'Cadastro Imobiliário'},
      {id: 'cadastroImobiliarioBrasileiro', label: 'Cadastro Imobiliário Brasileiro (CIB)'},
      {id: 'cep', label: 'CEP'},
      {id: 'logradouro', label: 'Logradouro (rua)'},
      {id: 'numero', label: 'Número'},
      {id: 'cidade', label: 'Cidade'},
      {id: 'bairro', label: 'Bairro (setor)'},
      {id: 'estado', label: 'Estado'},
      {id: 'complemento', label: 'Complemento'},
      {id: 'area', label: 'Área total'},
      {id: 'medidaArea', label: 'Medida Área'},
      {id: 'fracaoSolo', label: 'Fração Solo'},
      {id: 'fracaoSoloPercentual', label: 'Fração Solo (%)'},
      {id: 'coeficienteProporcionalidade', label: 'Coeficiente de Proporcionalidade'},
      {id: 'especificacao', label: 'Especificações do Imóvel'},
      {id: 'observacao', label: 'Observações Internas'},
      {id: 'certidao', label: 'Observações para Certidão'},
    ]
  },

  listarSigef(imovel){
    return Http.get(`api/v2/sigef/${imovel}`);
  },

  camposConsulta() {
    return Utils.orderBy([
      {unificado: true, livro: null, id: 'categoria', nome: 'Categoria', type: 'list', options: this.getCategorias()},
      {unificado: true, livro: null, status: ['ABERTA','CANCELADA','ENCERRADA'], id: 'status', nome: 'Status/Situação', type: 'list', options: this.getStatusImovel(false)},
      {unificado: true, livro: null, id: 'tipo_imovel', nome: 'Tipo de Imóvel', type: 'list', options: this.getTiposImovel()},
      {unificado: true, livro: null, id: 'descricaoTipoImovelOutros', nome: 'Descrição do Tipo de Imóvel', type: 'text'},
      {unificado: true, livro: null, id: 'unidade', nome: 'Número da Unidade', type: 'text'},
      {unificado: true, livro: null, id: 'bloco', nome: 'Bloco', type: 'text'},
      {unificado: true, livro: null, id: 'quadra', nome: 'Quadra', type: 'text'},
      {unificado: true, livro: null, id: 'lote', nome: 'Lote', type: 'text'},
      {unificado: true, livro: null, id: 'localizacao', nome: 'Localização', type: 'text'},
      {unificado: true, livro: null, id: 'empreendimento', nome: 'Empreendimento/Condomínio', type: 'text'},
      {unificado: true, livro: null, id: 'denominacao', nome: 'Denominação da Área Rural', type: 'text'},
      {unificado: true, livro: null, id: 'incra', nome: 'INCRA (CCIR)', type: 'text'},
      {unificado: true, livro: null, id: 'itr', nome: 'ITR', type: 'text'},
      {unificado: true, livro: null, id: 'car', nome: 'CAR', type: 'text'},
      {unificado: true, livro: null, id: 'certificacao_incra', nome: 'Certificação no SIGEF', type: 'text'},
      {unificado: true, livro: null, id: 'andar', nome: 'Andar', type: 'text'},
      {unificado: true, livro: null, id: 'pavimento', nome: 'Pavimento', type: 'text'},
      {unificado: true, livro: null, id: 'grupo', nome: 'Grupo (Tipo)', type: 'text'},
      {unificado: true, livro: null, id: 'cadastro_imobiliario', nome: 'Cadastro Imobiliário', type: 'text'},
      {unificado: true, livro: null, id: 'cadastro_imobiliario_brasileiro', nome: 'Cadastro Imobiliário Brasileiro', type: 'text'},
      {unificado: true, livro: null, id: 'cadastro_imobiliario_num', nome: 'Cadastro Imobiliário (Numérico)', type: 'numeric'},
      {unificado: true, livro: null, id: 'cep', nome: 'CEP', type: 'cep'},
      {unificado: true, livro: null, id: 'logradouro', nome: 'Logradouro (rua)', type: 'text'},
      {unificado: true, livro: null, id: 'numero', nome: 'Número', type: 'text'},
      {unificado: true, livro: null, id: 'estado', nome: 'Estado', type: 'list', options: EnderecoBusiness.getEstados()},
      {unificado: true, livro: null, id: 'cidade', nome: 'Cidade', type: 'text'},
      {unificado: true, livro: null, id: 'bairro', nome: 'Bairro (setor)', type: 'text'},
      {unificado: true, livro: null, id: 'complemento', nome: 'Complemento', type: 'text'},
      {unificado: true, livro: null, id: 'especificacao', nome: 'Especificações do Imóvel', type: 'text'},
      {unificado: true, livro: null, id: 'referencia', nome: 'Referências do imóvel', type: 'text'},

      {unificado: true, livro: 'MATRICULA', status: ['ABERTA','CANCELADA','ENCERRADA'], id: 'inconsistente_onr',
        nome: 'Registros Inconsistentes para ONR', type: 'list', options: [{id: true, nome: 'Sim'}, {id: false, nome: 'Não'}]},

      {unificado: true, livro: 'TRANSCRICAO', id: 'livro_transcricao', nome: 'Livro Transcrição', type: 'list', options: this.getLivrosTranscricao()},
      {unificado: true, livro: 'TRANSCRICAO', id: 'livro_numero', nome: 'Livro (Número)', type: 'text'},
      {unificado: true, livro: 'TRANSCRICAO', id: 'livro_folha',  nome: 'Livro (Folha)', type: 'text'},
      {unificado: true, livro: 'TRANSCRICAO', id: 'livro_letra',  nome: 'Livro (Letra)', type: 'text'},

      {unificado: true, livro: null, status: ['ABERTA','CANCELADA','ENCERRADA'], id: 'abertura', nome: 'Data de Abertura', type: 'date'},
      {unificado: true, livro: null, status: ['ABERTA','CANCELADA','ENCERRADA'], id: 'registro', nome: 'Data de Registro', type: 'date'},
      {unificado: true, livro: null, status: ['ABERTA','CANCELADA','ENCERRADA'], id: 'encerramento', nome: 'Data de Encerramento', type: 'date'},
      {unificado: true, livro: null, status: ['ABERTA','CANCELADA','ENCERRADA'],id: 'data_alteracao_manual', nome: 'Data da Alteração Manual', type: 'date'},

      {unificado: true, livro: null, id: 'proprietario_cpf',  nome: 'Proprietário Atual (CPF)', type: 'cpf'},
      {unificado: true, livro: null, id: 'proprietario_cnpj', nome: 'Proprietário Atual (CNPJ)', type: 'cnpj'},
      {unificado: true, livro: null, id: 'proprietario_nome', nome: 'Proprietário Atual (Nome)', type: 'text'},

      {unificado: true, livro: null, status: ['ABERTA','CANCELADA','ENCERRADA'], id: 'ato_minuta', nome: 'Minuta dos Atos Registrados', type: 'tsquery'},
      {unificado: true, livro: null, status: ['ABERTA','CANCELADA','ENCERRADA'], id: 'ato_titulo', nome: 'Título dos Atos Registrados', type: 'tsquery'},

      {unificado: true, livro: null, id: 'registro_anterior', nome: 'Registro Anterior'},
      {unificado: true, livro: null, id: 'serventia_registro_anterior', nome: 'Serventia Registro Anterior'},

      {unificado: false, livro: 'MATRICULA', id: 'codigo', nome: 'Matrícula', type: 'number'},
      {unificado: false, livro: 'TRANSCRICAO', id: 'codigo', nome: 'Transcrição', type: 'number'},
      {unificado: true, livro: 'unificado', id: 'codigo', nome: 'Código', type: 'number'},
      {unificado: true, livro: null, id: 'area', nome: 'Área', type: 'area'},

      {unificado: true, livro: null, status: ['ABERTA','CANCELADA','ENCERRADA'], id: 'digitalizacao', nome: 'Digitalização', type: 'list', options: AnexoBusiness.tiposDigitalizacao().slice(1)},

      {unificado: true, id: 'registro_auxiliar_id',  nome: 'Registros Auxiliares Relacionados', type: 'registro_auxiliar'},
      {unificado: true, livro: null, id: 'ultimo_ato', nome: 'Último Ato', type: 'numeric'},
      {unificado: false, livro: 'MATRICULA', id: 'doi_tipo_transacao', nome: 'Apenas atos com Operação DOI', type: 'list', options: [{id: true, nome: 'Sim'}]}

    ], 'nome');
  },

}
