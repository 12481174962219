import CachedApi from "@/commons/CachedApi.js";
import Http from "@/commons/Http";
import utils from "@/commons/Utils.js";

const path = 'api/regra-custas';

export default {

  async listar(){
    return Http.get(`${path}`).then(l => utils.orderBy(l, 'nome'));
  },

  async getRegra(regra){
    return CachedApi.getCached('regra-'+regra, async () => {
      return Http.get(`${path}/${regra}`);
    });
  },

  getMotivosIsencao(estado, dominio = null){

    if(estado == 'RO'){
      //: “Ofício”,
      // “Mandado”, “Declaração de Pobreza” ou “Por Força de Lei”
      return [
        {id : 'T_1', nome: "Ofício"},
        {id : 'T_2', nome: "Mandado"},
        {id : 'T_3', nome: "Declaração de Pobreza"},
        {id : 'T_4', nome: "Por Força de Lei"},
      ];
    }

    if (estado == 'RS') {
      return [
        { id: 'T_EQLG02', nome: "Decreto Lei 1.537/77 - Gratuidade de quaisquer atos registrais e notariais praticados em favor das pessoas hipossuficientes, que não possam arcar com os custos", tipo: "RESSARCIVEL", dominio: ['PROTOCOLO_RI', 'CERTIDAO_RI'] },
        { id: 'T_EQLG05', nome: "Lei 9.532/97 - Arrolamento pela autoridade fiscal (bens móveis, imóveis e direitos) para garantir o cumprimento da obrigação tributária, podendo ser feito sem a necessidade de prévia manifestação judicial, desde que haja risco de não cumprimento da obrigação", tipo: "RESSARCIVEL", dominio: ['PROTOCOLO_RI', 'CERTIDAO_RI'] },
        { id: 'T_EQLG10', nome: "Lei 11.441/07 - Gratuidade separações, divórcios e partilhas amigáveis - desde que atendam aos requisitos legais", tipo: "RESSARCIVEL", dominio: ['PROTOCOLO_RI', 'CERTIDAO_RI'] },
        { id: 'T_EQLG14', nome: "Lei Federal 11.977/09 - Projeto Minha Casa Minha Vida que estabelece políticas públicas de habitação, com foco na concessão de financiamentos e subsídios para a construção ou aquisição de moradias destinadas a famílias de baixa e média renda, visando à redução do déficit habitacional no país", tipo: "RESSARCIVEL", dominio: ['PROTOCOLO_RI', 'CERTIDAO_RI'] },
        { id: 'T_EQLG15', nome: "Lei 13.105/2015 - CPC, art. 98 e § 1°, inciso IX - Assistência Judiciária", tipo: "RESSARCIVEL", dominio: ['PROTOCOLO_RI', 'CERTIDAO_RI', 'PROTOCOLO_RTD', 'CERTIDAO_RTD', 'PROTOCOLO_RCPJ', 'CERTIDAO_RCPJ'] },
        { id: 'T_PEPO', nome: "Pagamento de emolumentos a posteriori", tipo: "RESSARCIVEL", dominio: ['PROTOCOLO_RI', 'CERTIDAO_RI'] },
        { id: 'T_EQLG17', nome: "Lei 13.105/2015 - CPC art. 1.071 - Gratuidade dos atos notariais e de registro...", tipo: "RESSARCIVEL", dominio: ['PROTOCOLO_RI', 'CERTIDAO_RI', 'PROTOCOLO_RTD', 'CERTIDAO_RTD', 'PROTOCOLO_RCPJ', 'CERTIDAO_RCPJ'] },
        { id: 'T_EQLG20', nome: "Lei 13.465/17 e Decreto 9.310/18 - Regularização Fundiária", tipo: "RESSARCIVEL", dominio: ['PROTOCOLO_RI', 'CERTIDAO_RI'] },
        { id: 'T_RQPJ', nome: "Requisição do Poder Judiciário", tipo: "NAO_RESSARCIVEL", dominio: ['PROTOCOLO_RI', 'CERTIDAO_RI', 'PROTOCOLO_RTD', 'CERTIDAO_RTD', 'PROTOCOLO_RCPJ', 'CERTIDAO_RCPJ'] },
        { id: 'T_RQMP', nome: "Requisição do Ministério Público", tipo: "NAO_RESSARCIVEL", dominio: ['PROTOCOLO_RI', 'CERTIDAO_RI', 'PROTOCOLO_RTD', 'CERTIDAO_RTD', 'PROTOCOLO_RCPJ', 'CERTIDAO_RCPJ'] },
        { id: 'T_RQEA', nome: "Requisição do Ente Administrativo", tipo: "NAO_RESSARCIVEL", dominio: ['PROTOCOLO_RI', 'CERTIDAO_RI', 'PROTOCOLO_RTD', 'CERTIDAO_RTD', 'PROTOCOLO_RCPJ', 'CERTIDAO_RCPJ'] },
        { id: 'T_ERTR', nome: "Erro do Registrador", tipo: "NAO_RESSARCIVEL", dominio: ['PROTOCOLO_RI', 'CERTIDAO_RI', 'PROTOCOLO_RTD', 'CERTIDAO_RTD', 'PROTOCOLO_RCPJ', 'CERTIDAO_RCPJ'] },
        { id: 'T_AGNR', nome: "Ato Gratuito não ressarcível", tipo: "NAO_RESSARCIVEL", dominio: ['PROTOCOLO_RI', 'CERTIDAO_RI', 'PROTOCOLO_RTD', 'CERTIDAO_RTD', 'PROTOCOLO_RCPJ', 'CERTIDAO_RCPJ'] },
        { id: 'T_AGDO', nome: "Ato Gratuito não ressarcível (Doação de Órgãos)", tipo: "NAO_RESSARCIVEL", dominio: ['PROTOCOLO_RI', 'CERTIDAO_RI', 'PROTOCOLO_RTD', 'CERTIDAO_RTD', 'PROTOCOLO_RCPJ', 'CERTIDAO_RCPJ'] },
        { id: 'T_SGDL', nome: "Selo Gratuito por determinação legal", tipo: "NAO_RESSARCIVEL", dominio: ['PROTOCOLO_RI', 'CERTIDAO_RI', 'PROTOCOLO_RTD', 'CERTIDAO_RTD', 'PROTOCOLO_RCPJ', 'CERTIDAO_RCPJ'] },
        { id: 'T_PEPO', nome: "Pagamento de emolumentos posteriori", tipo: "NAO_RESSARCIVEL", dominio: ['PROTOCOLO_RI', 'CERTIDAO_RI', 'PROTOCOLO_RTD', 'CERTIDAO_RTD', 'PROTOCOLO_RCPJ', 'CERTIDAO_RCPJ'] },
      ];
    }


    if(estado == 'MS'){
      return [
        {id : 'MUNICIPIO', nome: "Município"},
        {id : 'AUTARQUIA_FEDERAL', nome: "Autarquia Federal"},
        {id : 'AUTARQUIA_ESTADUAL', nome: "Autarquia Estadual"},
        {id : 'AUTARQUIA_MUNICIPAL', nome: "Autarquia Municipal"},
        {id : 'FUNDACAO_PUBLICA_FEDERAL', nome: "Fundação Pública Federal"},
        {id : 'FUNDACAO_PUBLICA_ESTADUAL', nome: "Fundação Pública Estadual"},
        {id : 'FUNDACAO_PUBLICA_MUNICIPAL', nome: "Fundação Pública Municipal"},
        {id : 'OUTROS', nome: "Outros"}
      ];
    }


    if(estado == 'PR'){
      return [
        {id : 'T_1', nome: "1 - Justiça Gratuita"},
        {id : 'T_2', nome: "2 - Ordem judicial"},
        {id : 'T_3', nome: "3 - Órgão Público"},
        // {id : 'T_4', nome: "Declaração de Hipossuficiente"},
        // {id : 'T_5', nome: "INSS"},
        {id : 'T_6', nome: "6 - Isenção legal"},
        {id : 'T_7', nome: "7 - Erro Material"},
      ];
    }

    if(estado == 'BA'){
      return [
        {id : 'T_64', nome: "64 - Assistência Judiciária"},
        {id : 'T_21', nome: "21 - Autarquias e Fundações Públicas"},
        {id : 'T_63', nome: "63 - Aut/Rec/Cartão (Autorização Expressa)"},
        {id : 'T_62', nome: "62 - Defensoria Pública"},
        {id : 'T_42', nome: "42 - Estados e DF"},
        {id : 'T_61', nome: "61 - Ministário Público"},
        {id : 'T_43', nome: "43 - Municipios"},
        {id : 'T_66', nome: "66 - Outros (Especificar)"},
        {id : 'T_65', nome: "65 - Retificação de Ato (Erro Cartório)"},
        {id : 'T_41', nome: "41 - União"},
      ];
    }


    if(estado == 'SE'){
      return [
        {id: 'T_3', nome: "3 - Nascimento/Óbito/Natimorto"},
        {id: 'T_4', nome: "4 - 2ª Via de Certidão de Nascimento/Óbito/Casamento"},
        {id: 'T_5', nome: "5 - Habilitação Casamento (envolvendo todos os atos)"},
        {id: 'T_6', nome: "6 - Determinação Judicial"},
        {id: 'T_7', nome: "7 - Determinação Administrativa"},
        {id: 'T_1', nome: "1 - Autoridade Judiciária/Ministério Público (Art. 5º, I, Lei 6310/2007)"},
        {id: 'T_2', nome: "2 - Adequação Estatutária OSCIP/Associação de Moradores (Lei 12.879/13)"},
        {id: 'T_8', nome: "8 - Afixação gratuita proveniente habilitação gratuita"},
        {id: 'T_10', nome: "10 - Inventário/Partilha/Separação/Divórcio (Art. 3º, §3º, Lei 11441/2007)"},
        {id: 'T_11', nome: "11 - Atos de Ofício com Previsão Legal"},
        {id: 'T_12', nome: "12 - Retificação ou Renovação do ato praticado com erro imputável ao serviço"},
        {id: 'T_14', nome: "14 - Certidão de Dívida Ativa (CDA)"},
        {id: 'T_15', nome: "15 - Regularização Fundiária - Primeiro Registro de direito real"},
        {id: 'T_16', nome: "16 - Regularização Fundiária - Primeira Averbação de construção até 70m²"},
        {id: 'T_17', nome: "17 - Regularização Fundiária - Registro de Título de legitimação de posse"},
        {id: 'T_18', nome: "18 - Ato Normativo – Prov. 63/CNJ"},
        {id: 'T_19', nome: "19 - Art. 237-A, § 1º da Lei nº 6.015/73"},
        {id: 'T_20', nome: "20 - Reconhecimento de paternidade"}
      ];
    }

    if(estado == 'MG'){
      return [
        // {id : 'T_9', nome: "9 - Isento - Administração Direta do Estado - art. 19 da Lei 15.424/2004"},
        {id : 'T_10', nome: "10 - Isento - Mandado Judicial + justiça gratuita - art. 20, I da Lei 15.424/2004 c/ redação Lei 19.414/2010"},
        {id : 'T_11', nome: "11 - Isento - Declaradamente Pobres"},
        {id : 'T_12', nome: "12 - Isento - art. 12-A ou 13 da Lei 15.424/2004 c/ redação Lei 19.971/2011  Credor"},
        {id : 'T_13', nome: "13 - Isento - Penhora e Arresto - art. 20, II, da Lei 15.424/2004 c/ redação Lei 19.414/2010"},
        {id : 'T_14', nome: "14 - Isento - Programa Habitação - art. 20, III, da Lei 15.424/2004 c/ redação Lei 19.414/2010"},
        {id : 'T_15', nome: "15 - Isento - Interesse da União - art. 20, IV, da Lei 15.424/2004 c/ redação Lei 19.414/2010"},
        {id : 'T_16', nome: "16 - Isento - Entidades de Assistência Social - art. 20, V, da Lei 15.424/2004 c/ redação Lei 19.414/2010"},
        {id : 'T_17', nome: "17 - Isento - Regularização Fundiária de Interesse Social - art. 20, VI, da Lei 15.424/2004 c/ redação Lei 19.414/2010"},
        {id : 'T_18', nome: "18 - Isento - Certidão Justiça Eleitoral - art. 20, VIII, da Lei 15.424/2004 c/ redação Lei 20.379/2012"},
        {id : 'T_19', nome: "19 - Isento - Certidões para Órgãos Públicos - art. 20, IX, da Lei 15.424/2004 c/ redação Lei 20.379/2012"},
        {id : 'T_20', nome: "20 - Isento - Promorar-Militar - art. 15-A, caput, da Lei 15.424/2004 c/ redação Lei 20.379/2012"},
        {id : 'T_23', nome: "23 - Isento TFJ - Micro Empresa e Empresa de Pequeno Porte - art. 73 da Lei Complementar 123/06"},
        {id : 'T_24', nome: "24 - Art. 12-A ou 13 da Lei 15.424/2004 c/ redação Lei 19.971/2011 - Devedor"},
        // {id : 'T_25', nome: "25 - Isento TFJ - Decreto-Lei nº 167/1967 - REGISTRO"},
        {id : 'T_26', nome: "26 - Isento - Nascimento/Óbito + certidão - art. 30 Lei 6.015/73"},
        // {id : 'T_27', nome: "27 - Isento - Minha Casa Minha Vida - art. 43 da Lei 11.977/2009"},
        {id : 'T_28', nome: "28 - Ato retificador/renovador em razão de erro imputável ao próprio cartório - art. 16, III, Lei 15.424/2004"},
        {id : 'T_29', nome: "29 - Isento - Nascimento/Óbito + certidão - Provimento 13/CNJ/2010"},
        {id : 'T_30', nome: "30 - Isento - Estado de Minas Gerais e suas autarquias e fundações - art. 19 da Lei 15.424/2004 c/ redação Lei 19.971/2011"},
        {id : 'T_31', nome: "31 - Ato retificador/renovador em razão de erro imputável ao próprio cartório - art. 16, III, Lei 15.424/2004 c/c decisão do processo 2014/66383/CAFIS"},
        {id : 'T_32', nome: "32 - Isento - reserva legal - art. 18, §4º, da Lei Federal nº 12.651/2012 e art. 31 da Lei Estadual nº 20.922/2013"},
        {id : 'T_33', nome: "33 - Isento - associações de moradores - art. 1º da Lei Federal nº 12.879/2013"},
        {id : 'T_34', nome: "34 - Registro ou averbação de cédulas e letras de crédito imobiliário e de cédulas de crédito bancário, quando solicitado(a) simultaneamente com o registro da garantia do crédito respectivo - ato único para efeito de cobrança de emol. e TFJ, nos termos da Nota VIII, da Tab. 4"},
        {id : 'T_35', nome: "35 - Art. 12-A ou 13 da Lei 15.424/2004 c/ redação Lei 19.971/2011 - Devedor + Isento TFJ - Microempresa e Empresa de Pequeno Porte - art. 73 da Lei Complementar 123/06"},
        {id : 'T_36', nome: "36 - Isento - Reforma Agrária/Assentamento - Beneficiários de terras rurais - art. 1º da Lei Estadual nº 14.313/2002 c/ redação da Lei Estadual nº 18.711/2010"},
        {id : 'T_38', nome: "38 - Averbação/registro relativo ao mesmo ato/negócio jurídico, realizados na matrícula de origem do imóvel e em cada uma das matrículas das unidades autônomas eventualmente abertas - ato único para fins de cobrança de emolumentos e TFJ (Art. 237-A, caput e § 1º, da Lei Federal nº 6.015/1973)."},
        // {id : 'T_40', nome: "40 - Isento TFJ e desconto de 90% sobre os emolumentos - art. 39, § 3º, c/c art. 36, § 2º, Decreto-Lei nº 167/1967 - CANCELAMENTO"},
        {id : 'T_41', nome: "41 - Isento - Reconhecimento de Firma em requerimentos e papéis destinados a fins eleitorais - art. 373 da Lei Federal nº 4.737/1965"},
        // {id : 'T_42', nome: "42 - Prenotação - Art. 1.024-K, §§ 2º a 5º, do Provimento 260/2013 c/ redação Provimento 317/2016  Ordem Judicial - Autoridade Judiciária"},
        // {id : 'T_43', nome: "43 - Prenotação - Art. 1.024-K, §§ 2º a 5º, do Provimento 260/2013 c/ redação Provimento 317/2016  Ordem Judicial - Parte Interessada"},
        {id : 'T_44', nome: "44 - Averbação de retificação de registro civil das pessoas naturais para correção de erro que não exija qualquer indagação - Art.110 da Lei 6.015/1973"},
        // {id : 'T_45', nome: "45 - Apostila - Convenção de Haia - Art. 18 da Resolução nº 228/CNJ/2016"},
        {id : 'T_46', nome: "46 - Isento - Averbação de CPF - art. 6º, §§ 1º, 2º e 3º, Provimento nº 63/CNJ/2017."},
        {id : 'T_48', nome: "48 - Isento - Bem/direito doado ao Estado, suas autarquias e fundações - art. 20, X e XI, da Lei nº 15.424/2004."},
        {id : 'T_50', nome: "50 - Registro/averbação de cédula rural pignoratícia/de produto rural garantida por penhor rural, exclusivamente no Livro 3 - ato único para efeito de cobrança de Emolumentos e TFJ - Nota X da Tabela 4 da Lei nº 15.424/2004."},
        {id : 'T_51', nome: "51 - Isento - Protocolo em registro/custódia de documentos eletrônicos (item 5, \"c\", \"d\", \"e\") - art. 10, §§ 6º e 8º c/c Nota IV da Tabela 5 da Lei nº 15.424/2004."},
        {id : 'T_52', nome: "52 - Certidão de processo de habilitação ou de outro procedimento (termo de abertura e termo de encerramento) e cada uma das páginas reproduzidas - Item 18 da Tabela 7 da Lei nº 15.424/2004."},
        {id : 'T_53', nome: "53 - Registro de certidões expedidas pelo Poder Judiciário relativas a precatórios judiciais."},
        // {id : 'T_54', nome: "54 - Registro de hipoteca/alienação fiduciária relacionado a cédula/nota de crédito rural e de produto rural - metade dos valores previstos na alínea \"e\" do item 5 da Tabela 4 - art. 10, §3º, inciso XI da Lei nº 15.424/2004."},
        {id : 'T_55', nome: "55 - Apresentação/distribuição a protesto - pagamento postergado - art. 12-B da Lei 15.424/2004, acrescentado pelo art. 1º da Lei nº 23.204, de 27/12/2018 - Apresentante"},
        {id : 'T_56', nome: "56 - Apresentação/distribuição a protesto - pagamento quando da elisão/desistência/cancelamento/sustação - art. 12-B da Lei 15.424/2004, acrescentado pelo art. 1º da Lei nº 23.204, de 27/12/2018 - Interessado"},
        {id : 'T_57', nome: "57 - Cancelamento da distribuição por vício formal do título - Art. 3º do Provimento nº 86/CNJ/2019"},
        {id : 'T_58', nome: "58 - Prenotação - mandado judicial - decisão proferida no Processo nº 0069880-18.2018.8.13.0000 (SEI) - Autoridade Judiciária"},
        // {id : 'T_59', nome: "59 - Prenotação - mandado judicial - decisão proferida no Processo nº 0069880-18.2018.8.13.0000 (SEI) - Parte Interessada"},
        {id : 'T_60', nome: "60 - Prenotação - indisponibilidade de bens - art. 14, § 3º, do Provimento nº 39/CNJ/2014 c/c art. 851, § 2º, do Provimento Conjunto nº 93/2020 c/c decisão proferida no Processo nº 0069880-18.2018.8.13.0000 (SEI) - Autoridade"},
        // {id : 'T_61', nome: "61 - Prenotação - indisponibilidade de bens - art. 14, § 3º, do Provimento nº 39/CNJ/2014 c/c art. 851, § 2º, do Provimento Conjunto nº 93/2020 c/c decisão proferida no Processo nº 0069880-18.2018.8.13.0000 (SEI) - Parte Interessada"},
        {id : 'T_62', nome: "62 - Isento - Procuração para recebimento de benefícios previdenciários/assistenciais (INSS) + traslado -art. 68-A da Lei nº 8.212/1991, incluído pela Lei nº 14.199/2021"},
        {id : 'T_69', nome: "69 - Isento para cumprimento de decisão administrativa do Poder Judiciário"},
        {id : 'T_73', nome: "73 - Isento - Ato praticado de ofício"},
        {id : 'T_74', nome: "74 - Cumprimento de ordem judicial independentemente de emolumentos e TFJ - sem justiça gratuita"},
        {id : 'T_75', nome: "75 - Isento - serviços eletrônicos disponibilizados aos entes previstos no § 6o do art. 76 da Lei no 13.465, 11 de julho de 2017"},
      ];
    }

    if(estado == 'SC'){
      let lista =[{id : 'T_5',  nome: "5 - Lei Federal n. 13.105/2015 - CPC, Art. 98, § 1º, IX, Gratuidade da Justiça"}];

      if (dominio!='certidao') {
        lista = [...lista, ... [
          {id : 'T_6',  nome: "6 - Lei Federal n. 4.380/64, Art. 59 - Contratos Imobiliários de Interesse Social"},
        ]];
      }
      lista = [...lista, ... [
        //{id : 'T_5',  nome: "5 - Lei Federal n. 13.105/2015 - CPC, Art. 98, § 1º, IX, Gratuidade da Justiça"},
        {id : 'T_9',  nome: "9 - Lei Federal n. 6.015/73, Art. 30 - Reg. Nascimento, Natimorto e Óbito c/ 1ª Certidão"},
        {id : 'T_10', nome: "10 - Lei Federal n. 6.830/80, Art. 39 - Execução Fiscal / Fazenda Pública"},
        {id : 'T_12', nome: "12 - Isento (Lei Federal n. 6.015/73, art. 213, §15º)"},
        {id : 'T_14', nome: "14 - Lei Federal n. 8.069/90 - ECA, Art. 102, § 2º - Medidas de Proteção"},
        {id : 'T_15', nome: "15 - Lei Federal n. 8.069/90 - ECA, Art. 141, § 2º - Ação Judicial Infância e Juventude"},
        {id : 'T_16', nome: "16 - Lei Federal n. 8.560/92, Art. 2º - Ausência de Paternidade"},
        {id : 'T_17', nome: "17 - Lei Federal n. 9.265/96, Art. 1º - Certidão Exercício da Soberania Popular e Alistamento Militar"},
        {id : 'T_18', nome: "18 - Lei Federal n. 9.532/97, Art. 64, § 5º - Arrolamento de Bens / Fisco"},
        {id : 'T_20', nome: "20 - Lei Estadual n. 13.671/05, Art. 1º, caput e § 3º - Certidão de 2ª Via de Nascimento, Casamento e Óbito"},
        {id : 'T_21', nome: "21 - Lei Complementar Estadual n. 155/97, Art. 3º - Certidão de Honorários Advocatícios"},
        {id : 'T_23', nome: "23 - LCe n. 755/19 - Art. 7º, IV - Habilitação e Registro de casamento p/ os hipossuficientes"},
        {id : 'T_25', nome: "25 - LCe n. 755/19 - Art. 7º, I - Entes Públicos"},
        {id : 'T_26', nome: "26 - LCe n. 755/19 - Art. 7º, VI - COHAB"}
      ]];
      if (dominio!='certidao') {
        lista.push(
          {id : 'T_27', nome: "27 - LCe n. 755/2019, Art. 83 - Encerramento de matrícula na serventia de origem quando for alterada sua circunscrição"},
        )
      }
      lista.push({id : 'T_28', nome: "28 - Lei Federal n. 12.879/2013 - Adaptação estatutária das associações de moradores"},
        {id : 'T_32', nome: "32 - LCe n. 755/19 - Art. 7º, III - Entidade Declarada de Utilidade Pública Estadual"},
        {id : 'T_33', nome: "33 - Lei Estadual n. 8.589/1992 (Pessoas portadoras de deficiências físicas e as que tenham atingido o limite previsto para aposentadoria"},
        {id : 'T_34', nome: "34 - Lei Federal n. 8.069/90 - ECA, Art. 102, § 5º e § 6º - Reconhecimento de Paternidade"},
        {id : 'T_36', nome: "36 - Lei Federal n. 8.629/93, Art. 26-A - Imóvel Rural Reforma Agrária"},
        {id : 'T_37', nome: "37 - Publicação de Edital de Proclamas em serventia diversa, no caso de Habilitação gratuita"},
        {id : 'T_42', nome: "42 - Posto de Atendimento e Conciliação (PAC) - Declaração de Pobreza, CF, art. 5º LXXIV"},
        {id : 'T_44', nome: "44 - Ato decorrente de Registro Gratuito ¿ RCPN)"},
        {id : 'T_45', nome: "45 - Provimento CNJ N. 63/2017, art. 6º, §2º e §3º)"},
        {id : 'T_46', nome: "46 - LCe n. 755/19 - Art. 7º, II - Autarquia Federal"},
        {id : 'T_47', nome: "47 - LCe n. 755/19 - Art. 7º, II - Autarquia Estadual de SC"},
        {id : 'T_48', nome: "48 - LCe n. 755/19 - Art. 7º, II - Autarquia Municipal de SC"},
        {id : 'T_49', nome: "49 - LCe n. 755/19 - Art. 7º, VII - Defensoria Pública para os hipossuficientes"},
        {id : 'T_50', nome: "50 - Resolução CNJ n. 35/2007 - Art. 6° - Inventário, Partilha, Separação Consensual e Divórcio consensual"},
        {id : 'T_51', nome: "51 - Isento (Lei Federal n. 13.465/2017, Art. 13, §1º - Regularização Fundiária de Interesse Social - Reurb-S)"},
        {id : 'T_52', nome: "52 - Isento (Resolução CM n. 08/2014, art. 15 - Programa Lar Legal)"},
        {id : 'T_53', nome: "53 - Isento (Lei Federal n. 14.199/2021, Art. 68-A - Procuração pública para fins previdenciários)"},
        {id : 'T_54', nome: "54 - Não incidência"},
        {id : 'T_64', nome: "64 - Isento (Lei Federal n. 13.105/2015 - CPC, Art. 98, § 1º, IX, Gratuidade da Justiça; Provimento CNJ n. 39/2014, art. 2º, CNIB)"},
        {id : 'T_65', nome: "65 - Isento (Lei Federal n. 13.105/2015 - CPC, Art. 98, § 1º, IX, Gratuidade da Justiça; Provimento CNJ n. 39/2014, art. 2º, CNIB - Justiça do Trabalho)"},
        {id : 'T_66', nome: "66 - Isento (Lei Federal n. 13.105/2015 - CPC, Art. 98, § 1º, IX, Gratuidade da Justiça; Provimento CNJ n. 39/2014, art. 2º, CNIB - Justiça Federal)"},
        {id : 'T_67', nome: "67 - Isento (Lei Federal n. 13.105/2015 - CPC, Art. 98, § 1º, IX, Gratuidade da Justiça; Provimento CNJ n. 39/2014, art. 2º, CNIB - Justiça de outros Estados)"},
        {id : 'T_68', nome: "68 - Isento (Lei Federal n. 13.105/2015 - CPC, Art. 98, § 1º, IX, Gratuidade da Justiça - Restrição - Justiça do Estado de Santa Catarina)"},
        {id : 'T_70', nome: "70 - Isento (Provimento CNJ n. 140/2023 - Registre-se)"},
      )
      return lista;
    }

    return null;

  },

  getMotivoIsencaoById(estado, id){
    return this.getMotivosIsencao(estado).find(m => m.id === id);
  },

  getTiposProcessoRessarcimento(estado){

    if(estado == 'SC'){
      return [
        {id : 'ESTADUAL_PRIMEIRO_GRAU', nome: 'Justiça comum estadual de primeiro grau de Santa Catarina - juízes de primeiro grau da justiça estadual de Santa Catarina'},
        {id : 'ESTADUAL_SEGUNDO_GRAU', nome: 'Justiça comum estadual de segundo grau - Tribunal de Justiça de Santa Catarina/TJSC'},
        {id : 'OUTRAS_JUSTICAS', nome: 'Outras Justiças'},
        {id : 'OUTRAS_SITUACOES', nome: 'Não é processo'},
        {id : 'NAO_RESSARCIVEL', nome: 'Não ressarcível'}
      ];
    }

    return null;

  },

  camposBaseCalculo(){
    return Object.freeze([
      {id: 'FIXO', nome: 'Valor Fixo', possuiAtualizacao: false, label: 'Valor Fixo', key: 'fixo'},
      {id: 'SEM_VALOR', nome: 'Sem Valor', possuiAtualizacao: false, label: 'Sem Valor', key: 'nenhum'},
      {id: 'MAIOR_VALOR', nome: 'Maior Valor', possuiAtualizacao: false, label: 'Maior Valor', key: 'maiorValor'},
      // {id: 'MENOR_VALOR', nome: 'Menor Valor', possuiAtualizacao: false, label: 'Menor Valor', key: 'menorValor'},
      {id: 'ALIENACAO', nome: 'Negócio/Alienação', possuiAtualizacao: true, label: 'Valor do Negócio/Alienação', key: 'alienacao'},
      {id: 'VALOR_AVALIACAO', nome: 'Avaliação', possuiAtualizacao: true, label: 'Valor de Avaliação Fiscal', key: 'valorAvaliacao', help: '(ITBI/ITCD)'},
      {id: 'VALOR_DIVIDA', nome: 'Dívida', possuiAtualizacao: true, label: 'Valor da Dívida', key: 'valorDivida'},
      {id: 'VALOR_IMOVEL', nome: 'Imóvel', possuiAtualizacao: false, label: 'Valor Atribuído ao Imóvel', key: 'valorImovel'},
      {id: 'VALOR_MERCADO', nome: 'Mercado', possuiAtualizacao: false, label: 'Valor de Mercado', key: 'valorMercado'},
      {id: 'VALOR_VENAL', nome: 'Venal', possuiAtualizacao: false, label: 'Valor Venal', key: 'valorVenal'},
      {id: 'VALOR_MEACAO', nome: 'Meação', possuiAtualizacao: false, label: 'Valor de Meação', key: 'valorMeacao'},
      {id: 'VALOR_LEILAO', nome: 'Leilão', possuiAtualizacao: false, label: 'Valor de Leilão', key: 'valorLeilao'},
      {id: 'VTI', nome: 'Valor Total do Imóvel (VTI)', possuiAtualizacao: false, label: 'Valor Total do Imóvel (VTI)', key: 'vti'},
      {id: 'VTN', nome: 'Valor da Terra Nua (VTN)', possuiAtualizacao: false, label: 'Valor da Terra Nua (VTN)', key: 'vtn'},
    ]);
  },

}
