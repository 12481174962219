import Vue from "vue";
import Router from "vue-router";
import VueBusiness from "@/business/VueBusiness";

Vue.use(Router);

export default new Router({
  // mode: 'history',
  linkExactActiveClass: 'active router-link-active',
  routes: [

    {
      path: '/testar-conexao', name: 'testar-conexao',
      component: () => import(/* webpackChunkName: "testar-conexao.route" */ '@/views/TestarConexao.vue')
    },

    {
      path: '/vue3', name: 'testar-vue3',
      component: () => import(/* webpackChunkName: "testar-conexao.route" */ '@/views/TestarVue3.vue')
    },

    {
      path: '/login', name: 'login',
      component: () => import(/* webpackChunkName: "login.route" */ '@/views/Login.vue')
    },

    {
      path: '/digitalizacao/:entidade/:tipoEntidade/:id?/:grupo?/:tipoRegistroTD?', name: 'digitalizacao', props: true, meta: {requiresAuth: true},
      component: () => import(/* webpackChunkName: "digitalizacao.route" */ '@/views/Digitalizacao/Digitalizacao.vue')
    },

    {
      path: '/doc/:entidade/:id', name: 'document-editor', props: true, meta: {requiresAuth: true},
      component: () => import('@/views/DocumentEditor/DocumentEditorView.vue')
    },

    {
      path: '/anexo/:id/:assinado?', name: 'anexo', props: true, meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "anexo.route" */ '@/views/Anexo')
    },

    {
      path: '/acompanhamento-online/:entidade?/:codigo?/:senha?', name: 'acompanhamento-online', props: true,
      component: () => import(/* webpackChunkName: "acompanhamento-online.route" */ '@/views/Acompanhamento/Acompanhamento.vue')
    },

    {
      path: '/validar-assinatura/:verificacao?', name: 'validar-assinatura', props: true,
      component: () => import(/* webpackChunkName: "validar-assinatura.route" */ '@/views/Assinatura/ValidarAssinatura.vue')
    },

    {
      path: '/pedido-online', name: 'pedido-online', props: true,
      component: () => import(/* webpackChunkName: "pedido-online.route" */ '@/views/PedidoOnline/PedidoOnline.vue')
    },

    // {
    //   path : '/site', name : 'site', props: true, redirect: '/site/pedidos-certidao',
    //   component: () => import(/* webpackChunkName: "site.route" */ '@/views/Site/Site.vue'),
    //   children: [
    //     {
    //       path: 'autenticacao', name: 'site-autenticacao',
    //       component: () => import(/* webpackChunkName: "site.route" */ '@/views/Site/Autenticacao.vue')
    //     },
    //     {
    //       path: 'validar-certidao', name: 'site-validar-certidao',
    //       component: () => import(/* webpackChunkName: "site.route" */ '@/views/Site/ValidarCertidao.vue')
    //     },
    //     {
    //       path: 'editar-perfil', name: 'site-editar-perfil',
    //       component: () => import(/* webpackChunkName: "site.route" */ '@/views/Site/EditarPerfil.vue')
    //     },
    //     {
    //       path: 'pedidos-certidao', name: 'site-pedidos-certidao',
    //       component: () => import(/* webpackChunkName: "site.route" */ '@/views/Site/PedidosCertidao.vue')
    //     },
    //     {
    //       path: 'novo-pedido-certidao', name: 'site-novo-pedido-certidao',
    //       component: () => import(/* webpackChunkName: "site.route" */ '@/views/Site/NovoPedido.vue')
    //     },
    //     {
    //       path: 'pedido-certidao/:id', name: 'site-pedido-certidao',
    //       component: () => import(/* webpackChunkName: "site.route" */ '@/views/Site/Pedido.vue')
    //     },
    //   ]
    // },

    {
      path: '/odin/:entidade/:id', name: 'odin', props: true, meta: {requiresAuth: true},
      component: () => import('@/views/Odin/OdinView.vue')
    },

    {
      path: '',
      alias: '/',
      name: 'layout-base',
      redirect: '/tarefas',
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "layout.route" */ '@/components/Layout/Layout.vue'),

      children: [

        {
          path: '/tarefas', name: 'tarefas',
          component: () => import(/* webpackChunkName: "tarefas.route" */ '@/views/Tarefas/Tarefas.vue')
        },

        {
          path: '/dashboards', name: 'dashboards',
          component: () => import(/* webpackChunkName: "dashboards.route" */ '@/views/Dashboard/GerenciarDashboards.vue')
        },

        {
          path: '/odin/dashboard', name: 'odin-dashboard',
          component: () => import('@/views/Odin/OdinDashboardView.vue')
        },

        {
          path: '/calendario', name: 'calendario',
          component: () => import(/* webpackChunkName: "dashboards.route" */ '@/views/Calendario/Calendario')
        },

        {
          path: '/dashboard/:id', name: 'dashboard', props: true,
          component: () => import(/* webpackChunkName: "dashboard.route" */ '@/views/Dashboard/DashboardGrid.vue')
        },

        {
          path: '/dashboard/:id/view', name: 'dashboard-view', props: true,
          component: () => import(/* webpackChunkName: "dashboard.route" */ '@/views/Dashboard/DashboardView.vue')
        },

        {
          path : '/protocolo/:id', name : 'protocolo', props: true, redirect: '/protocolo/:id/geral',
          component: () => import(/* webpackChunkName: "protocolo.route" */ '@/views/Protocolo/Protocolo.vue'),
          children: [
            {
              path: 'geral', name: 'protocolo-geral',
              component: () => import(/* webpackChunkName: "protocolo.route" */ '@/views/Protocolo/Geral/Geral.vue')
            },
            {
              path: 'documentos', name: 'protocolo-documentos',
              component: () => import(/* webpackChunkName: "protocolo.route" */ '@/views/Protocolo/Documentos/Documentos.vue')
            },
            {
              path: 'atos', name: 'protocolo-atos',
              component: () => import(/* webpackChunkName: "protocolo.route" */ '@/views/Protocolo/Atos/GestaoAtos.vue')
            },
            {
              path: 'certidoes', name: 'protocolo-certidoes',
              component: () => import(/* webpackChunkName: "protocolo.route" */ '@/views/Protocolo/Certidoes/GestaoCertidoes.vue')
            },
            {
              path: 'financeiro', name: 'protocolo-financeiro',
              component: () => import(/* webpackChunkName: "protocolo.route" */ '@/views/Protocolo/Financeiro/Financeiro.vue')
            },
            {
              path: 'repasses', name: 'protocolo-repasses',
              component: () => import(/* webpackChunkName: "protocolo.route" */ '@/views/Protocolo/Repasse/Repasse.vue')
            }
          ]
        },

        {
          path : '/oficio/:id', name : 'oficio', props: true, redirect: '/oficio/:id/geral',
          component: () => import(/* webpackChunkName: "oficio.route" */ '@/views/Oficio/Oficio.vue'),
          children: [
            {
              path: 'geral', name: 'oficio-geral',
              component: () => import(/* webpackChunkName: "oficio.route" */ '@/views/Oficio/Geral.vue')
            },
            {
              path: 'dados', name: 'oficio-dados',
              component: () => import(/* webpackChunkName: "oficio.route" */ '@/views/Oficio/Dados.vue')
            },
            {
              path: 'texto', name: 'oficio-texto',
              component: () => import(/* webpackChunkName: "oficio.route" */ '@/views/Oficio/Texto.vue')
            },
          ]
        },

        {
          path : '/notificacao/:id', name : 'notificacao', props: true, redirect: '/notificacao/:id/geral',
          component: () => import(/* webpackChunkName: "notificacao.route" */ '@/views/Notificacao/Notificacao.vue'),
          children: [
            {
              path: 'geral', name: 'notificacao-geral',
              component: () => import(/* webpackChunkName: "notificacao.route" */ '@/views/Notificacao/Geral.vue')
            },
            {
              path: 'dados', name: 'notificacao-dados',
              component: () => import(/* webpackChunkName: "notificacao.route" */ '@/views/Notificacao/Dados.vue')
            },
            {
              path: 'texto', name: 'notificacao-texto',
              component: () => import(/* webpackChunkName: "notificacao.route" */ '@/views/Notificacao/Texto.vue')
            },
          ]
        },

        {
          path : '/ocorrencia/:id', name : 'ocorrencia', props: true, redirect: '/ocorrencia/:id/geral',
          component: () => import(/* webpackChunkName: "ocorrencia.route" */ '@/views/Ocorrencia/Ocorrencia.vue'),
          children: [
            {
              path: 'geral', name: 'ocorrencia-geral',
              component: () => import(/* webpackChunkName: "ocorrencia.route" */ '@/views/Ocorrencia/Geral.vue')
            },
            {
              path: 'dados', name: 'ocorrencia-dados',
              component: () => import(/* webpackChunkName: "ocorrencia.route" */ '@/views/Ocorrencia/Dados.vue')
            },
          ]
        },

        {
          path : '/tarefa/:id', name : 'tarefa', props: true, redirect: '/tarefa/:id/geral',
          component: () => import(/* webpackChunkName: "tarefa.route" */ '@/views/Processos/Tarefa.vue'),
          children: [
            {
              path: 'geral', name: 'tarefa-geral',
              component: () => import(/* webpackChunkName: "tarefa.route" */ '@/views/Processos/Geral.vue')
            },
            {
              path: 'dados', name: 'tarefa-dados',
              component: () => import(/* webpackChunkName: "tarefa.route" */ '@/views/Processos/Dados.vue')
            },
          ]
        },

        {
          path : '/financeiro', name : 'financeiro', props: true, redirect: '/financeiro/resumo',
          component: () => import(/* webpackChunkName: "financeiro.route" */ '@/views/Financeiro/Financeiro.vue'),
          children: [
            {
              path: 'resumo', name: 'financeiro-resumo',
              component: () => import(/* webpackChunkName: "financeiro.route" */ '@/views/Financeiro/Resumo.vue')
            },
            {
              path: 'extrato/:id', name: 'financeiro-extrato', props: true,
              component: () => import(/* webpackChunkName: "financeiro.route" */ '@/views/Financeiro/Extrato.vue')
            },
            {
              path: 'extrato-geral', name: 'extrato-geral', props: true,
              beforeEnter: (to, from, next) => {
                VueBusiness.getVueRoot().getPermission({id: 'PERMITIR_VIZUALIZAR_EXTRATO_GERAL'})? next() : next('/tarefas');
              },
              component: () => import(/* webpackChunkName: "financeiro.route" */ '@/views/Financeiro/ExtratoGeral.vue')
            },
            {
              path: 'analitico', name: 'financeiro-analitico',
              component: () => import(/* webpackChunkName: "financeiro.route" */ '@/views/Financeiro/Analitico.vue')
            },
            {
              path: 'caixasInativos', name: 'caixas-inativos',
              beforeEnter: (to, from, next) => {
                VueBusiness.getVueRoot().getPermission({id: 'PERMITIR_GERENCIAR_CAIXAS_INATIVOS'})? next() : next('/tarefas');
              },
              component: () => import(/* webpackChunkName: "financeiro.route" */ '@/views/Financeiro/CaixasInativos.vue')
            }
          ]
        },

        {
          path: '/atendimento', name: 'atendimento',
          component: () => import(/* webpackChunkName: "atendimento.route" */ '@/views/Atendimento/Atendimento.vue')
        },

        {
          path: '/extrato-diario/:relatorio', name: 'caixa-diario',
          component: () => import(/* webpackChunkName: "caixa-diario.route" */ '@/views/Financeiro/ExtratoGeral.vue')
        },

        {
          path: '/administracao', props: true, component: () => import(/* webpackChunkName: "administracao.route" */ '@/views/Administracao/Administracao.vue'),

          children: [
            {
              path: '', name: 'administracao',
              component: () => import(/* webpackChunkName: "administracao.route" */ '@/views/Administracao/Selecao.vue')
            },
            {
              path: 'sistema', props: true, name: 'administracao-sistema',
              component: () => import(/* webpackChunkName: "administracao.route" */ '@/views/Administracao/Sistema/Sistema.vue')
            },
            {
              path: 'sistema/auditorias', props: true, name: 'administracao-sistema-auditorias',
              component: () => import(/* webpackChunkName: "administracao.route" */ '@/views/Administracao/Sistema/Auditoria.vue')
            },
            {
              path: ':modulo', props: true, name: 'administracao-modulo', meta: {action: 'listar'},
              component: () => import(/* webpackChunkName: "administracao.route" */ '@/views/Administracao/Crud.vue')
            },
            {
              path: ':modulo/lote', props: true, name: 'administracao-lote-entidade', meta: {action: 'lote'},
              component: () => import(/* webpackChunkName: "administracao.route" */ '@/views/Administracao/Crud.vue')
            },
            {
              path: ':modulo/novo', props: true, name: 'administracao-nova-entidade', meta: {action: 'novo'},
              component: () => import(/* webpackChunkName: "administracao.route" */ '@/views/Administracao/Crud.vue')
            },
            {
              path: ':modulo/:id', props: true, name: 'administracao-editar-entidade', meta: {action: 'editar'},
              component: () => import(/* webpackChunkName: "administracao.route" */ '@/views/Administracao/Crud.vue')
            },
            {
              path: ':modulo/auditorias', props: true, name: 'administracao-auditorias-entidade', meta: {action: 'auditorias'},
              component: () => import(/* webpackChunkName: "administracao.route" */ '@/views/Administracao/Crud.vue')
            },
          ]

        },

        // {
        //   path : '/relatorios', name : 'relatorios', props: true,
        //   component: () => import(/* webpackChunkName: "relatorios.route" */ '@/views/Relatorios/Relatorios.vue'),
        //   children: [
        //     {
        //       path: 'e-certidao', name: 'relatorios--e-certidao',
        //       component: () => import(/* webpackChunkName: "relatorios.route" */ '@/views/Relatorios/modulos/ECertidao.vue')
        //     }
        //   ]
        // },

        {
          path: '/implantacao', name: 'implantacao', props: true,
          component: () => import(/* webpackChunkName: "implantacao.route" */ '@/views/Implantacao/Implantacao.vue')
        },

      ]
    },

    {
      path: '/impressao-remota', name: 'impressao-remota',
      component: () => import(/* webpackChunkName: "solicitar-senha.route" */ '@/views/Paineis/ImpressaoRemota.vue')
    },

    {
      path: '/solicitar-senha', name: 'solicitar-senha',
      component: () => import(/* webpackChunkName: "solicitar-senha.route" */ '@/views/Paineis/SolicitarSenha.vue')
    },

    {
      path: '/painel-atendimento', name: 'painel-atendimento',
      component: () => import(/* webpackChunkName: "painel-atendimento.route" */ '@/views/Paineis/PainelAtendimento.vue')
    },

    {
      path: '/painel-atendimento-goiania', name: 'painel-atendimento-goiania',
      component: () => import(/* webpackChunkName: "painel-atendimento-goiania.route" */ '@/views/Paineis/atendimento/PainelAtendimentoGoiania.vue')
    },

    // {
    //   path: '/cnib', name: 'cnib',
    //   meta: { requiresAuth: true },
    //   component: () => import(/* webpackChunkName: "cnib" */ '@/views/Cnib/Cnib.vue')
    // },

    {path: '*', redirect: '/tarefas'}
  ]
})
