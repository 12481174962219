import AnexoBusiness from "@/business/AnexoBusiness.js";
import Http from "@/commons/Http";
import Utils from "@/commons/Utils";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
import IndicadorAbstractService from "@/business/indicadores/IndicadorAbstractService.js";

const path = 'api/v2/registro-pj';

export default {

  ...IndicadorAbstractService,

  async getFichasPorCodigo(params = {}) {
    const {status, dto} = params;
    return IndicadorAbstractService.getFichasPorCodigo({livro: 'REGISTRO_PJ', status, dto});
  },

  async paginationV2(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/v2/pagination?sort=${sort || 'i.codigo'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async getById(id){
    return Http.get(`${path}/${id}`);
  },

  async criarLote(quantidade, dto) {
    return Http.post(`${path}/lote/${quantidade}`, dto);
  },

  async save(id, dto) {
    if(!id){
      return Http.post(`${path}/lote/1`, dto);
    }
    return Http.post(`${path}/${id}`, dto);
  },

  getPapeisPacto() {
    let papeisPacto= this.getPapeis();
    papeisPacto.push({id:'CONTRATANTE', nome: 'Contratante'});
    return papeisPacto;
  },

  getPapeis() {
    return [
      {id: 'ANUENTE', nome: 'Anuente'},
      {id: 'CREDOR', nome: 'Credor'},
      {id: 'DEVEDOR', nome: 'Devedor'},
      {id: 'GARANTIDOR', nome: 'Garantidor'},
      {id: 'TESTEMUNHA', nome: 'Testemunha'},
      {id: 'FIEL_DEPOSITARIO', nome: 'Fiel Depositário'},
    ]
  },

  getStatusImovel(completa = true) {
    if (completa) {
      return [
        {id: 'PENDENTE', nome: 'Pendente'},
        {id: 'ABERTA', nome: 'Aberta'},
        {id: 'ENCERRADA', nome: 'Encerrada'},
        {id: 'CANCELADA', nome: 'Cancelada'},
        {id: 'VIRTUAL', nome: 'Virtual'}
      ]
    } else {
      return [
        {id: 'ABERTA', nome: 'Aberta'},
        {id: 'ENCERRADA', nome: 'Encerrada'},
        {id: 'CANCELADA', nome: 'Cancelada'},
      ]
    }
  },

  camposConsulta() {
    return Utils.orderBy([

      {id: 'codigo', nome: 'Registro', type: 'number'},

      {id: 'status', status: ['ABERTA','CANCELADA','ENCERRADA'], nome: 'Status/Situação', type: 'list', options: this.getStatusImovel(false)},
      {id: 'tipo_registro', nome: 'Tipo de Registro', type: 'list', options: this.getTipos()},
      {id: 'livro_letra', nome: 'Livro (Letra)', type: 'text'},
      {id: 'abertura', status: ['ABERTA','CANCELADA','ENCERRADA'], nome: 'Data de Abertura', type: 'date'},
      {id: 'registro', status: ['ABERTA','CANCELADA','ENCERRADA'], nome: 'Data de Registro', type: 'date'},
      {id: 'encerramento', status: ['ABERTA','CANCELADA','ENCERRADA'], nome: 'Data de Encerramento', type: 'date'},
      {id: 'data_alteracao_manual', status: ['ABERTA','CANCELADA','ENCERRADA'], nome: 'Data da Alteração Manual', type: 'date'},

      {id: 'envolvido_cpf', status: ['ABERTA','CANCELADA','ENCERRADA'],  nome: 'CPF Envolvido', type: 'cpf'},
      {id: 'envolvido_cnpj', status: ['ABERTA','CANCELADA','ENCERRADA'], nome: 'CNPJ Envolvido', type: 'cnpj'},
      {id: 'envolvido_nome', status: ['ABERTA','CANCELADA','ENCERRADA'], nome: 'Nome Envolvido', type: 'text'},
      {id: 'envolvido_papel', status: ['ABERTA','CANCELADA','ENCERRADA'], nome: 'Papel Envolvido', type: 'list', options: IndicadorRealBusiness.getPapeisEnvolvidos()},

      {id: 'ato_minuta', status: ['ABERTA','CANCELADA','ENCERRADA'], nome: 'Minuta dos Atos Registrados', type: 'tsquery'},
      {id: 'ato_titulo', status: ['ABERTA','CANCELADA','ENCERRADA'], nome: 'Título dos Atos Registrados', type: 'tsquery'},
      {id: 'livro_registro', nome: 'Livro - Registro', type: 'text-retro'},
      {id: 'folha_registro', nome: 'Folha - Registro', type: 'text-retro'},

      {id: 'observacoes', nome: 'Observações', type: 'tsquery'},

      {id: 'digitalizacao', status: ['ABERTA','CANCELADA','ENCERRADA'], nome: 'Digitalização', type: 'list', options: AnexoBusiness.tiposDigitalizacao().slice(1)}

    ], 'nome');
  },

  getTipos() {
    return [
      {id: 'A', nome: 'Livro A'},
      {id: 'B', nome: 'Livro B'},
    ];
  },

}
