<template>
  <div class="mt-3" id="text-extractor-wrapper">
    <div class="card">
      <div class="card-body">
        <v-input type="textarea" v-model="dataBus.ocrText" label="Texto para extração"></v-input>
        <v-button class="btn btn-outline-primary" :run="run" :popover="true">
          Processar Texto
        </v-button>
      </div>
    </div>

    <card :loading-full="loading">
      <card-content v-if="!loading">
        <card-table class="table-borderless table-striped mb-2" :dragscroll="true"
                    v-if="atos.length > 0">
          <thead>
            <tr>
              <th>Ato</th>
              <th>Tipo</th>
              <th>Data</th>
              <th>Protocolo</th>
              <th>Título</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="pessoa in atos">
              <td>{{ pessoa.numeroAto }}</td>
              <td>{{ pessoa.tipoAto }}</td>
              <td>{{ pessoa.dataAto }}</td>
              <td>{{ pessoa.numeroProtocolo }}</td>
              <td>{{ pessoa.tituloAto }}</td>
            </tr>
          </tbody>
        </card-table>
      </card-content>
    </card>

  </div>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import TextExtractorBusiness from "@/views/Odin/componentes/TextExtractorBusiness";

export default {
  name: "OdinAtosMatricula",
  components: {CardTable, CardContent, Card},
  props: {
    documentId: {
      type: String,
      required: true
    },
    type: {
      type: String,
      request: true
    },
    dataBus: {
      type: Object,
      request: true
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  computed:{
    atos(){
      return this.dataBus.atosmatricula || [];
    }
  },
  methods:{
    async run(){
      this.$set(this, 'loading', true);
      const resp = await TextExtractorBusiness.extrair('atos-matricula', this.dataBus.ocrText).catch(e => { FrontBusiness.showError('', e.message); return null})
      this.$set(this.dataBus, 'atosmatricula', resp);
      this.$set(this, 'loading', false);
    }
  }
};
</script>
