<template>
  <modal titulo="Comparar Indicador" id="modal-imprimir" :loading="loading">

    <card>
      <card-content>

        <ul class="mb-5" v-show="files.length">
          <li v-for="file in files" :key="file.id">
            <span>{{file.name}}</span> -
            <span>{{file.size | formatSize}}</span>
            <span>
            <a class="icon text-red pointer ml-2 small" @click.prevent="removerArquivo">
              <i class="fal fa-trash"></i>
            </a>
          </span>
          </li>
        </ul>

        <div class="example-btn" v-show="files.length">

          <file-upload
            v-show="false"
            class="btn btn-outline-primary mx-1 pointer"
            :multiple="false"
            :drop="true"
            :drop-directory="true"
            v-model="files"
            :thread="1"
            accept="application/json"
            extensions="json"
            @input-file="start"
            ref="upload" />

        </div>

        <template v-if="!files.length">

          <div class="text-center pt-3">

            <label for="file" class="btn btn-lg btn-outline-primary">
              <i class="fal fa-upload mr-2"></i> Selecionar Arquivos
            </label>

            <div class="pt-2 text-muted">ou arraste e solte os arquivos aqui</div>

          </div>

        </template>

        <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
          <h3>Solte os arquivos aqui</h3>
        </div>

      </card-content>
      <auditoria-details-content
        title-antes="Dados Atuais"
        title-depois="Novos Dados"
        :auditoria="auditoria" :apenas-alteracao="true" :selecionar="true"
                                 @selecionados="setSelecionados"
                                 :formatters="formatters" :title="`Detalhes do Ato`" v-if="auditoria.depois" />

    </card>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-2">Cancelar</button>
      <v-button :run="confirmarAtualizacao" v-if="selecionados.length" :popover="true" class="btn-outline-primary ml-auto">Aplicar Alterações</v-button>
    </template>

  </modal>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import Modal from "@/components/Modal";
import FileUpload from 'vue-upload-component'
import AuditoriaDetailsContent from "@/v2/components/Auditoria/AuditoriaDetailsContent.vue";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
import Utils from "@/commons/Utils";

export default {
    name: "ImportarIndicadorReal",
    components: {AuditoriaDetailsContent, CardContent, Card, Modal, FileUpload},

    props: ['ficha'],

    async mounted() {
      this.formatters = [
        {label: 'CNM', key: 'cnm'},
        {label: 'Categoria', key: 'categoria', type: 'enum', lista: IndicadorRealBusiness.getCategorias()},
        {label: 'Tipo de Imóvel', key: 'tipoImovel', type: 'enum', lista: IndicadorRealBusiness.getTiposImovel()},
        {label: 'CEP', key: 'cep', group: 'Endereço'},
        {label: 'Tipo logradouro', key: 'tipoLogradouro', group: 'Endereço'},
        {label: 'Logradouro', key: 'logradouro', group: 'Endereço'},
        {label: 'Número', key: 'numero', group: 'Endereço'},
        {label: 'Bairro', key: 'bairro', group: 'Endereço'},
        {label: 'Cidade', key: 'cidade', group: 'Endereço'},
        {label: 'Estado', key: 'estado', group: 'Endereço'},
        {label: 'Complemento', key: 'complemento', group: 'Endereço'},

        {label: 'Quadra', key: 'quadra', group: 'Identificação'},
        {label: 'Lote', key: 'lote', group: 'Identificação'},
        {label: 'Localização', key: 'localizacao', group: 'Identificação'},
        {label: 'Empreendimento/Condomínio', key: 'empreendimento', group: 'Identificação'},
        {label: 'Bloco', key: 'bloco', group: 'Identificação'},
        {label: 'Número da Unidade', key: 'unidade', group: 'Identificação'},


        {label: 'CAR', key: 'car', group: 'Informações Complementares'},
        {label: 'INCRA (CCIR)', key: 'incra', group: 'Informações Complementares'},
        {label: 'Certificação no SIGEF', key: 'certificacaoIncra', group: 'Informações Complementares'},
        {label: 'Denominação da Área Rural', key: 'denominacao', group: 'Informações Complementares'},


      ];

    },

    modal: {
      // width: 400,
      escapable: false
    },

    data() {
      return {
        files: [],
        loading : false,
        auditoria: {
          antes: this.ficha,
          depois: null
        },
        formatters: [],
        selecionados: [],
      }
    },

    methods: {

      close(response) {
        FrontBusiness.closeModal(response);
      },

      async start() {
        if (!this.files || this.files.length === 0) return;
        this.loading = true;

        const file = this.files[0]?.file;
        const formData = new FormData();
        formData.append("file", file);

        let config = {
          timeout: 0,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        };

        let atualizacao = await this.$api.post(`api/indicador-real/importar-atualizacao`, formData, config )
          .catch(e => {
            FrontBusiness.showError('', e.message)
            this.files = [];
          });
        this.$set(this.auditoria, 'depois', atualizacao);
        this.loading = false;

        this.$forceUpdate();
      },

      removerArquivo() {
        this.$refs.upload.remove(this.files[0])
        this.$set(this.auditoria, 'depois', null);
        this.$set(this, 'selecionados', []);

      },

      setSelecionados(lista) {
        this.$set(this, 'selecionados', lista);
      },

      async confirmarAtualizacao() {
        this.loading = true;
        let dto = Utils.clone(this.ficha);
        for (const id of this.selecionados) {
          dto[id] = this.auditoria.depois[id];
        }
        this.loading = true;
        let indicador = await IndicadorRealBusiness.save(this.ficha.id, dto).catch(this.showError);
        if (indicador) {
          this.files = [];
          this.loading = false;
          this.close(true);
          FrontBusiness.showSuccess('', 'Dados importados com sucesso');
        }

      }

    }
  }
</script>
