import Http from "@/commons/Http";

const path = 'api/protocolo';

export default {

  async listar(protocolo){
    return Http.get(`${path}/${protocolo}/documento`).then(l => l.map(d => {
      d.versoes = JSON.parse(d.versoes);
      d.checklist = JSON.parse(d?.checklist || false);
      d.indices = JSON.parse(d?.indices || false);
      d.exigencias = JSON.parse(d?.exigencias || false);
      d.cadastro = d.versoes?.[0]?.cadastro || d.cadastro;
      return d;
    }));
  },

  async listarCompleto(protocolo){
    return Http.get(`${path}/${protocolo}/documento/completo`);
  },

  async getById(protocolo, indicador){
    return Http.get(`${path}/${protocolo}/documento/${indicador}`);
  },

  async save(dto, protocolo, id = ''){
    return Http.post(`${path}/${protocolo}/documento/${id}`, dto);
  },

  async salvarIndices(protocolo, id = '', dto){
    return Http.post(`${path}/${protocolo}/documento/${id}/indices`, dto);
  },

  async excluir(protocolo, id){
    return Http.delete(`${path}/${protocolo}/documento/${id}`);
  },

  async regerarChecklist(protocolo){
    return Http.post(`${path}/${protocolo}/documento/checklist`);
  },

  async ordenarDocumentos(id, documentos) {
    return Http.post(`${path}/${id}/documento/ordenar`, documentos);
  },

  async cobrarTaxaEstado(protocolo){
    return Http.get(`${path}/${protocolo}/documento/cobrar-taxa-estado`);
  },

}
