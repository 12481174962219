<template>
  <div class="card card-default border border-sidebar asgard-card">

    <div id="overlay" v-if="loadingFull">
      <div class="w-100 h-100 d-flex justify-content-center align-items-center flex-column">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <span v-if="loadingTxt" class="mt-1">{{ loadingTxt }}</span>
      </div>
    </div>

    <div class="card-body py-3 bg-transparent" data-testid="dados-gerais">

      <h3 class="card-title small border-sidebar font-weight-500" :title="title" v-if="titulo" :class="classesTitle" style="min-height: 20px;">

          <input v-if="showCheckbox" style="position: relative; top: 3px; left: 0px;" type="checkbox"
                 :id="checkOptions.id" v-model="checkOptions.model" @change="changeEvent()"/>

          {{titulo}}

          <toggle :mostrar="mostrar" :run="mostrarToggle" :params="[!mostrar]" :chave="chave" :salvarMostrar="salvarMostrar" v-if="chave"
                  class="ml-1 text-muted float-right" />

          <slot name="acoes"></slot>
          <slot name="descricao" class="w-100"></slot>

      </h3>

      <template v-if="!titulo">
        <slot name="acoes"></slot>
        <slot name="descricao" class="w-100"></slot>
      </template>

      <div v-if="loading" class="text-center">
        <small-loading></small-loading>
      </div>

      <template v-if="!chave || mostrar">
        <slot></slot>
      </template>

      <template v-if="chave">
        <slot name="list"></slot>
      </template>

    </div>

  </div>
</template>

<script>
import Toggle from "@/components/Layout/components/Toggle.vue";
import SmallLoading from "@/components/SmallLoading.vue";

export default {
    name: "Card",
    props:{
      titulo : String,
      chave : String,
      title : String,
      checkOptions : {},
      semBorda : Boolean,
      classTitle : String,
      loading: Boolean,
      salvarMostrar: {type: Boolean, default: false},
      loadingFull: Boolean,
      loadingTxt: String,
    },
    components: {SmallLoading, Toggle},
    data(){
      return {
        mostrar : true
      }
    },
    computed: {
        showCheckbox() {
            return this.checkOptions && Object.keys(this.checkOptions)?.length
        },
        classesTitle(){
            let c = 'border-bottom pb-1 ';
            if(this.semBorda || !this.mostrar) c = 'pb-0 mb-0 ';
            if(this.classTitle) c += this.classTitle;
            return c
        }
    },
    methods: {
        changeEvent() {
            this.$emit('change-check', this.checkOptions);
        },

        mostrarToggle(mostrar) {
            this.$set(this, 'mostrar', mostrar)
        }
    }
  }
</script>

<style lang="scss">
.asgard-card{

  #overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.55);
    z-index: 2;
  }

}
</style>
