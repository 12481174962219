<template>
  <modal titulo="Alterar texto do ato" id="comparacao-texto">
    <div class="row gutters-xs">

      <div class="col">
        <card titulo="Original">
          <card-content>
            <v-input v-model="original"
                     :disabled="true"
                     type="readonly-editor" />
          </card-content>
        </card>
      </div>

      <div class="col">
        <card titulo="Novo">
          <card-content>
            <v-input v-model="novo"
                     :disabled="true"
                     type="readonly-editor" />
          </card-content>
        </card>
      </div>

      <div class="col-12">
        <card titulo="Alterações">
          <card-content>
            <div v-html="changes" class="texto-legado"></div>
          </card-content>
        </card>
      </div>

    </div>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Cancelar</button>
      <button type="button" @click.prevent="confirmar()" class="btn btn-outline-primary">Confirmar Alteração</button>
    </template>

  </modal>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness.js";
import Utils from "@/commons/Utils.js";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import Modal from "@/components/Modal.vue";
import Diff from 'text-diff';

export default {
  name: "ModalCompararTexto",
  components: {CardContent, Card, Modal},
  props: {
    original: String,
    novo: String
  },

  modal: {
    width: 1100
  },

  data(){
    return {
      changes: ''
    }
  },

  mounted() {
    this.load();
  },

  methods:{

    load(){
      const diff = new Diff();
      const textDiff = diff.main(Utils.removeHTML(this.original), Utils.removeHTML(this.novo));
      this.changes = diff.prettyHtml(textDiff);
    },

    close() {
      FrontBusiness.closeModal(false);
    },

    confirmar() {
      FrontBusiness.closeModal(true);
    },

  }

}
</script>

<style lang="css">
#comparacao-texto .texto-legado del{
  background: #FF8585FF !important;
  color: black;
  text-decoration: line-through;
}
#comparacao-texto .texto-legado ins{
  background: #A2FAA2FF !important;
  color: black;
}
</style>
