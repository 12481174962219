<template>

  <th :class="classes" @click="toggleSort" :width="column.width" :style="columnStyle" v-if="show">

    <span v-if="column.headerComponentLabel">
      <span :title="column.label">{{ column.label }}</span>
      <component
        :is="column.headerComponentLabel"
        :column="column" />
    </span>

    <component
      :is="column.headerComponent"
      v-else-if="column.headerComponent"
      :column="column" />

    <span v-else :title="column.label">{{ column.label }}</span>

    <i :class="sortClass" v-if="sortable"></i>

  </th>
</template>

<script>
  export default {
    name: "HeaderCell",

    props: {
      loading: Boolean,
      column: Object,
      direction : String,
    },

    computed:{
      classes(){
        return this.column.headerClass + (this.direction ? ` sort-${this.direction}` : '') + (this.sortable ? ` sortable` : '')
      },
      show(){
        return this.column.show != undefined ? this.column.show : true;
      },
      sortClass(){
        return !this.direction ? 'fal fa-sort' : `fal fa-${this.direction == 'asc' ? 'sort-amount-up' : 'sort-amount-down'}`
      },
      sortable(){
        return this.column.sortable !== false && this.column.field;
      },
      columnStyle(){
        let columnStyle = {};
            columnStyle.cursor = this.loading ? 'wait' : (this.sortable ? 'pointer' : 'auto');
            columnStyle.minWidth = this.column.headerClass == 'column__botao' ? this.column.minWidth || '50px': this.column.minWidth || '100px';
            if(this.column.width){
              columnStyle.width = this.column.width + 'px';
            }
        return columnStyle;
      }
    },

    data() {
      return {
      }
    },

    methods:{
      toggleSort(){
        if (this.sortable && !this.loading) {
          this.$emit( 'change', this.column );
        }
      }
    }
  }
</script>

<style lang=scss>

</style>
