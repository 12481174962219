import Http from "@/commons/Http";

const path = 'api/odin';

export default {

  async paginacaoAcompanhamentoMatriculas(sort, direction, page, size, filters = {}) {
    return Http.post(`${path}/acompanhamento-matriculas/pagination?sort=${sort || 'codigo'},${direction || 'asc'}&page=${page || 1}&size=${size || 30}`, filters);
  },

  async getOcrMatricula(indicadorRealId){
    return Http.get(`${path}/acompanhamento-matriculas/${indicadorRealId}/ocr`);
  },

  async capturarExtracaoAto(atoId){
    return Http.get(`${path}/ato/${atoId}/extracao`);
  },

  async realizarExtracaoAto(atoId, sobrescrever = false){
    return Http.post(`${path}/ato/${atoId}/extracao/${sobrescrever}`);
  },

  /** ********************************** **/

  preprocessarNome (nome) {
    // Converter para minúsculas
    nome = (nome|| '').toLowerCase();

    // Remover acentos
    nome = nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    // Remover caracteres especiais e espaços extras
    nome = nome.replace(/[^\w\s]/gi, '');

    // Remover partículas de nomes (da, de, do, das, dos, e, etc.)
    nome = nome.replace(/\b(da|de|do|das|dos|e|la|le|du|del|von|van|di|al|el|bin|bint)\b/g, ' ');

    // Remover espaços múltiplos
    nome = nome.replace(/\s+/g, ' ').trim();

    return nome;
  },

  // Implementação da distância de Levenshtein
  distanciaLevenshtein(s1, s2) {
    const m = s1.length;
    const n = s2.length;

    // Criar matriz para cálculos intermediários
    const dp = Array(m + 1).fill().map(() => Array(n + 1).fill(0));

    // Inicializar a primeira coluna e linha
    for (let i = 0; i <= m; i++) {
      dp[i][0] = i;
    }

    for (let j = 0; j <= n; j++) {
      dp[0][j] = j;
    }

    // Preencher a matriz
    for (let i = 1; i <= m; i++) {
      for (let j = 1; j <= n; j++) {
        if (s1[i - 1] === s2[j - 1]) {
          dp[i][j] = dp[i - 1][j - 1];
        } else {
          dp[i][j] = 1 + Math.min(
            dp[i - 1][j],     // Deleção
            dp[i][j - 1],     // Inserção
            dp[i - 1][j - 1]  // Substituição
          );
        }
      }
    }

    return dp[m][n];
  },

  calcularSimilaridade(nome1, nome2) {
    // Pré-processar os nomes
    const nome1Proc = this.preprocessarNome(nome1);
    const nome2Proc = this.preprocessarNome(nome2);

    // Calcular a distância de Levenshtein
    const distancia = this.distanciaLevenshtein(nome1Proc, nome2Proc);

    // Calcular a similaridade como percentual
    const tamanhoMaximo = Math.max(nome1Proc.length, nome2Proc.length);

    if (tamanhoMaximo === 0) {
      return 100.0;  // Ambas strings vazias são consideradas iguais
    }

    const similaridade = (1 - distancia / tamanhoMaximo) * 100;

    return similaridade;
  }

}
