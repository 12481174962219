import ChecklistRequisitoBusiness from "@/business/ChecklistRequisitoBusiness.js";
import ChecklistBusiness from "@/business/crud/ChecklistBusiness.js";
import FragmentoBusiness from "@/business/crud/FragmentoBusiness.js";
import ListaBusiness from "@/business/crud/ListaBusiness.js";
import FrontBusiness from "@/business/FrontBusiness.js";
import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness.js";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness.js";
import ExigenciaBusiness from "@/business/livros/ExigenciaBusiness.js";
import Utils from "@/commons/Utils.js";

const cacheChecklists = {};

export default {

  INDICE_EXIGENCIA: '__EXIGENCIA',

  MODO: Object.freeze({
    STORAGE: 'storage',
    VIEW: 'view'
  }),

  init: Utils.debounce(async () => {

    let p1, p2, p3;

    // if(!window.fragmentos?.length){
    //   p1 = FragmentoBusiness.getAll().then(l => window.fragmentos = l);
    // }

    if(!window.listas?.length){
      p2 = ListaBusiness.getAll().then(l => window.listas = l);
    }

    // if(!window.checklists?.length){
    //   p3 = ChecklistBusiness.getAll().then(l => window.checklists = l);
    // }

    await Promise.all([p2]);

  }),

  getChecklists(dto, tipo){

    let checklists = dto?.checklist || [].filter(c => c && c.ativo);

    switch (tipo.toLowerCase()){
      case 'protocolo':
        checklists = checklists.filter(c => (c.tipo_checklist ? c.tipo_checklist === 'PROTOCOLO' : c.protocolo));
        break;
      case 'ato':
        checklists = checklists.filter(c => (c.tipo_checklist ? c.tipo_checklist === 'ATO' : !c.protocolo));
        break;
      case 'envolvidos':
        checklists = checklists.filter(c => c.tipo_checklist === 'ENVOLVIDOS');
        break;
      case 'etapa':
        checklists = checklists.filter(c => c.tipo_checklist === 'ETAPA');
        break;
      default:
        checklists = checklists.filter(c => !(c.tipo_checklist ? c.tipo_checklist === 'PROTOCOLO' : c.protocolo));
        break;
    }

    return Utils.orderBy(checklists, 'ordem').map(c => ({
      id: c.id,
      nome: c.titulo || c.nome,
      requisitos: c.checklist,
      // atosVinculados: c.atosVinculados || [],
      // indices: Utils.clone(dto.indices)
    }));
  },

  async processarEntidade(dto, checklists, tipo, documentos = [], modo = 'storage'){

    let vinculos = {};
    if (dto?.envolvidos) {
      vinculos.envolvidos = dto?.envolvidos;
    }

    if (dto?.indicadorReal) {
      vinculos.matricula = dto?.indicadorReal;
    }

    if (dto?.indicadorReal) {
      vinculos.matricula = dto?.indicadorReal;
    }

    if (documentos?.length) {
      vinculos.documentos = Object.freeze(documentos);
    }

    return await this.gerarChecklist(checklists, dto?.indices, vinculos, modo);

  },

  async gerarChecklist(checklists = [], respostas = {}, vinculos = {}, modo = 'view'){

    let base = {
      exigencias: [],
      indicesExigencia: {},
      indices: {},
      grupos: [],
      contador: {quantidade : 0, respondidos : 0}
    };

    for (let grupo of checklists) {

      let ctrl = {
        exigencias: base.exigencias,
        indicesExigencia: base.indicesExigencia,
        indices: base.indices,
        requisitos: [],
        contador: {quantidade : 0, respondidos : 0},
        id: grupo.id,
        recursividade: [grupo.id]
      };

      await this.tratarGrupoChecklist(grupo, respostas, vinculos, ctrl, modo);

      base.grupos.push({id: grupo.id, nome: grupo.nome, requisitos: ctrl.requisitos.map(r => {
        // if(modo == this.MODO.STORAGE){
        //   return {
        //    TODO tratar pra nao salvar o checklist completo
        //   }
        // }
        return r;
        }), contador: ctrl.contador});

      base.contador.quantidade += ctrl.contador.quantidade;
      base.contador.respondidos += ctrl.contador.respondidos;

    }

    return base;

  },

  async tratarGrupoChecklist(grupo, respostas = {}, vinculos = {}, ctrl, modo = 'view' ){

    if(!ctrl){
      ctrl = {
        exigencias : [],
        indicesExigencia : {},
        indices : {},
        requisitos : [],
        contador : { quantidade : 0, respondidos : 0},
        nome: grupo.nome,
        titulo: grupo.titulo,
        id: grupo.id,
        recursividade: [grupo.id]
      };
    }

    for (let requisito of grupo.requisitos) {
      await this.tratarRequisito({...requisito}, ctrl, respostas, vinculos, 1, undefined,  modo);
    }

    return ctrl;

  },

  criarRequisito(requisito, resposta, modo = 'view'){

    let req = Utils.clone(requisito);
    req.condicoes = (req?.condicoes || []).map(r => r.valor);
    req.resposta = resposta;

    if(!req.temExigencia){
      delete req.temExigencia;
    }

    if(!req.condicoes?.length){
      delete req.condicoes;
    }

    if(modo == this.MODO.STORAGE){

      delete req.ajuda;
      delete req.multiplicador
      if(!req.sublist?.length){
        delete req.sublist;
      }
    }

    return req;

  },

  async tratarRequisito(requisito, ctrl, respostas = {}, vinculos = {}, n = 1, prefix = '', modo = 'view'){

    if (++n > 20 || (modo == this.MODO.STORAGE && requisito.tipo == 'LABEL')) {
      return;
    }

    if (requisito.tipo == 'CHECKLIST') {
      if(ctrl.recursividade.includes(requisito.checklist)){
        return;
      }

      ctrl.recursividade.push(requisito.checklist);

      let c = cacheChecklists?.[requisito.checklist] || [];
      if(!c?.length && requisito.checklist){
        c = await ChecklistBusiness.getByIdCached(requisito.checklist).then(e => e?.checklist || []).catch(e => []);
        cacheChecklists[requisito.checklist] = c;
      }

      for (let req of c) {
        await this.tratarRequisito({...req}, ctrl, respostas, vinculos, n, prefix, modo);
      }
      return;

    }

    if (!requisito.indice) {
      requisito.semIndice = true;
      //adiciona na lista de itens sem indice
      ctrl.requisitos.push(this.criarRequisito(requisito, undefined, modo));
      return;
    }

    // if(ctrl.indices[requisito.indice] != undefined){
    //   requisito.indiceDuplicado = true;
    // }

    // CAPTURA RESPOSTA ANTERIOR DO INDICE
    if (requisito.tipo != 'AUTOMATICO' && respostas?.[requisito.indice]) {
      ctrl.indices[requisito.indice] = respostas[requisito.indice];
    } else if (requisito.tipo == 'AUTOMATICO') {

      try {
        await this.automatizarValor(requisito, ctrl, respostas, vinculos);
      } catch (e) {
        console.error('erro ao tentar prencher resposta', e)
      }

    }

    if(ctrl.indices[requisito.indice] === undefined){
      ctrl.indices[requisito.indice] = null;
    }

    if (!['GROUP', 'LABEL'].includes(requisito.tipo)) {

      ++ctrl.contador.quantidade;

      let val = ctrl.indices[requisito.indice];

      if (Array.isArray(val) || requisito.apresentacao == 'checkbox' || requisito.multiplo) {
        val = val?.length;
      }

      if (val) {
        ++ctrl.contador.respondidos;
      }

    }

    if (requisito.tipo == 'GROUP') {
      await this.tratarRepetidores({...requisito}, ctrl, respostas, vinculos, n, modo);
    } else if (requisito.condicoes?.length) {
      await this.tratarSubrequisitos({...requisito}, ctrl, respostas, vinculos, n, modo);
    } else {
      requisito.uuidReq = `req-${prefix}${prefix ? '-':''}${requisito.indice.replace(' ', '_')}`;

      requisito = this.criarRequisito(requisito, ctrl.indices[requisito.indice], modo);

      if(requisito.tipo == 'EXIGENCIA' && ctrl.indices[requisito.indice]){

        const exigencia = {
          // requisito: requisito,
          indice: requisito.indice,
          // indices: ctrl.indices,
          // envolvido: vinculos?.envolvido,
          exigencia: Utils.clone(ctrl.indices[requisito.indice])
        }

        ctrl.exigencias.push(exigencia);
        requisito.temExigencia = true;
        requisito.ctrlExigencias = [exigencia];

      }

      ctrl.requisitos.push(requisito);

    }

  },

  async automatizarValor(requisito, ctrl, respostas = {}, vinculos = {}){

    requisito.readOnly = true;
    switch (requisito.chave) {
      case 'TIPO_PESSOA':
        if (vinculos.envolvido) {
          ctrl.indices[requisito.indice] = vinculos.envolvido.tipoPessoa == 'JURIDICA' ? 'Pessoa jurídica' : 'Pessoa física';
        }
        break;
      case 'INDIVIDUO_MENOR':
        if (vinculos.envolvido && vinculos.envolvido.tipoPessoa != 'JURIDICA') {
          ctrl.indices[requisito.indice] = vinculos.envolvido.menor ? 'Sim' : 'Não';
        }
        break;
      case 'INDIVIDUO_INCAPAZ':
        if (vinculos.envolvido && vinculos.envolvido.tipoPessoa != 'JURIDICA') {
          ctrl.indices[requisito.indice] = vinculos.envolvido.incapaz ? 'Sim' : 'Não';
        }
        break;
      case 'INDIVIDUO_FALECIDO':
        if (vinculos.envolvido && vinculos.envolvido.tipoPessoa != 'JURIDICA') {
          ctrl.indices[requisito.indice] = vinculos.envolvido.falecido ? 'Sim' : 'Não';
        }
        break;
      case 'ESTADO_CIVIL':
        if (vinculos.envolvido && vinculos.envolvido.tipoPessoa != 'JURIDICA'){
          let valor;
          if(vinculos.envolvido.estadoCivil != 'CASADO' && vinculos.envolvido.possuiUniaoEstavel){
            valor = 'União Estável';
          }else{
            valor = FrontBusiness.getLabel(vinculos.envolvido.estadoCivil, IndicadorPessoalBusiness.getEstadosCivil())?.replace('o(a)', 'o')
          }
          ctrl.indices[requisito.indice] = valor || 'Não Informado';
        }
        break;
      case 'CASAMENTO_APOS_LEI_6515':
        if (vinculos.envolvido && vinculos.envolvido.tipoPessoa != 'JURIDICA') {
          ctrl.indices[requisito.indice] = vinculos.envolvido.casamentoAposLei6515 ? 'Sim' : 'Não';
        }
        break;
      case 'REGIME_CASAMENTO':
        if (vinculos.envolvido && vinculos.envolvido.tipoPessoa != 'JURIDICA'){
          let valor;
          if(vinculos.envolvido.regimeCasamento){
            valor = FrontBusiness.getLabel(vinculos.envolvido.regimeCasamento, IndicadorPessoalBusiness.getRegimes())
          }
          ctrl.indices[requisito.indice] = valor;
        }
        break;
      case 'LIVRO':
        if (vinculos.matricula) {
          ctrl.indices[requisito.indice] = 'MATRICULA';
        }
        if (vinculos.registroAuxiliar) {
          ctrl.indices[requisito.indice] = 'REGISTRO_AUXILIAR';
        }
        break;
      case 'CATEGORIA_IMOVEL':
        if (vinculos.matricula) {
          ctrl.indices[requisito.indice] = Utils.capitalize(vinculos.matricula.categoria?.toLowerCase() || '');
        }
        break;
      case 'UNIDADE_AUTONOMA':
        if (vinculos.matricula) {
          ctrl.indices[requisito.indice] = vinculos.matricula.unidadeAutonoma != undefined ? vinculos.matricula.unidadeAutonoma ? 'Sim' : 'Não' : null;
        }
        break;
      case 'POSSUI_DEVEDOR':
        if(vinculos.envolvidos){
          ctrl.indices[requisito.indice] = vinculos.envolvidos.filter(e => e.papel == 'DEVEDOR').length ? 'Sim' : 'Não';
        }
        break;
      case 'PAPEL':
        if (vinculos.envolvido) {
          ctrl.indices[requisito.indice] = IndicadorRealBusiness.getPapeisEnvolvidos().find( e => e.id === vinculos.envolvido?.papel)?.nome;
        }
        break;
    }

  },

  async tratarSubrequisitos(requisito, ctrl, respostas = {}, vinculos = {}, n = 1, modo = 'view'){

    let exigencias = [];
    let resposta = ctrl.indices[requisito.indice];
    let respostaLocal = Utils.clone(resposta);
    let valores = requisito.condicoes.map(v => v.valor);

    if (requisito.tipo === 'VINCULO' && requisito.vinculo === 'TITULO') {

      let documentos = vinculos.documentos || [];
      let tipos = requisito.condicoes.map(v => v.id);

      // console.group('tratarSubrequisitos');
      // console.debug('indice', Utils.clone(requisito.indice));
      // console.debug('respostaLocal', Utils.clone(respostaLocal));
      // console.debug('resposta', Utils.clone(resposta));
      // console.debug('valores', Utils.clone(valores));
      // // console.debug('tipos', tipos);
      // console.debug('documentos', Utils.clone(documentos));
      // console.groupEnd();

      let docs = documentos.filter(d => tipos.includes(d.tipoId)).map(d => d.id);

      if (Array.isArray(resposta)) {
        // console.debug('docs', Utils.clone(docs));
        // console.debug('resposta', Utils.clone(resposta));
        // console.debug('valores', Utils.clone(valores));
        // console.debug('documentos', Utils.clone(documentos));
        resposta = resposta.filter(i => docs.includes(i));
        respostaLocal = documentos.filter(d => resposta.includes(d.id)).map(d => d.tipo);
      } else if(!docs.includes(resposta)){
          resposta = null;
          respostaLocal = null;
      }

    }else{

      if (!Array.isArray(resposta) && !valores.includes(resposta)) {
        resposta = null;
      }

      if (Array.isArray(respostaLocal)) {
        resposta = [];
        respostaLocal.forEach((r, i) => {
          if (valores.includes(r)) resposta.push(ctrl.indices[requisito.indice][i])
        })
      }

    }

    ctrl.indices[requisito.indice] = resposta;

    let pularExigencia = false;
    requisito.temExigencia = false;

    const base = {
      exigencia: respostas?.[this.INDICE_EXIGENCIA]?.[requisito.indice] || requisito.exigencia,
      original: requisito.exigencia,
      indice: requisito.indice,
      // requisito: requisito,
      indices: ctrl.indices,
      envolvido: vinculos?.envolvido
    }

    ctrl.indices[this.INDICE_EXIGENCIA] = ctrl.indices[this.INDICE_EXIGENCIA] || {};
    if(respostas?.[this.INDICE_EXIGENCIA]?.[requisito.indice]){
      ctrl.indices[this.INDICE_EXIGENCIA][requisito.indice] = respostas?.[this.INDICE_EXIGENCIA]?.[requisito.indice];
    }

    if (requisito.tipo == 'SELECT' && requisito.apresentacao == 'checkbox') {

      pularExigencia = true;
      switch (requisito.tipoExigencia) {
        // nenhum item selecionado
        case 'vazio':
          if (requisito.exigencia && !resposta?.length) {
            exigencias.push({...base});
          }
          break;

        case 'incompleto':
          if (requisito.exigencia && resposta?.length != requisito.condicoes?.length) {
            exigencias.push({...base});
          }
          break;

        // itens nao selecionados geram exigencia
        case 'unchecked':
          (requisito.condicoes || []).filter(c => c.exigencia && !(resposta || []).includes(c.valor)).forEach(c => {
            const idx = `${base.indice}::${c.valor}`;

            const editada = respostas?.[this.INDICE_EXIGENCIA]?.[idx];
            if(editada){
              ctrl.indices[this.INDICE_EXIGENCIA][idx] = editada;
            }

            exigencias.push({...base, exigencia: editada || c.exigencia, original: c.exigencia, indice: idx});
          });
          break;

        default:
          pularExigencia = false;
          break;

      }

    }

    respostaLocal = Array.isArray(respostaLocal) ? respostaLocal : [respostaLocal];
    let l = (requisito.condicoes || []).filter(r => respostaLocal.includes(r.valor));

    if (!pularExigencia && respostaLocal.length) {
      l.filter(c => c.exigencia).forEach(c => {
        const idx = `${base.indice}::${c.valor}`;

        const editada = respostas?.[this.INDICE_EXIGENCIA]?.[idx];
        if(editada){
          ctrl.indices[this.INDICE_EXIGENCIA][idx] = editada;
        }
        // console.debug('--ex..', idx, editada)
        exigencias.push({...base, exigencia: editada || c.exigencia, original: c.exigencia, indice: idx});
      });
    }

    if (exigencias.length) {
      ctrl.exigencias.push(...exigencias);
      requisito.temExigencia = true;
      requisito.ctrlExigencias = exigencias;

      // console.debug(
      //   '--ex', this.INDICE_EXIGENCIA, requisito.indice,
      //   respostas?.[this.INDICE_EXIGENCIA],
      //   respostas
      // );

    }

    ctrl.requisitos.push(this.criarRequisito(requisito, ctrl.indices[requisito.indice], modo));

    for (let condicao of l) {
      for (let req of condicao.requisitos) {
        await this.tratarRequisito({...req}, ctrl, respostas, vinculos, n, undefined, modo);
      }
    }

  },

  limparObjetoEnvolvido(envolvido, simplificarVersao = false){
    const eCopy = Utils.clone(envolvido);

    delete eCopy.editandoInventariante;
    delete eCopy.indisponivel;
    delete eCopy.exibicao;
    delete eCopy.ocorrenciasAtivas;
    delete eCopy.inventarianteDocumentoInvalido;
    delete eCopy.ALERTA_DOCUMENTO;
    delete eCopy.contador;
    if(simplificarVersao){
      delete eCopy.id;
      eCopy.indicadorPessoalVersao = {id : eCopy.indicadorPessoalVersao.id};
    }
    if (JSON.stringify(eCopy.inventarianteVersao) === '{}') {
      eCopy.inventarianteVersao = null;
    }
    return eCopy;
  },

  async tratarRepetidores(requisito, ctrl, respostas = {}, vinculos = {}, n = 1, modo = 'view'){
    if (requisito.multiplicador == 'matricula') {
      requisito.multiplicador = 'manual';
    }

    if(ctrl?.repetindo && requisito.multiplicador != 'manual'){ //
      console.error("O checklist já está dentro de um requisito multiplicador");
      return;
    }

    if (!requisito.condicoes?.length) {
      console.error("Repetidor sem condições para repetir");
      return;
    }

    let sublist = [];

    switch (requisito.multiplicador) {
      case 'envolvido': {

        ctrl.indices.envolvidos = {...(ctrl.indices.envolvidos || {})};

        if(vinculos.envolvidos){
          sublist = vinculos.envolvidos.filter(e => requisito.papeis.includes(e.papel));
          sublist = Utils.orderBy(Utils.uniqBy(sublist, 'indicadorPessoalId'),  'ordem')
            .map(e => (this.limparObjetoEnvolvido({
              ...e,
              ...(e.indicadorPessoalVersao || {})
            })));

          sublist.forEach(envolvido => {
            ctrl.indices.envolvidos[envolvido.indicadorPessoalId] = {
              ...(ctrl.indices.envolvidos[envolvido.indicadorPessoalId] || {})
            };
          });
        }

        break;
      }

      case 'manual': {

        let list = ctrl.indices[requisito.indice] || [];
        if(!Array.isArray(list)){
          list = [];
        }

        ctrl.indices[requisito.indice] = list.map(e => {
          let r = e || {};
          delete r.requisitos;
          return r;
        });
        sublist = ctrl.indices[requisito.indice].map(e => ({}));

        break;
      }

      default:{
        console.error("Favor verificar a configuração do requisito: " + requisito.multiplicador);
        return;
      }

    }

    let i = -1;
    for (let grupo of sublist) {

      ++i;
      grupo.requisitos = [];

      let ctrlInterno = {
        exigencias: ctrl.exigencias,
        indicesExigencia: ctrl.indicesExigencia,
        indices: null,
        requisitos: grupo.requisitos,
        contador: ctrl.contador,
        repetindo: true,
        recursividade: [...ctrl.recursividade]
      };

      let respostasInternas = {}, prefix = undefined;

      switch (requisito.multiplicador) {
        case 'envolvido':
          ctrlInterno.indices = ctrl.indices.envolvidos[grupo.indicadorPessoalId];
          respostasInternas = respostas?.envolvidos?.[grupo.indicadorPessoalId] || {};
          prefix = grupo.indicadorPessoalId;
          vinculos.envolvido = grupo;
          break;
        default:
          ctrlInterno.indices = ctrl.indices[requisito.indice][i];
          respostasInternas = respostas?.[requisito.indice]?.[i] || {};
          prefix = i;
          break;
      }

      // if(requisito.indice == 'grupo_manual'){
      //   console.debug(i, sublist, respostasInternas, ctrlInterno.indices, ctrl.indices[requisito.indice], respostas[requisito.indice] );
      // }

      for (let condicao of requisito.condicoes) {
        for (let req of condicao.requisitos) {
          await this.tratarRequisito({...req}, ctrlInterno, respostasInternas, vinculos, n, prefix, modo);
        }
      }

      // if(requisito.indice == 'grupo_manual'){
      //   console.debug(i, sublist, respostasInternas, ctrlInterno.indices, ctrl.indices[requisito.indice], respostas[requisito.indice] );
      // }

      switch (requisito.multiplicador) {
        case 'envolvido':
          delete vinculos.envolvido;
          break;
        default:
          break;
      }

    }

    ctrl.requisitos.push(this.criarRequisito({...requisito, sublist}, null, modo));

  },

  async copilarExigencias(protocolo = {}, dto = {}, exigencias = [], apenasTexto = true) {

    let out = [], envolvidos = Utils.clone(dto?.envolvidos || []);

    let indices = {};
    if (dto?.ficha) {
      indices.matricula = Utils.clone(dto?.ficha);
    }

    if(dto?.tipoDocumento){
      indices.documento = {
        tipoDocumento : dto?.tipoDocumento?.nome,
        tituloDocumento: dto.nome
      }
    }

    for (let exigencia of exigencias) {
      let idx = {...indices, ...exigencia.indices};
      if(exigencia?.envolvido){
        idx.envolvido = {...exigencia?.envolvido}
      }
      let texto = await ExigenciaBusiness.copilarTextoExigencia(protocolo, exigencia.exigencia, idx, envolvidos);
      if(apenasTexto){
        out.push(texto);
      }else{
        let original = await ExigenciaBusiness.copilarTextoExigencia(protocolo, exigencia.original, idx, envolvidos);
        out.push({...exigencia, textoCopilado : texto, original});
      }
    }

    return out;
  },


  /*******************************/

  async removerFragmentos(original){

    //http://localhost:8081/#/administracao/checklist/0daa3625-7a57-4ee8-a9dc-6416246f7f52?dominio=
    const atual = Utils.clone(original);
    const checklist = [];

    for (let requisito of atual) {
      const r = await this.removerFragmentoRequisito(requisito);
      checklist.push(...r);
    }

    // console.debug('checklist', checklist);

    return checklist;

  },

  async removerFragmentoRequisito(requisito) {

    if (requisito.tipo == 'CHECKLIST') {
      let c = await ChecklistBusiness.getByIdCached(requisito.checklist).then(e => e.ativo ? (e?.checklist || []) : []).catch(e => []);
      const checklist = [];
      for (let requisito of c) {
        let r = await this.removerFragmentoRequisito(requisito);
        checklist.push(...r);
      }
      return checklist;
    }

    if(!ChecklistRequisitoBusiness.campos().map(c => c.id).includes(requisito.tipo)){
      return [];
    }

    if (requisito.condicoes?.length) {
      for(let condicao of requisito.condicoes) {
        const requisitos = [];
        for(let req of condicao.requisitos) {
          const r = await this.removerFragmentoRequisito(req);
          requisitos.push(...r);
        }
        condicao.requisitos = requisitos;
      }
    }

    return [requisito];

  },

  atualizarIndicesExigencias(exigencias, indices) {
    return exigencias.map(e => {
      e.indices = indices
      return e;
    });
  },

  async limparIndices(indices){

    if(indices?.envolvidos?.length){
      Object.keys(indices.envolvidos).forEach(key => {
        const envolvido = indices.envolvidos[key]?.envolvido;
        if (envolvido && envolvido.id) {
          indices.envolvidos[key].envolvido = { id: envolvido.id };
        }
      });
    }

    return indices;
  }


}
