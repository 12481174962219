<template>
  <div class="mt-3" id="odinIndicadorRealAtoProcessamento">

    <card :loading-full="loading">
      <card-content v-if="!loading">

        <div class="alert alert-outline-danger mb-3 w-100 small" v-if="atosSemTexto.length > 0">
          Antes de realizar a análise via IA dos atos, certifique-se de que todos eles estejam cadastrados e com o texto correto.
        </div>

        <div class="d-flex">
          <div class="mr-auto">
            <p class="text-muted pb-2 mb-2 small">
              <template v-if="atosSelecionados.length > 0">{{atosSelecionados.length}} ato(s) selecionado(s)</template>
              <template v-else>Nenhum ato selecionado</template>
            </p>
          </div>

          <v-button :run="atualizarMinutas" :button="true" v-if="atosSelecionados.length > 0"
                    :popover="true" position="dropleft" class="btn btn-sm btn-outline-success icon ml-auto">
            Atualizar Minutas
          </v-button>

        </div>

        <card-table class="table-striped mb-2">
          <thead>
            <tr>
              <th style="width: 40px">
                <i v-if="atosSelecionados.length > 0" @click="toggleSelectAll(false)"
                   title="Limpar Seleção" class="pointer fa-solid fa-circle-check text-success"></i>
                <i v-else @click="toggleSelectAll(true)"
                   title="Selecionar Todos" class="pointer fa-solid fa-circle-dashed"></i>
              </th>
              <th style="width: 40px">Número</th>
              <th style="width: 45px">Origem</th>
              <th style="width: 45px">Tipo</th>
              <th class="">Data</th>
              <th class="">Título</th>
              <th></th>
              <th class=""style="width: 70px"></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="ato in atos">
              <tr>

                <td class="text-center">
                  <i v-if="ato.ato && ato.ato.validado" class="pointer fa-solid fa-check-circle text-success" title="Ato Já Validado no Sistema"></i>
                  <i v-else-if="ato.selecionado" @click="$set(ato, 'selecionado', false)" class="pointer fa-solid fa-circle-check text-primary"></i>
                  <i v-else @click="$set(ato, 'selecionado', true)" class="pointer fa-solid fa-circle-dashed"></i>
                </td>

                <td class="text-center">{{ato.numero}}</td>

                <td class="lborder text-center text-success" v-if="ato.ato" title="Informação Cadastrada no Sistema"><i class="fa-solid fa-database"></i></td>
                <td class="lborder text-center text-danger" v-else title="Informação vinda do OCR"><i class="fa-solid fa-microchip-ai"></i></td>

                <template v-if="ato.ato">
                  <td class="text-center">{{ato.ato.tipo == 'AVERBACAO' ? 'AV' : ato.ato.tipo.charAt(0)}}</td>
                  <td>{{$formatters.date(ato.ato.registro)}}</td>
                  <td class="small">{{ato.ato.titulo || '-'}}</td>
                  <td class="lborder text-center text-success" v-if="ato.ato.possuiTexto" title="Possui texto cadastrado"><i class="fa-solid fa-text"></i></td>
                  <td class="lborder text-center text-danger" v-else title="Não possui texto cadastrado"><i class="fa-solid fa-text-slash"></i></td>
                </template>
                <template v-else>
                  <td class="text-center">-</td>
                  <td class="text-center">-</td>
                  <td class="small">-</td>
                  <td class="small"></td>
                </template>

                <td class="lborder">
                  <i v-if="!ato.toggleMinuta" class="fa-solid fa-chevron-down pointer" @click.prevent="toggle('toggleMinuta', ato, true)"></i>
                  <i v-if="ato.toggleMinuta" class="fa-solid fa-chevron-up pointer" @click.prevent="toggle('toggleMinuta', ato, false)"></i>
                </td>

              </tr>
              <tr v-if="ato.toggleMinuta" style="background: transparent">
                <td colspan="100%">
                  <div class="row gutters-xs">
                    <div class="col-6">
                      <div class="border-sidebar p-2 m-2" style="border: 1pt solid var(--second-color)">
                        <h5>Texto Extraído OCR</h5>
                        <div class="texto-minuta small" v-html="ato.texto" style="width: 500px;"/>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="border-sidebar p-2 m-2" style="border: 1pt solid var(--second-color)">
                        <h5>Texto Cadastrado</h5>
                        <div v-if="ato.ato && ato.ato.texto" class="texto-minuta small" v-html="ato.ato.texto" style="width: 500px;"/>
                      </div>
                    </div>
                    <div class="w-100">
                      <div class="texto-minuta" v-html="ato.replyStr" />
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </card-table>
      </card-content>

    </card>

  </div>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";

export default {
  name: "OdinIndicadorRealOcrProcessamento",
  components: {CardTable, CardContent, Card},
  props: {
    indicadorRealId: String,
    dataBus: {
      type: Object,
      request: true
    }
  },
  data() {
    return {
      loading: true,
      textos: [],
      atos:[],
      ctrl:{
        toggleSelecao:false
      }
    };
  },
  computed:{
    atosSelecionados(){
      return this.atos?.filter(r => !!r.selecionado)
    },
    atosSemTexto(){
      return this.atos?.filter(r => !r.ato?.possuiTexto)
    },
  },
  async mounted() {
    await this.load();
  },
  methods:{
    async load(){
      this.$set(this, 'loading', true);

      const atos = await this.$api.get(`api/odin/acompanhamento-matriculas/${this.indicadorRealId}/ocr`)
        .catch(e => FrontBusiness.showError('', e.message));
      this.$set(this, 'atos', atos);
      this.$set(this, 'loading', false);
    },

    toggle(key, ato, valor){
      this.$set(ato, key, valor);
    },

    toggleSelectAll(result){
        this.atos.forEach(ato => {
          this.$set(ato, 'selecionado', result);
        });
    },

    async atualizarMinutas(){
      for(let pos in this.atosSelecionados) {
        const id = this.atosSelecionados[pos]?.atoId;
        const texto = this.atosSelecionados[pos]?.texto;
        if(id && texto){
          await this.$api.post(`api/ato-legado/${id}/atualizar/minuta`, {value: texto});
          // this.$set(this.atosSelecionados[pos], 'selecionado', false);
        }
      }

      FrontBusiness.showSuccess('', 'Minutas atualizadas');

      this.load();

    }

  }
};
</script>

<style lang="scss">
#odinIndicadorRealAtoProcessamento{
  th.rborder, td.rborder{
    border-right: 1pt solid gray;
  }
  th.lborder, td.lborder{
    border-left: 2pt solid var(--asgard-input);
  }
}
</style>
