<template>
  <div class="row gutters-xs">

    <template v-if="$root.isEstadoPR">

      <v-input :disabled="!edicao" v-model="item.diferido" type="radio"
               label="Custa Diferida" class="pb-2 col-auto" :default="false" @change="toggleDiferido" />

      <v-input type="v-select" :options="motivosIsencao" v-model="item.motivoDiferimento"
               v-if="item.diferido && motivosIsencao.length" :error="validation.motivoDiferimento"
               label="Motivo do Diferimento" class="pb-2 col" />

      <div class="w-100" v-if="item.diferido"></div>

    </template>

    <template v-if="$root.isEstadoRO">

      <v-input :disabled="!edicao" v-model="item.diferido" type="radio"
               label="Custa Adiada" class="pb-2 col-auto" :default="false" @change="toggleDiferido" />

      <v-input :disabled="!edicao" v-model="item.diferimentoRecebido" type="radio" v-if="!item.diferido"
               label="Custa Adiada Recebida" class="pb-2 col-auto" :default="false" />

      <div class="w-100"></div>

    </template>

    <v-input :disabled="!edicao" v-model="item.isento" type="radio"
             v-if="!item.diferido && item.tipoRegistro != 'DESCONTO_ENTES_PUBLICOS'" @change="limparRessarcimento()"
             :label="tituloIsento" class="pb-2 col-auto" />

    <template v-if="item.isento && $root.isEstadoRS">
      <v-input type="v-select" :options="tipoIsencaoRs"
               v-if="tipoIsencaoRs.length" v-model="item.ressarcimento.tipoIsencao"
               label="Tipo de Isenção" class="col-3" />
    </template>

    <template v-if="item.isento && $root.isEstadoSP">
      <v-input type="v-select" :options="tipoIsencaoSp"
               v-if="tipoIsencaoSp.length" v-model="item.ressarcimento.motivoIsencao"
               label="Motivo da Isenção" class="col" />
    </template>

    <template v-if="item.isento && $root.isEstadoRS">
      <v-input type="v-select" :options="motivoIsencaoRS"
               v-if="motivoIsencaoRS.length" v-model="item.ressarcimento.motivoIsencao"
               label="Motivo da Isenção" class="col" />
    </template>

    <template v-if="item.isento && ['MG', 'PR', 'RO', 'BA', 'CE', 'MS'].includes($root.config.estado)">
      <v-input type="v-select" :options="motivosIsencao" v-model="item.ressarcimento.motivoIsencao"
               v-if="motivosIsencao?.length" :error="validation.motivoIsencao"
               label="Motivo da Isenção" class="pb-2 col" />

      <template v-if="item.isento && ($root.config.estado === 'CE' || $root.config.estado === 'BA')">
        <v-input type="text" class="col" v-if="!motivosIsencaoPersonalizado?.length"
                 :error="validation.motivo"
                 label="Descrição do Motivo" v-model="item.ressarcimento.motivo"
                 :disabled="!edicao" />

        <v-input type="v-select" v-else :options="motivosIsencaoPersonalizado" v-model="item.ressarcimento.motivo"
                 :error="validation.motivo"
                 label="Motivo da Isenção" class="pb-2 col" />
        <div class="w-100"></div>
      </template>

      <template v-if="['RO'].includes($root.config.estado)">
        <v-input type="text" class="col-4"
                 :error="validation.numeroProcesso" label="Número do processo/ofício" v-model="item.ressarcimento.numeroProcesso"
                 :disabled="!edicao" />
      </template>

      <div class="w-100"></div>
    </template>

    <template v-if="item.isento && $root.config.estado === 'SC'">

      <v-input type="v-select" :options="motivosIsencao" v-model="item.ressarcimento.motivoIsencao"
               v-if="motivosIsencao.length"
               @change="pesquisarEntidades()" :error="validation.motivoIsencao"
               label="Motivo da Isenção" class="pb-2 col" />

      <div class="w-100"></div>

      <v-input type="v-select" :options="tiposProcessoRessarcimento" v-model="item.ressarcimento.tipoProcesso"
               v-if="tiposProcessoRessarcimento.length" :disabled="!edicao"
               :error="validation.tipoProcesso"
               label="Tipo de Isenção" class="col" />

      <v-input type="text" class="col-4" v-if="item.ressarcimento.tipoProcesso != 'NAO_RESSARCIVEL'"
               :error="validation.numeroProcesso"
               label="Número do processo/ofício" v-model="item.ressarcimento.numeroProcesso"
               :disabled="!edicao" />

      <div class="w-100"></div>

      <template v-if="ressarcimentoEntidade">

        <small-loading :loading="true" v-if="loadingEntidades" class="py-2"></small-loading>

        <v-input v-else type="v-select" :options="entidades" v-model="item.ressarcimento.enteCodigo"
                 label="Entidade" class="pb-2 col" />

      </template>

    </template>

    <div class="w-100" />
    <v-input :disabled="!edicao" v-if="acaoLote" v-model="item.alterarSolicitante" type="radio"
             label="Deseja alterar o solicitante do(s) protocolo(s)?" class="pb-2 col-auto" />
    <div class="col-12" v-if="retificarSelo && (acaoLote ? item.alterarSolicitante : true)">
      <v-input class="w-100" label="Interessado" :error="validation.interessado" v-if="!item.dto.protocolo.interessado">
        <template #label>
          <i class="fal fa-question-circle ml-2 small" :title="ajuda"></i>
        </template>

        <buscar-pessoa @add="selecionar($event, 'interessado')" :novo="true" />
      </v-input>
      <div class="row gutters-xs" v-else>

        <v-input class="col form-group" label="Interessado">
          <template #label>
            <i class="fal fa-question-circle ml-2 small" :title="ajuda"></i>
          </template>
          <input type="text" readonly :value="item.dto.protocolo.interessado.nome + (item.dto.protocolo.interessado.versao ? ' (' + 'Versão: ' + item.dto.protocolo.interessado.versao + ')' : '')" class="form-control form-control-xs" />
        </v-input>

        <div class="col-auto">
          <button class="btn btn-outline-secondary btn-xs" style="margin-top: 26px" @click="selecionar(null, 'interessado')" type="button" title="Remover">
            <i class="fal fa-times"></i>
          </button>
        </div>

        <div class="col-auto">
          <button class="btn btn-outline-secondary btn-xs" style="margin-top: 26px" @click="envolvido(item.dto.protocolo.interessado, 'interessado')" type="button">
            <i class="fal fa-edit"></i>
          </button>
        </div>
        <div class="w-100"></div>

      </div>
    </div>

  </div>
</template>

<script>
import ListaBusiness from "@/business/crud/ListaBusiness.js";
import FrontBusiness from "@/business/FrontBusiness.js";
import ProtocoloBusiness from "@/business/protocolo/ProtocoloBusiness.js";
import RegraCustasBusiness from "@/business/RegraCustasBusiness.js";
import SeloBusiness from "@/business/SeloBusiness.js";
import FormIndicadorPessoal from "@/components/IndicadorPessoal/Form/FormIndicadorPessoal.vue";
import SmallLoading from "@/components/SmallLoading.vue";
import CachedApi from "@/commons/CachedApi";

export default {
  name: "InformacoesRessarcimento",
  props:{
    item:Object,
    tipo:String,
    edicao:Boolean,
    validation:{type: Object, default: () => ({})},
    retificarSelo:{type: Boolean, default: false},
    acaoLote:{type: Boolean, default: false},
  },

  components: { SmallLoading },

  data(){
    return {
      motivosIsencao: RegraCustasBusiness.getMotivosIsencao(this.$root.config.estado, this.tipo),
      motivosIsencaoPersonalizado: [],
      tiposProcessoRessarcimento: RegraCustasBusiness.getTiposProcessoRessarcimento(this.$root.config.estado),
      entidades: null,
      loadingEntidades: false,
      ressarcimentoEntidade: false,
      ajuda: 'Pessoa que será informada no Livro 1 - Protocolo. Esta é a informação que vai para o TJ.',
      tipoIsencaoSp:[],
      tipoIsencaoRs:[
        {id: 'RESSARCIVEL', nome: 'Ressarcível'},
        {id : 'NAO_RESSARCIVEL', nome: 'Não ressarcível'}
      ],
    }
  },

  async mounted() {

    let promises = [];

    if(this.$root.isEstadoCE && this.$root.config?.geral?.motivosIsencao){
      let p1 = ListaBusiness.getById(this.$root.config?.geral?.motivosIsencao).then(l => {
          this.$set(this, 'motivosIsencaoPersonalizado', (l?.lista || []).map(e => ({id:e, nome:e})));
      });
      promises.push(p1);
    }

    this.$nextTick(() => {
      this.item.ressarcimento = this.item.ressarcimento || {};
      this.item.dto = this.item.dto || {};
      // this.item.diferido = this.item.diferido || false;
      this.item.dto.protocolo = this.item.dto?.protocolo || {};
    });

    if (this.item?.dto?.protocolo?.id) {
      let p2 = ProtocoloBusiness.getById(this.item.dto.protocolo.id).then(p => this.item.dto.protocolo = p);
      promises.push(p2);
    }
    promises.push(this.pesquisarEntidades());

    await Promise.all(promises);
    this.toggleDiferido();
    this.getTipoInsecaoSp;

    if(this.item.tipoRegistro === 'DESCONTO_ENTES_PUBLICOS'){
      this.item.isento = false;
    }

  },

  computed:{
    async getTipoInsecaoSp() {
      this.tipoIsencaoSp = await ProtocoloBusiness.getTipoInsencaoItens();
    },

    tituloIsento(){
      switch (this.tipo){
        case 'ato' : return 'Ato Isento';
        case 'certidao' : return 'Certidão Isenta';
        default: return 'Isento';
      }
    },

    motivoIsencaoRS() {
      return this.motivosIsencao.filter(motivo =>
        motivo.tipo === this.item.ressarcimento.tipoIsencao &&
        motivo.dominio.includes(this.item.tipoServico.dominio)
      );
    }


  },

  methods:{
    limparRessarcimento(){
      if(!this.item.isento){
        this.item.ressarcimento = {};
        this.item.dto.configRelatorioVerbaSintetica.motivoIsencao = null;
      }
    },

    toggleDiferido(){
      this.$nextTick(() => {
        if(this.item.diferido && this.item.isento){
          this.$set(this.item, 'isento', false);
        }
        if(this.item.diferido && this.item.diferimentoRecebido){
          this.$set(this.item, 'diferimentoRecebido', false);
        }
      });
    },

    async pesquisarEntidades(){
      this.ressarcimentoEntidade = this.$root.config.estado === 'SC' && ['T_32'].includes(this.item?.ressarcimento?.motivoIsencao) && this.item.isento;
      if(!this.entidades && this.ressarcimentoEntidade){
        this.$set(this, 'loadingEntidades', true);
        this.entidades = [];
        this.entidades = await SeloBusiness.pesquisarEntidades().catch(e => {
          FrontBusiness.showError('', e.message);
          return [];
        });
        this.$set(this, 'loadingEntidades', false);
      }
    },

    async envolvido(envolvido, papel) {
      let retorno = await FrontBusiness.openModal(FormIndicadorPessoal, {versaoId: envolvido?.id});
      if (retorno) {
        this.selecionar(retorno, papel);
      }
    },

    selecionar(envolvido, papel) {
      this.$set(this.item.dto.protocolo, papel, envolvido);
    }

  }

}
</script>
