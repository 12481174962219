<template>
  <card titulo="Protocolos Ativos">
    <template #acoes>
      <a class="icon float-right" href title="Não Ativos" @click.prevent="protocolosNaoAtivos()">
        NÃO ATIVOS
      </a>
    </template>

    <consulta :columns="columns" ref="tabela" :process="process" classe="tbl-consulta" minWidth="690px">

      <template #row="{ row }">

        <tr>
          <td class="text-truncate">
            <router-link :to="{ name: url(row.dominio), params:{id : row.id} }" class="mb-1 pointer" target="_blank" @click.native="$event.stopImmediatePropagation()">
              {{nomear(row)}}
            </router-link>
          </td>
          <td>{{row.natureza}}</td>
          <td>{{row.tipoServico}}</td>
          <td>{{row.etapa}}</td>
          <td>{{row.cadastro | data}}</td>

        </tr>

      </template>

    </consulta>

    <template v-if="outros.length">

      <br />

      <h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1">Outros Protocolos</h3>

      <consulta :columns="columns" ref="tabelaOutros" :process="processOutros" classe="tbl-consulta" minWidth="690px">

        <template #row="{ row }">

          <tr>
            <td class="text-truncate">
              <router-link :to="{ name: 'protocolo', params:{id : row.id} }" class="mb-1 pointer" target="_blank" @click.native="$event.stopImmediatePropagation()">
                {{nomear(row)}}
              </router-link>
            </td>
            <td>{{row.natureza}}</td>
            <td>{{row.tipoServico}}</td>
            <td>{{row.etapa}}</td>
            <td>{{row.cadastro | data}}</td>

          </tr>

        </template>

      </consulta>

    </template>

  </card>
</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness.js";
  import Consulta from "@/components/Consultas/Consulta";
  import Card from "@/components/Layout/components/Card.vue";
  import ConsultarProtocoloNaoAtivos from "@/components/Protocolo/ConsultarProtocoloNaoAtivos.vue";
  import ProtocoloBusiness from "@/business/protocolo/ProtocoloBusiness";
  import IndicadorBusiness from "@/business/indicadores/IndicadorBusiness";
  import DominioBusiness from "@/business/protocolo/DominioBusiness";

  export default {

    name: "Protocolos",
    components: {Consulta, Card},
    props: {
      ficha : Object,
      tipo: {default: () => 'Indicador'},
    },

    data() {
      return {
        filtros : {
          id: this.ficha.id,
          tipo: this.tipo,
        },
        outros : []
      }
    },

    computed: {
      atribuicao() {
        return DominioBusiness.getDominio(IndicadorBusiness.getDominioByLivro(this.ficha.livro))?.atribuicao;
      },

      columns() {
        return [
          {label: 'Código', field: 'p.codigo'},
          {label: 'Natureza', field: 'p.natureza'},
          {label: 'Serviço', field: 'p.tiposervico'},
          {label: 'Etapa', field: 'p.etapa'},
          {label: 'Cadastro', field: 'p.cadastro'},
        ];
      },
    },

    async mounted(){
      await this.$refs?.tabela?.load();
      await this.$refs?.tabelaOutros?.load();
    },

    methods:{

      nomear(protocolo){
        if(protocolo.dominio === 'OFICIO') {
          return 'Ofício ' + protocolo.codigo;
        }
        return FrontBusiness.nomearProtocolo(protocolo.dominio, protocolo.tipoProtocolo, protocolo.codigo);
      },

      url(dominio = '') {
        switch (dominio) {
          case 'OFICIO':
          case 'NOTIFICACAO':
            return dominio.toLowerCase();
          default:
            return 'protocolo'
        }
      },

      protocolosNaoAtivos() {
        FrontBusiness.openConsulta(ConsultarProtocoloNaoAtivos,{ indicadorId: this.ficha.id, tipo: this.tipo, atribuicao: this.atribuicao});
      },

      async process(sort_by, sort_dir, page_length, page_number){
        let sort = sort_by || "p.dominio, ordenacao_tipo, p.cadastro";
        let result = await ProtocoloBusiness.buscarProtocolosAtivos(sort, sort_dir, page_number, page_length, {filtros: this.filtros});
        return {rows: result.pagina, total: result.quantidade};
      },

      async processOutros(sort_by, sort_dir, page_length, page_number){
        let result = await ProtocoloBusiness.buscarProtocolosAtivos(sort_by, sort_dir, page_number, page_length, {filtros: {
            ...this.filtros, ...{outros: true}
          }});
        this.outros = result.pagina;
        return {rows: result.pagina, total: result.quantidade};
      },

    }
  }
</script>

<style lang=scss>
  .tbl-consulta {
    .modal-body {
      padding: 0;
    }
  }
</style>
