<template>
  <div class="mt-3">

    <card :loading-full="loading">
      <card-content v-if="!loading && indicadorRealNovo">
        <card-table class="table-borderless table-striped mb-2">
          <thead>
            <tr>
              <th style="width:200px"></th>
              <th style="width:400px">Atual</th>
              <th style="width:400px">Extraído</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Tipo Imóvel</th>
              <td>{{indicadorRealAto.tipoImovel}}</td>
              <td>{{indicadorRealNovo.tipoImovel}}</td>
            </tr>
            <tr>
              <th>Número da Unidade</th>
              <td>{{indicadorRealAto.unidade}}</td>
              <td>{{indicadorRealNovo.numeroUnidade}}</td>
            </tr>
            <tr>
              <th>Tipo de Logradouro</th>
              <td>{{indicadorRealAto.tipoLogradouro}}</td>
              <td>{{indicadorRealNovo.tipoLogradouro}}</td>
            </tr>
            <tr>
              <th>Logradouro</th>
              <td>{{indicadorRealAto.logradouro}}</td>
              <td>{{indicadorRealNovo.nomeRua}}</td>
            </tr>
            <tr>
              <th>Número</th>
              <td>{{indicadorRealAto.numero}}</td>
              <td>{{indicadorRealNovo.numeroImovel}}</td>
            </tr>
            <tr>
              <th>Complemento</th>
              <td>{{indicadorRealAto.complemento}}</td>
              <td>{{indicadorRealNovo.complemento}}</td>
            </tr>
            <tr>
              <th>Bairro</th>
              <td>{{indicadorRealAto.bairro}}</td>
              <td>{{indicadorRealNovo.bairro}}</td>
            </tr>
            <tr>
              <th>Cidade</th>
              <td>{{indicadorRealAto.cidade}}</td>
              <td>{{indicadorRealNovo.cidade}}</td>
            </tr>
            <tr>
              <th>Estado</th>
              <td>{{indicadorRealAto.estado}}</td>
              <td>{{indicadorRealNovo.estado}}</td>
            </tr>
            <tr>
              <th>Empreendimento</th>
              <td>{{indicadorRealAto.empreendimento}}</td>
              <td>{{indicadorRealNovo.condominio}}</td>
            </tr>
            <tr>
              <th>Bloco</th>
              <td>{{indicadorRealAto.bloco}}</td>
              <td>{{indicadorRealNovo.bloco}}</td>
            </tr>
            <tr>
              <th>Localização</th>
              <td>{{indicadorRealAto.localizacao}}</td>
              <td>{{indicadorRealNovo.loteamento}}</td>
            </tr>
            <tr>
              <th>lote</th>
              <td>{{indicadorRealAto.lote}}</td>
              <td>{{indicadorRealNovo.lote}}</td>
            </tr>
            <tr>
              <th>Quadra</th>
              <td>{{indicadorRealAto.quadra}}</td>
              <td>{{indicadorRealNovo.quadra}}</td>
            </tr>
<!--            <tr>-->
<!--              <th>areaTerreno</th>-->
<!--              <td>{{indicadorRealNovo.areaTerreno}}</td>-->
<!--              <td>{{indicadorRealAto.areaTerreno}}</td>-->
<!--            </tr>-->
            <tr>
              <th>Área Privativa</th>
              <td>{{indicadorRealAto.areaPrivativa}}</td>
              <td>{{indicadorRealNovo.areaPrivativa}}</td>
            </tr>
            <tr>
              <th>Área Comum</th>
              <td>{{indicadorRealAto.areaComum}}</td>
              <td>{{indicadorRealNovo.areaComum}}</td>
            </tr>
<!--            <tr>-->
<!--              <th>areaConstruida</th>-->
<!--              <td>{{indicadorRealNovo.areaConstruida}}</td>-->
<!--              <td>{{indicadorRealAto.areaConstruida}}</td>-->
<!--            </tr>-->
            <tr>
              <th>Área Total</th>
              <td>{{indicadorRealAto.area}}</td>
              <td>{{indicadorRealNovo.areaTotal}}</td>
            </tr>
            <tr>
              <th>Fração Ideal</th>
              <td>{{indicadorRealAto.fracaoSolo}}</td>
              <td>{{indicadorRealNovo.fracaoIdeal}}</td>
            </tr>
            <tr>
              <th>Confrontações</th>
              <td>{{indicadorRealAto.confrontacoes}}</td>
              <td>{{indicadorRealNovo.confrontantes}}</td>
            </tr>
          </tbody>
        </card-table>
      </card-content>
    </card>

  </div>
</template>

<script>
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import TextExtractorBusiness from "@/views/Odin/componentes/TextExtractorBusiness";

export default {
  name: "OdinIndicadorRealProcessamento",
  components: {CardTable, CardContent, Card},
  props: {
    indicadorRealId: String,
    dataBus: {
      type: Object,
      request: true
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  computed:{
    indicadorRealNovo(){
      return this.dataBus?.indicadorreal || {};
    },
    indicadorRealAto(){
      return this.dataBus?.ficha || this.dataBus?.ato?.indicadorReal || {};
    }
  },
  async mounted() {
    this.$set(this, 'loading', true);
    await TextExtractorBusiness.getLastResult('indicador-real', this.indicadorRealId)
      .then(r => this.$set(this.dataBus, 'indicadorreal', r));
    this.$set(this, 'loading', false);
  },
  methods:{
    async run(){
      this.$set(this, 'loading', true);

      this.$set(this, 'loading', false);
    }
  }
};
</script>
