<template>
  <div class="mt-3">

    <card :loading-full="loading">
      <card-content v-if="!loading">
        <card-table class="table-borderless table-striped mb-2" :dragscroll="true"
                    v-if="pessoas.length > 0">
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Nome</th>
              <th>CPF/CNPJ</th>
              <th>Papel</th>
              <th>Percentual</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="pessoa in pessoas">
              <td>{{ pessoa.tipo }}</td>
              <td>{{ pessoa.tipo === 'PF' ? pessoa.nome : pessoa.razaoSocial }}</td>
              <td>{{ pessoa.tipo === 'PF' ? pessoa.cpf : pessoa.cnpj }}</td>
              <td>{{ pessoa.papel }}</td>
              <td>{{ pessoa.percentual }}</td>
            </tr>
          </tbody>
        </card-table>
      </card-content>
    </card>

  </div>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import TextExtractorBusiness from "@/views/Odin/componentes/TextExtractorBusiness";

export default {
  name: "OdinEnvolvidosAtoProcessamentoAtoProcessamento",
  components: {CardTable, CardContent, Card},
  props: {
    indicadorRealId: String,
    dataBus: {
      type: Object,
      request: true
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  computed:{
    pessoas(){
      return this.dataBus?.envolvidosato || [];
    }
  },
  async mounted() {
    this.$set(this, 'loading', true);

    await TextExtractorBusiness.getLastResult('envolvidos-matricula', this.indicadorRealId)
      .then(r => this.$set(this.dataBus, 'envolvidosmatricula', r));

    this.$set(this, 'loading', false);
  },
  methods:{
    async run(){
      this.$set(this, 'loading', true);

      this.$set(this, 'loading', false);
    }
  }
};
</script>
