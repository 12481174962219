import AnexoBusiness from "@/business/AnexoBusiness.js";
import RestricaoBusiness from "@/business/RestricaoBusiness";
import Http from "@/commons/Http";
import Utils from "@/commons/Utils";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";

const path = 'api/registro-auxiliar';

const tratarAto = (a) => {
  if(a.tipoServico) a.tipoServico = JSON.parse(a.tipoServico);
  if(a.tipoServico?.opcoes && typeof a.tipoServico?.opcoes === 'string'){
    a.tipoServico.opcoes = JSON.parse(a.tipoServico.opcoes || false)
  }
  if(a.validador) a.validador = JSON.parse(a.validador);
  if(a.protocolo) a.protocolo = JSON.parse(a.protocolo);
  return a;
};

export default {

  async gerarCNM(id) {
    return Http.post(`${path}/${id}/gerar-cnm`);
  },

  async pagination(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/pagination?sort=${sort || 'r.codigo'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async paginationV2(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/v2/pagination?sort=${sort || 'r.codigo'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async getById(id){
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/${id}`);
  },

  async delete(id){
    return Http.delete(`${path}/${id}`);
  },

  async getEnvolvidos(id){
    return Http.get(`${path}/${id}/envolvidos`);
  },

  async buscarAtosRegistrados(id){
    return Http.get(`${path}/${id}/atos-registrados`).then(l => l.map(tratarAto));
  },

  async verificarAtosImpressao(id){
    return Http.get(`${path}/${id}/atos-impressao`);
  },

  async ultimoAtoRegistrado(id){
    return Http.get(`${path}/${id}/ultimo-ato-registrado`).then(a => tratarAto(a));
  },

  async cancelarUltimoAto(registroAuxiliarId) {
    return await Http.post(`${path}/${registroAuxiliarId}/ato/cancelar-ultimo-ato`);
  },

  async setImpressao(id, imprimindo) {
    return Http.post(`${path}/${id}/ato-impressao/${imprimindo}`);
  },

  async buscarProtocolosAtivos(id){
    return Http.get(`${path}/${id}/protocolos-ativos`);
  },

  async getFichasPorCodigo(params = {}) {
    const {status, dto} = params;
    return await Http.post(`api/registro-auxiliar/codigos/${status}`, dto);
  },

  async atosRegistrados(id){
    return Http.get(`${path}/${id}/atos`).then(l => l.map(tratarAto));
  },

  async buscarUltimaAuditoriaImpressaoAto(id){
    return Http.get(`${path}/${id}/ultima-auditoria-impressao`);
  },

  async getFichasPorId(ids) {
    return await Http.post(`${path}/ids`, ids);
  },

  async restricoesVigentes(id){
    return Http.get(`${path}/${id}/restricoes-vigentes`);
  },

  async restricoes(id, registroAto){
    if(Utils.isEmptyNullOrUndefined(registroAto)) registroAto = '';
    return Http.get(`${path}/${id}/restricoes/${registroAto}`).then(r => r.map(RestricaoBusiness.tratarRestricoes));
  },

  async digitalizacaoDesatualizada(id){
    return Http.get(`${path}/${id}/digitalizacao-desatualizada`);
  },

  async impressaoDesatualizada(id){
    return Http.get(`${path}/${id}/impressao-desatualizada`);
  },

  async registroAuxiliarValidado(id){
    return Http.get(`${path}/valido/${id}`);
  },

  async historico(id, page, size) {
    return Http.get(`${path}/${id}/historico?page=${page || 1}&size=${size || 10}`);
  },

  async save(id, dto) {
    if(!id){
      return Http.post(`${path}/lote/1`, dto);
    }
    return Http.post(`${path}/edit/${id}`, dto);
  },

  async editarTextoLegado(id, dto) {
    return Http.post(`${path}/${id}/texto-legado`, dto);
  },

  async getAtos(registroAuxiliar, atoId = '', registrado = false, selado = false){
    const tratarObjeto = (item) => {
      item.ato = JSON.parse(item.ato);
      return item;
    };
    return Http.get(`${path}/atos-indicador/${registroAuxiliar}/${atoId}/${registrado}/${selado}`).then(response => response.map(tratarObjeto));
  },

  getPapeisPacto() {
    let papeisPacto= this.getPapeis();
    papeisPacto.push({id:'CONTRATANTE', nome: 'Contratante'});
    return papeisPacto;
  },

  getPapeis() {
    return [
      {id: 'ANUENTE', nome: 'Anuente'},
      {id: 'CREDOR', nome: 'Credor'},
      {id: 'DEVEDOR', nome: 'Devedor'},
      {id: 'GARANTIDOR', nome: 'Garantidor'},
      {id: 'TESTEMUNHA', nome: 'Testemunha'},
      {id: 'FIEL_DEPOSITARIO', nome: 'Fiel Depositário'},
    ]
  },

  getTiposCedulas() {
    return [
      {id: 'PIGNORATICIA', nome: 'Pignoratícia'},
      {id: 'HIPOTECARIA', nome: 'Hipotecária'},
      {id: 'PIGNORATICIA_HIPOTECARIA', nome: 'Pignoratícia Hipotecária'},
      {id: 'CREDITO_INDUSTRIAL', nome: 'Crédito Indústrial'},
      {id: 'EXPORTACAO', nome: 'Exportação'},
      {id: 'COMERCIAL', nome: 'Comercial'},
      {id: 'PRODUTO_RURAL', nome: 'Produto Rural'},
      {id: 'CREDITO_IMOBILIARIO', nome: 'Crédito Imobiliário'},
      {id: 'CREDITO_BANCARIO', nome: 'Crédito Bancário'},
      {id: 'PENHOR', nome: 'Penhor'},
      {id: 'PRODUTO_RURAL_COM_LIQUIDACAO_FINANCEIRA', nome: 'Produto Rural com Liquidação Financeira'},
    ];
  },

  getCamposBemMovel() {
    return [
      {id: 'TIPO', nome: 'Tipo'},
      {id: 'NOME', nome: 'Nome'},
      {id: 'DATA_REF', nome: 'Data de Referência'},
      {id: 'VALOR', nome: 'Valor'},
      {id: 'FABRICANTE', nome: 'Fabricante'},
      {id: 'ID', nome: 'ID'},
      {id: 'LOCALIZACAO', nome: 'Localização'},
      {id: 'DETALHES', nome: 'Detalhes'},
      {id: 'NOTA_FISCAL', nome: 'Nota Fiscal'}
    ];
  },

  getTipos() {
    return [
      {id: 'CEDULA', nome: 'Cédula'},
      {id: 'CONVENCAO_CONDOMINIO', nome: 'Convenção de Condomínio'},
      {id: 'PACTO', nome: 'Pacto Antenupcial'},
      {id: 'OUTROS', nome: 'Outros Títulos'},
    ];
  },

  getStatusImovel(completa = true) {
    if (completa) {
      return [
        {id: 'PENDENTE', nome: 'Pendente'},
        {id: 'ABERTA', nome: 'Aberta'},
        {id: 'ENCERRADA', nome: 'Encerrada'},
        {id: 'CANCELADA', nome: 'Cancelada'},
        {id: 'VIRTUAL', nome: 'Virtual'}
      ]
    } else {
      return [
        {id: 'ABERTA', nome: 'Aberta'},
        {id: 'ENCERRADA', nome: 'Encerrada'},
        {id: 'CANCELADA', nome: 'Cancelada'},
      ]
    }
  },

  camposConsulta() {
    return Utils.orderBy([

      {id: 'codigo', nome: 'Registro', type: 'number'},

      {id: 'status', status: ['ABERTA','CANCELADA','ENCERRADA'], nome: 'Status/Situação', type: 'list', options: this.getStatusImovel(false)},
      {id: 'tipo_registro', nome: 'Tipo de Registro Auxiliar', type: 'list', options: this.getTipos()},
      {id: 'tipo_cedula', nome: 'Tipo de cédulas', type: 'list', options: this.getTiposCedulas()},
      {id: 'descricao_tipo_registro_outros', nome: 'Descrição', type: 'text'},
      {id: 'livro_letra', nome: 'Livro (Letra)', type: 'text'},
      {id: 'abertura', status: ['ABERTA','CANCELADA','ENCERRADA'], nome: 'Data de Abertura', type: 'date'},
      {id: 'registro', status: ['ABERTA','CANCELADA','ENCERRADA'], nome: 'Data de Registro', type: 'date'},
      {id: 'encerramento', status: ['ABERTA','CANCELADA','ENCERRADA'], nome: 'Data de Encerramento', type: 'date'},
      {id: 'data_alteracao_manual', status: ['ABERTA','CANCELADA','ENCERRADA'], nome: 'Data da Alteração Manual', type: 'date'},

      {id: 'envolvido_cpf', status: ['ABERTA','CANCELADA','ENCERRADA'],  nome: 'CPF Envolvido', type: 'cpf'},
      {id: 'envolvido_cnpj', status: ['ABERTA','CANCELADA','ENCERRADA'], nome: 'CNPJ Envolvido', type: 'cnpj'},
      {id: 'envolvido_nome', status: ['ABERTA','CANCELADA','ENCERRADA'], nome: 'Nome Envolvido', type: 'text'},
      {id: 'envolvido_papel', status: ['ABERTA','CANCELADA','ENCERRADA'], nome: 'Papel Envolvido', type: 'list', options: IndicadorRealBusiness.getPapeisEnvolvidos()},

      // {id: 'codigo', nome: 'Código', type: 'text'},

      {id: 'condominio', nome: 'Nome do condomínio', type: 'text'},

      {id: 'ato_minuta', status: ['ABERTA','CANCELADA','ENCERRADA'], nome: 'Minuta dos Atos Registrados', type: 'tsquery'},
      {id: 'ato_titulo', status: ['ABERTA','CANCELADA','ENCERRADA'], nome: 'Título dos Atos Registrados', type: 'tsquery'},
      {id: 'pacto', nome: 'Data do Pacto', type: 'date'},
      {id: 'vencimento_cedula', nome: 'Vencimento', type: 'date'},
      {id: 'valor_cedula', nome: 'Valor', type: 'currency'},


      {id: 'garantia', nome: 'Garantia/Teor', type: 'tsquery'},
      {id: 'observacoes', nome: 'Observações', type: 'tsquery'},

      {id: 'digitalizacao', status: ['ABERTA','CANCELADA','ENCERRADA'], nome: 'Digitalização', type: 'list', options: AnexoBusiness.tiposDigitalizacao().slice(1)},

      {id: 'indicador_real_id',  nome: 'Matrículas Relacionadas', type: 'matricula'},
      {id: 'ultimo_ato', nome: 'Último Ato', type: 'numeric'},
      {id: 'bem_movel', nome: 'Bem Móvel', type: 'list', options: this.getCamposBemMovel()},
      {id: 'status_protocolo',status: ['ABERTA','CANCELADA','ENCERRADA'],nome:'Status Protocolo',type: 'list',options: this.getStatusProtocolo()}

    ], 'nome');
  },

  getStatusProtocolo() {
      return [
        {id:'TODOS',nome:'Todos'},
        {id: 'ATIVO', nome: 'Ativo'},
        {id: 'INATIVO', nome: 'Não Ativo'},
      ]
  },

  getStatusRestricao(){
    return [
      {id:'TODOS',nome:'Todos'},
      {id:'true',nome:'Restrição Ativa'},
      {id:'false',nome:'Restrição Não Ativa'}
    ]
  }



}
