<template>

  <small-loading v-if="loading"></small-loading>
  <card v-else id="auditoria-container" :title="title" :titulo="selecionar ? ' ' : ''">
    <div class="div-select-all" v-if="Object.keys(this.rows).length && selecionar">
      <a @click.prevent="selecionarTodos()" title="Selecionar Todos" class="icon">
        <i class="ml-2 fal fa-check-circle"></i>
      </a>
      <a @click.prevent="selecionarTodos(true)" title="Limpar Seleção" class="icon">
        <i class="ml-2 fal fa-circle"></i>
      </a>
    </div>

      <p class="alert alert-secondary my-0" role="alert" v-if="!show">
        Nenhuma informação para exibir
      </p>

      <card-table v-if="show">
        <thead>
          <tr>
            <td v-if="selecionar" class="text-center" />
            <th :width="`${showAfter ? '200px' : '150px'}`"></th>
            <th :width="`${showAfter ? '40%' : '100%'}`">{{showAfter ? titleAntes : ''}}</th>
            <th width="40%" v-if="showAfter">{{ titleDepois }}</th>
          </tr>
        </thead>
        <tbody>

          <template v-for="(linhas, name) in rows">

            <tr class="cabecalho-auditoria" v-if="name != 'Geral'" :key="name">
              <th colspan="100%" v-html="name"></th>
            </tr>

            <tr :key="`${row.id}-${i}`" v-for="(row, i) in linhas" :class="{'mais-destaque' : row.modified}">
              <td class="text-center" v-if="selecionar">
                <input style="position: relative; top: 2px; left: 0px;" type="checkbox" :id="row.id"
                       @click="selecionarId(row.id)"  :value="selecionados.includes(row.id)" :checked="selecionados.includes(row.id)"/>
              </td>
              <td style="vertical-align: baseline" :class="{'cabecalho-auditoria': row.completo}" :colspan="row.completo ? '100%' : 1"><b>{{row.label}}</b></td>

              <!-- ANTES -->
              <template v-if="!row.completo">
                <td v-if="row.type === 'image-from-anexo'" :class="{'text-primary' : row.modified}" class="td-conteudo">
                  <image-from-anexo :thumb="true" v-if="row.antes" :anexo-or-id-anexo="row.antes" style="width: 100%" />
                </td>
                <td v-else-if="row.type === 'ide-minuta'" :class="{'text-primary' : row.modified}" class="td-conteudo">
                  <ide-minuta v-if="row.antes" v-model="row.antes" :readOnly="true" :toolbar="false" />
                </td>
                <td v-else-if="row.type === 'detalhes-indisponibilidade'" class="td-conteudo">
                  <v-button v-if="row.antes" :run="detalhes" :params="[row.antes]" :button="false" :class="{'text-primary' : row.modified}" class="icon" title="Exibir Detalhes">
                    <i class="fas fa-id-badge"></i>
                  </v-button>
                </td>
                <td v-else-if="row.type === 'grupo-permissao'" class="td-conteudo">
                  <v-button v-if="row.antes" :run="detalhesPermissao" :params="[row.antes]" :button="false" :class="{'text-primary' : row.modified}" class="icon" title="Ver Permissões">
                    Ver Permissões
                  </v-button>
                </td>
                <td v-else-if="row.type === 'configuracao-impressao'" :class="{'text-primary' : row.modified}">
                  <template v-if="!isLayoutNovo">
                    <template v-if="row.antes">
                      <b>Tamanho (Altura x Largura):</b> {{row.antes.size || '-'}} <br>
                      <b>Margem - Frente:</b> {{row.antes.margemFrente || '-'}} <br>
                      <b>Margem - Verso:</b> {{row.antes.margemVerso || '-'}} <br>
                      <b>Cabeçalho - Frente:</b>
                      <ide-minuta class="mb-2" v-model="row.antes.cabecalhoFrente" :readOnly="true" :toolbar="false" />
                      <b>Cabeçalho - Verso:</b>
                      <ide-minuta class="mb-2" v-model="row.antes.cabecalhoVerso" :readOnly="true" :toolbar="false" />
                      <b>Continuação:</b>
                      <ide-minuta class="mb-2" v-model="row.antes.continuacao" :readOnly="true" :toolbar="false" />
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="row.antes">
                      <b>Geral - Largura:</b> {{row.antes.largura || '-'}} <br>
                      <b>Geral - Altura:</b> {{row.antes.altura || '-'}} <br>
                      <b>Geral - Recuo Esquerda:</b> {{row.antes.leftFix || '-'}} <br>
                      <b>Geral - Tamanho da Letra:</b> {{row.antes.tamanhoFonte || '-'}} <br>
                      <b>Geral - Fonte da Letra:</b> {{row.antes.fonte || '-'}} <br><br>

                      <b>Frente - Borda:</b> {{row.antes.frente.border == true ? 'Sim' : row.antes.frente.border == false ?'Não' : row.antes.frente.border || '-'}} <br>
                      <b>Frente - Correção Topo:</b> {{row.antes.frente.topFix || '-'}} <br>
                      <b>Frente - Margem Topo:</b> {{row.antes.frente.top || '-'}} <br>
                      <b>Frente - Margem Direita:</b> {{row.antes.frente.right || '-'}} <br>
                      <b>Frente - Margem Rodapê:</b> {{row.antes.frente.bottom || '-'}} <br>
                      <b>Frente - Margem Esquerda:</b> {{row.antes.frente.left || '-'}} <br>
                      <b>Frente - Padding Topo:</b> {{row.antes.frente.paddingTop || '-'}} <br>
                      <b>Frente - Padding Direita:</b> {{row.antes.frente.paddingRight || '-'}} <br>
                      <b>Frente - Padding Rodapê:</b> {{row.antes.frente.paddingLeft || '-'}} <br>
                      <b>Frente - Padding Esquerda:</b> {{row.antes.frente.paddingBottom || '-'}} <br><br>

                      <b>Verso - Borda:</b> {{row.antes.verso.border == true ? 'Sim' : row.antes.verso.border == false ?'Não' : row.antes.verso.border || '-'}} <br>
                      <b>Verso - Correção Topo:</b> {{row.antes.verso.topFix || '-'}} <br>
                      <b>Verso - Margem Topo:</b> {{row.antes.verso.top || '-'}} <br>
                      <b>Verso - Margem Direita:</b> {{row.antes.verso.right || '-'}} <br>
                      <b>Verso - Margem Rodapê:</b> {{row.antes.verso.bottom || '-'}} <br>
                      <b>Verso - Margem Esquerda:</b> {{row.antes.verso.left || '-'}} <br>
                      <b>Verso - Padding Topo:</b> {{row.antes.verso.paddingTop || '-'}} <br>
                      <b>Verso - Padding Direita:</b> {{row.antes.verso.paddingRight || '-'}} <br>
                      <b>Verso - Padding Rodapê:</b> {{row.antes.verso.paddingLeft || '-'}} <br>
                      <b>Verso - Padding Esquerda:</b> {{row.antes.verso.paddingBottom || '-'}} <br><br>

                      <template v-for="(elemento, index) in row.antes.elementos">
                        <b>El - {{ index + 1}} - Margem Topo:</b> {{elemento.top || '-'}} <br>
                        <b>El - {{ index + 1}} - Margem Esquerda:</b> {{elemento.left || '-'}} <br>
                        <b>El - {{ index + 1}} - Frente:</b> {{elemento.frente == true ? 'Sim' : elemento.frente  == false ?'Não' : elemento.frente  || '-'}} <br>
                        <b>El - {{ index + 1}} - Verso:</b> {{elemento.verso  == true ? 'Sim' : elemento.verso == false ?'Não' : elemento.verso || '-'}} <br>
                        <b>El - {{ index + 1}} - Sublinhar:</b> {{elemento.sublinhar == true ? 'Sim' : elemento.sublinhar == false ?'Não' : elemento.sublinhar || '-'}} <br>
                        <b>El - {{ index + 1}} - Apres. Primeira Folha:</b> {{elemento.apresentarPrimeiraFolha == true ? 'Sim' : elemento.apresentarPrimeiraFolha == false ?'Não' : elemento.apresentarPrimeiraFolha || '-'}} <br>
                        <b>El - {{ index + 1}} - Apres. Apenas Primeira Folha:</b> {{elemento.apresentarApenasPrimeiraFolha == true ? 'Sim' : elemento.apresentarApenasPrimeiraFolha  == false ?'Não' : elemento.apresentarApenasPrimeiraFolha  || '-'}} <br>
                        <b>El - {{ index + 1}} - Tipo:</b> {{elemento.type || '-'}} <br>
                        <b>El - {{ index + 1}} - Modo:</b> {{elemento.mode || '-'}} <br>
                        <b>El - {{ index + 1}} - Largura:</b> {{elemento.width || '-'}} <br>
                        <b>El - {{ index + 1}} - Altura:</b> {{elemento.height || '-'}} <br>
                        <b>El - {{ index + 1}} - Tamanho Letra:</b> {{elemento.fontSize || '-'}} <br>
                        <b>El - {{ index + 1}} - Negrito:</b> {{elemento.bold == true ? 'Sim' : elemento.bold  == false ?'Não' : elemento.bold   || '-'}} <br>
                        <b>El - {{ index + 1}} - Itálico:</b> {{elemento.italic == true ? 'Sim' : elemento.italic  == false ?'Não' : elemento.italic || '-'}} <br>
                        <b>El - {{ index + 1}} - Conteudo:</b> {{elemento.content || '-'}} <br>
                        <b>El - {{ index + 1}} - Rotação:</b> {{elemento.rotacao || '-'}} <br><br>
                      </template>

                    </template>
                  </template>
                </td>
                <td v-else :class="{'text-primary' : row.modified}" class="td-conteudo" data-testid="conteudo-antes" v-html="row.antes"></td>
              </template>

              <!-- depois -->
              <template v-if="!row.completo && showAfter">
                <td v-if="row.type === 'image-from-anexo'" :class="{'text-primary' : row.modified}" class="td-conteudo">
                  <image-from-anexo :thumb="true" v-if="row.depois" :anexo-or-id-anexo="row.depois" style="width: 100%" />
                </td>
                <td v-else-if="row.type === 'ide-minuta'" :class="{'text-primary' : row.modified}" class="td-conteudo">
                  <ide-minuta v-if="row.depois" v-model="row.depois" :readOnly="true" :toolbar="false" />
                </td>
                <td v-else-if="row.type === 'detalhes-indisponibilidade'" class="td-conteudo">
                  <v-button v-if="row.depois" :run="detalhes" :params="[row.depois]" :button="false" :class="{'text-primary' : row.modified}" class="icon" title="Ver Detalhes">
                    <i class="fas fa-id-badge"></i>
                  </v-button>
                </td>
                <td v-else-if="row.type === 'grupo-permissao'" class="td-conteudo">
                  <v-button v-if="row.depois" :run="detalhesPermissao" :params="[row.depois]" :button="false" :class="{'text-primary' : row.modified}" class="icon" title="Exibir Permissões">
                    Ver Permissões
                  </v-button>
                </td>
                <td v-else-if="row.type === 'configuracao-impressao'" :class="{'text-primary' : row.modified}">
                  <template v-if="!isLayoutNovo">
                    <template v-if="row.depois" >
                      <b>Tamanho (Altura x Largura):</b> {{row.depois.size || '-'}} <br>
                      <b>Margem - Frente:</b> {{row.depois.margemFrente || '-'}} <br>
                      <b>Margem - Verso:</b> {{row.depois.margemVerso || '-'}} <br>
                      <b>Cabeçalho - Frente:</b>
                      <ide-minuta class="mb-2" v-model="row.depois.cabecalhoFrente" :readOnly="true" :toolbar="false" />
                      <b>Cabeçalho - Verso:</b>
                      <ide-minuta class="mb-2" v-model="row.depois.cabecalhoVerso" :readOnly="true" :toolbar="false" />
                      <b>Continuação:</b>
                      <ide-minuta class="mb-2" v-model="row.depois.continuacao" :readOnly="true" :toolbar="false" />
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="row.depois">
                      <b>Geral - Largura:</b> {{row.depois.largura || '-'}} <br>
                      <b>Geral - Altura:</b> {{row.depois.altura || '-'}} <br>
                      <b>Geral - Recuo Esquerda:</b> {{row.depois.leftFix || '-'}} <br>
                      <b>Geral - Tamanho da Letra:</b> {{row.depois.tamanhoFonte || '-'}} <br>
                      <b>Geral - Fonte da Letra:</b> {{row.depois.fonte || '-'}} <br><br>

                      <b>Frente - Borda:</b> {{row.depois.frente.border == true ? 'Sim' : row.depois.frente.border == false ?'Não' : row.depois.frente.border || '-'}} <br>
                      <b>Frente - Correção Topo:</b> {{row.depois.frente.topFix || '-'}} <br>
                      <b>Frente - Margem Topo:</b> {{row.depois.frente.top || '-'}} <br>
                      <b>Frente - Margem Direita:</b> {{row.depois.frente.right || '-'}} <br>
                      <b>Frente - Margem Rodapê:</b> {{row.depois.frente.bottom || '-'}} <br>
                      <b>Frente - Margem Esquerda:</b> {{row.depois.frente.left || '-'}} <br>
                      <b>Frente - Padding Topo:</b> {{row.depois.frente.paddingTop || '-'}} <br>
                      <b>Frente - Padding Direita:</b> {{row.depois.frente.paddingRight || '-'}} <br>
                      <b>Frente - Padding Rodapê:</b> {{row.depois.frente.paddingLeft || '-'}} <br>
                      <b>Frente - Padding Esquerda:</b> {{row.depois.frente.paddingBottom || '-'}} <br><br>

                      <b>Verso - Borda:</b> {{row.depois.verso.border == true ? 'Sim' : row.depois.verso.border == false ?'Não' : row.depois.verso.border || '-'}} <br>
                      <b>Verso - Correção Topo:</b> {{row.depois.verso.topFix || '-'}} <br>
                      <b>Verso - Margem Topo:</b> {{row.depois.verso.top || '-'}} <br>
                      <b>Verso - Margem Direita:</b> {{row.depois.verso.right || '-'}} <br>
                      <b>Verso - Margem Rodapê:</b> {{row.depois.verso.bottom || '-'}} <br>
                      <b>Verso - Margem Esquerda:</b> {{row.depois.verso.left || '-'}} <br>
                      <b>Verso - Padding Topo:</b> {{row.depois.verso.paddingTop || '-'}} <br>
                      <b>Verso - Padding Direita:</b> {{row.depois.verso.paddingRight || '-'}} <br>
                      <b>Verso - Padding Rodapê:</b> {{row.depois.verso.paddingLeft || '-'}} <br>
                      <b>Verso - Padding Esquerda:</b> {{row.depois.verso.paddingBottom || '-'}} <br><br>

                      <template v-for="(elemento, index) in row.depois.elementos">
                        <b>El - {{ index + 1}} - Margem Topo:</b> {{elemento.top || '-'}} <br>
                        <b>El - {{ index + 1}} - Margem Esquerda:</b> {{elemento.left || '-'}} <br>
                        <b>El - {{ index + 1}} - Frente:</b> {{elemento.frente == true ? 'Sim' : elemento.frente  == false ?'Não' : elemento.frente  || '-'}} <br>
                        <b>El - {{ index + 1}} - Verso:</b> {{elemento.verso  == true ? 'Sim' : elemento.verso == false ?'Não' : elemento.verso || '-'}} <br>
                        <b>El - {{ index + 1}} - Sublinhar:</b> {{elemento.sublinhar == true ? 'Sim' : elemento.sublinhar == false ?'Não' : elemento.sublinhar || '-'}} <br>
                        <b>El - {{ index + 1}} - Apres. Primeira Folha:</b> {{elemento.apresentarPrimeiraFolha == true ? 'Sim' : elemento.apresentarPrimeiraFolha == false ?'Não' : elemento.apresentarPrimeiraFolha || '-'}} <br>
                        <b>El - {{ index + 1}} - Apres. Apenas Primeira Folha:</b> {{elemento.apresentarApenasPrimeiraFolha == true ? 'Sim' : elemento.apresentarApenasPrimeiraFolha  == false ?'Não' : elemento.apresentarApenasPrimeiraFolha  || '-'}} <br>
                        <b>El - {{ index + 1}} - Tipo:</b> {{elemento.type || '-'}} <br>
                        <b>El - {{ index + 1}} - Modo:</b> {{elemento.mode || '-'}} <br>
                        <b>El - {{ index + 1}} - Largura:</b> {{elemento.width || '-'}} <br>
                        <b>El - {{ index + 1}} - Altura:</b> {{elemento.height || '-'}} <br>
                        <b>El - {{ index + 1}} - Tamanho Letra:</b> {{elemento.fontSize || '-'}} <br>
                        <b>El - {{ index + 1}} - Negrito:</b> {{elemento.bold == true ? 'Sim' : elemento.bold  == false ?'Não' : elemento.bold   || '-'}} <br>
                        <b>El - {{ index + 1}} - Itálico:</b> {{elemento.italic == true ? 'Sim' : elemento.italic  == false ?'Não' : elemento.italic || '-'}} <br>
                        <b>El - {{ index + 1}} - Conteudo:</b> {{elemento.content || '-'}} <br>
                        <b>El - {{ index + 1}} - Rotação:</b> {{elemento.rotacao || '-'}} <br><br>
                      </template>

                    </template>
                  </template>
                </td>
                <td v-else :class="{'text-primary' : row.modified}" class="td-conteudo" data-testid="conteudo-depois" v-html="row.depois"></td>
              </template>

            </tr>

          </template>

        </tbody>
      </card-table>

    </card>

</template>

<script>
import FrontBusiness from "@/business/FrontBusiness";
import ProtocoloDocumentoBusiness from "@/business/protocolo/ProtocoloDocumentoBusiness.js";
import Utils from "@/commons/Utils.js";
import DetalhesIndisponibilidade from "@/components/Indisponibilidade/DetalhesIndisponibilidade.vue";
import Card from "@/components/Layout/components/Card.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";

import ImageFromAnexo from "@/components/ImageFromAnexo";
import IdeMinuta from "@/components/IdeMinuta/IdeMinuta";
import SmallLoading from "@/components/SmallLoading.vue";
import ModalGrupoPermissao from "@/components/Usuario/ModalGrupoPermissao.vue";

export default {

  name: "AuditoriaDetailsContent",
  components: {SmallLoading, ImageFromAnexo, IdeMinuta, CardTable, Card},
  props: {
    auditoria : Object,
    titleAntes: {type: String, default: 'Antes'},
    titleDepois: {type: String, default: 'Depois'},
    formatters: Array,
    showAfter: {
      type: Boolean,
      default: () => true
    },
    apenasAlteracao: {
      type: Boolean,
      default: () => false
    },
    selecionar: {
      type: Boolean,
      default: () => false
    },
    title: String
  },

  data() {
    return {
      loading: true,
      rows: [],
      selecionados: [],

    }
  },

  computed:{
    show(){
      return !this.loading && Object.keys(this.rows).length;
    },
    isLayoutNovo(){
      let novos = ['MATRICULA_V3', 'REGISTRO_AUXILIAR_V3', 'TRANSCRICAO', 'LIVRO_ESTRANGEIRO', 'CERTIDAO_V3', 'REGISTRO_TD', 'REGISTRO_PJ', 'INCRA'];
      let dados = this.auditoria.depois || this.auditoria.antes;

      if(dados?.tipo && novos.includes(dados?.tipo)){
        return true;
      }
      return false;
    }
  },

  async mounted(){
    let rows = [];
    for(let config of this.formatters){
      if(config.type == 'checklist'){
        rows.push(...(await this.createChecklistRows(config)));
      }else{
        rows.push(await this.createRow(config));
      }
    }

    console.log('-----------', {rows}, this)

    rows = Utils.groupBy(rows.filter(r => r && r.show && (this.apenasAlteracao ? r.modified : true)), 'group');
    this.rows = rows;
    this.loading = false;
  },

  methods:{

    montarItemExibicaoChecklist(grupo, requisito, aResposta, dResposta, documentos = []) {
      switch (requisito.tipo){
        case 'DATE':
          aResposta = this.simpleFormat(aResposta, 'date');
          dResposta = this.simpleFormat(dResposta, 'date');
          break;
        case 'NUMBER':
          aResposta = this.simpleFormat(aResposta, 'integer');
          dResposta = this.simpleFormat(dResposta, 'integer');
          break;
        case 'CURRENCY':
          aResposta = this.simpleFormat(aResposta, 'currency');
          dResposta = this.simpleFormat(dResposta, 'currency');
          break;
        case 'EDITOR':
          aResposta = this.simpleFormat(aResposta, 'html');
          dResposta = this.simpleFormat(dResposta, 'html');
          break;
        case 'VINCULO':
          aResposta = this.simpleFormat(aResposta, 'listaenum', {lista: documentos});
          dResposta = this.simpleFormat(dResposta, 'listaenum', {lista: documentos});
          break;
        case 'SELECT':
          if (Array.isArray(aResposta) || Array.isArray(dResposta)) {
            aResposta = this.simpleFormat(aResposta, 'array');
            dResposta = this.simpleFormat(dResposta, 'array');
          } else {
            aResposta = this.simpleFormat(aResposta, 'string');
            dResposta = this.simpleFormat(dResposta, 'string');
          }
          break;
        default:
          aResposta = this.simpleFormat(aResposta, 'string');
          dResposta = this.simpleFormat(dResposta, 'string');
          break;
      }

      const antesStr = aResposta != null && (aResposta + ' ').replaceAll('-', '').trim() != '';
      const depoisStr = dResposta != null && (dResposta + ' ').replaceAll('-', '').trim() != '';

      return {
        id : `${grupo.id}-${requisito.indice}-${Utils.uuid()}`,
        label: `${requisito.pergunta} [${requisito.indice}]`,
        group: `Checklist - ${grupo.nome}`,
        antes: aResposta, depois: dResposta,
        show: (antesStr || depoisStr),
        modified: this.showAfter && (aResposta != dResposta)
      }
    },

    async createChecklistRows(config) {

      const gerarObjeto = (grupos = []) => {

        const checklist = Utils.keyBy(grupos.map(g => {
          g.requisitos = Utils.keyBy(g.requisitos, 'indice');
          return g;
        }), 'id');

        return checklist;
      };

      let antes = Utils.get(this.auditoria.antes, config.key),
        depois = Utils.get(this.auditoria.depois, config.key);

      let mostrarChecklist = antes?.checklistProcessado?.length || depois?.checklistProcessado?.length;

      if(!mostrarChecklist){
        let keys = Utils.uniq(Object.keys(antes || {}).concat(Object.keys(depois || {}))).filter(k => !['checklistProcessado', 'respondidos', 'quantidade'].includes(k));
        return keys.map(k => {
          let a = this.simpleFormat(antes?.[k], 'string');
          let d = this.simpleFormat(depois?.[k], 'string');

          const antesStr = a != null && (a + ' ').replaceAll('-', '').trim() != '';
          const depoisStr = d != null && (d + ' ').replaceAll('-', '').trim() != '';

          return {id : k, label: k, group: 'Índices do Checklist', antes: a, depois: d, show: (antesStr || depoisStr), modified: this.showAfter && (a != d)}
        });
      }

      antes = gerarObjeto(antes?.checklistProcessado);
      depois = gerarObjeto(depois?.checklistProcessado);

      let keys = Utils.uniq(Object.keys(antes || {}).concat(Object.keys(depois || {})));
      let protocolo = (this.auditoria?.antes || this.auditoria?.depois)?.protocolo;
      let documentos = [];
      if (protocolo) {
        documentos = await ProtocoloDocumentoBusiness.listar(protocolo?.id || protocolo);
      }

      const requisitos = keys.map(gKey => {

        let reqKeys = Utils.uniq(Object.keys(antes?.[gKey]?.requisitos || {}).concat(Object.keys(depois?.[gKey]?.requisitos || {})));
        const grupo = antes?.[gKey] || depois?.[gKey];
        let itens = [];
        reqKeys.map(req => {
          const a = antes?.[gKey]?.requisitos?.[req];
          const d = depois?.[gKey]?.requisitos?.[req];
          const requisito = a || d;
          if (requisito.tipo === "GROUP") {
            let sublist = requisito?.sublist || [];
            sublist.map((subItem, i) => {
              itens.push({
                id : `${grupo.id}-${requisito.indice}-${Utils.uuid()}`,
                label: `${i + 1}) ${subItem.nome}`,
                group: `Checklist - ${grupo.nome}`,
                show: true,
                completo: true
              })
              let subKeys = Utils.uniq(Object.keys(a?.sublist?.[i]?.requisitos || {}).concat(Object.keys(d?.sublist?.[i]?.requisitos || {})));
              subKeys.map(subKey => {
                let x = a?.sublist?.[i]?.requisitos[subKey]
                let y = d?.sublist?.[i]?.requisitos[subKey]
                const subrequisito = x || y;
                let aResposta = x?.resposta;
                let dResposta = y?.resposta;
                itens.push(this.montarItemExibicaoChecklist(grupo, subrequisito, aResposta, dResposta, documentos))
              })
            })
          } else {
            let aResposta = a?.resposta;
            let dResposta = d?.resposta;
            itens.push(this.montarItemExibicaoChecklist(grupo, requisito, aResposta, dResposta, documentos));
          }
        })
        if (itens.length) {
          return itens
        }
      });

      return Utils.flatten(requisitos);
    },

    async createRow(config){

      let dados = this.auditoria.depois || this.auditoria.antes;

      let antes = Utils.get(this.auditoria.antes, config.key),
        depois = Utils.get(this.auditoria.depois, config.key);

      if(config?.formatter){
        antes = await config.formatter(antes, this.auditoria.antes);
        depois = await config.formatter(depois, this.auditoria.depois);
      }else{
        antes = this.simpleFormat(antes, config.type, config);
        depois = this.simpleFormat(depois, config.type, config);
      }

      if(config?.labelFnc){
        config.label = await config.labelFnc(dados);
      }

      const antesStr = antes != null && (antes + ' ').replaceAll('-', '').trim() != '';
      const depoisStr = depois != null && (depois + ' ').replaceAll('-', '').trim() != '';

      return {
        id : config.key,
        label: config.label,
        type: config.type,
        group: config.group || 'Geral',
        antes, depois,
        show: (antesStr || depoisStr),
        modified: this.showAfter && (JSON.stringify(antes) != JSON.stringify(depois))
      };

    },

    simpleFormat(value, type = '', config = {}){

      let convertJson = () => {
        try{
          return value != null && typeof value === 'string' ? JSON.parse(value || '') : value;
        }catch (e){
          return value;
        }
      };

      switch (type.toLowerCase()) {
        case 'date':{
          if (Array.isArray(value)) {
            value = new Date(value[0], value[1] - 1, value[2]);
          } else if (value != null && typeof (value) === 'object') {
            value = new Date(value?.year, value?.monthValue - 1, value?.dayOfMonth, value?.hour || 0, value?.minute || 0, value?.second || 0);
          }
          let json = convertJson(value);
          if (value != null && json?.year) {
            value = { year:json?.year, month: json?.monthValue - 1, day: json?.dayOfMonth, hour: json?.hour, minute: json?.minute, second: json?.second};
          }
          return this.$formatters.date(value);
        }
        case 'datetime':{
          if(Array.isArray(value)){
            value = new Date(value[0], value[1] - 1, value[2],value[3] || 0,value[4] || 0,value[5] || 0);
          }else if (value != null && typeof (value) === 'object') {
            value = new Date(value?.year, value?.monthValue - 1, value?.dayOfMonth, value?.hour, value?.minute, value?.second);
          }
          let json = convertJson(value);
          if (value != null && json?.year) {
            value = { year:json?.year, month: json?.monthValue - 1, day: json?.dayOfMonth, hour: json?.hour, minute: json?.minute, second: json?.second};
          }
          return this.$formatters.datetime(value);
        }
        case 'datetime-date':{
          return value != null ? this.$formatters[value.length === 10 ? 'date' : 'datetime'](value) : null;
        }
        case 'boolean':
          if(value === undefined && config.alt){
            return config.alt;
          }
          return this.$formatters.boolean(value);
        case 'currency':
          return value != null ? 'R$ ' + this.$formatters.currency(value) : '-';
        case 'decimal':
          return value != null ? this.$formatters.currency(value) : '-';
        case 'decimalsimples':
          return value != null ? this.$formatters.currency(value, {min: '0', max: 10}) : '-';
        case 'integer':
          return value != null ? this.$formatters.integer(value) : '-';
        case 'enum':
          return value != null ? this.$formatters.getLabel(value, config?.lista,'id', config?.nomeLista) : '-'
        case 'listaenum':{
          return value?.length && Utils.isArray(value) ? value?.map(v => this.$formatters.getLabel(v, config?.lista)).join(", ") : '-';
        }
        case 'tags':{
          let html = '';
          (value || []).forEach(v => html += `<span class="badge ${v.cor ? 'badge-'+v.cor+'-soft' : ''} mb-0">${v.nome}</span>`)
          return (value || []).length ? html : '-';
        }
        case 'json':
          return value?.nome || value || '-';
        case 'listahtml':
          return value?.length ? `<div class="html-field"><ul>${value.map(text => `<li>${text}</li>`).join('') || '-'}</ul></div>` : '-';
        case 'array':
          return value?.length ? `<div>${value.join(', ') || '-'}</div>` : '-';
        case 'html':
          return value != null ? `<div class="html-field">${value || '-'}</div>` : '-';
        default:
          return value;
      }
    },

    async detalhes(id) {
      await FrontBusiness.openModal(DetalhesIndisponibilidade, {id});
    },

    async detalhesPermissao(permissoes) {
      await FrontBusiness.openModal(ModalGrupoPermissao, {permissoes});
    },

    selecionarTodos(limpar = false) {
      let selecionados = [];
      if (!limpar) {
        Object.values(this.rows).forEach(e => {
          selecionados = [...selecionados, ...e.map(c => c.id)];
        })
      }
      this.$set(this, 'selecionados', selecionados);
      this.$emit('selecionados', selecionados);

    },

    selecionarId(id) {
      let selecionados = this.selecionados;
      if (selecionados.includes(id)) {
        selecionados = selecionados.filter(e => e !== id);
      } else {
        selecionados.push(id);
      }

      console.log(selecionados, id)
      this.$set(this, 'selecionados', selecionados);
      this.$emit('selecionados', selecionados);

    }

  }

}
</script>

<style lang=scss>
.theme-dark #auditoria-container .html-field {
  background: #16181d;
}
.theme-light #auditoria-container .html-field {
  background: #f8f9fa;
}

#auditoria-container .html-field{
  padding: 10px;
  max-height: 450px;
  overflow: auto;
}

.theme-dark #auditoria-container .td-conteudo {
  background: #1c1e2466;
  border-left: 1px solid #1C1E24;
}

.theme-light #auditoria-container .td-conteudo {
  background: #eeeeef;
  border-left: 1px solid #c8c9cd;
}


#auditoria-container td ul, #auditoria-container td ol{
  padding-left: 20px;
  margin: 0;
}

#auditoria-container td ul li, #auditoria-container td ol li{
  margin-bottom: 10px;
}

#auditoria-container td ul li:last-child, #auditoria-container td ol li:last-child{
  margin-bottom: 0;
}

#auditoria-container td {
  max-width: 400px;
}

.text-primary {
  * {
    color: #007bff !important;
  }
  li {
    color: #007bff !important;
  }
}

</style>
