import Http, {source} from "@/commons/Http";
import Utils from "@/commons/Utils.js";

const path = 'api/odin/text-extract';


export default {

  async getStatus(key, ref = null) {
    return Http.get(`${path}/status/${key}${ref ? `/${ref}` : ''}`);
  },

  async setError(key, ref = null) {
    return Http.get(`${path}/set-error/${key}${ref ? `/${ref}` : ''}`);
  },

  async getLastResult(key, ref = null) {
    return Http.get(`${path}/${key}${ref ? `/${ref}` : ''}`);
  },

  async agendar(key, prompt, ref = null) {
    return Http.post(`${path}/schedule/${key}${ref ? `/${ref}` : ''}`, {prompt: prompt});
  },

  async extrair(key, prompt, ref = null) {
    return Http.post(`${path}/${key}${ref ? `/${ref}` : ''}`, {prompt: prompt});
  },

  async extrairMatricula(key, prompt, ref) {
    return Http.post(`${path}/ocr-matricula/${key}/${ref}`, {prompt: prompt});
  },

  ocrMatricula(indicadorRealId) {
    return `${window.server.API}/${path}/ocr-digitalizacoes-matricula/${indicadorRealId}/subscribe`;
  },

  async ocrImagem(key, prompt, ref){
    return Http.post(`${path}/ocr-imagem/${key}/${ref}`, {prompt: prompt});
  }

}
