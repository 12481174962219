<template>
  <consulta ref="modal" id="consultar-bens-moveis" :process="process" :columns="columns" titulo="Consultar Bens Móveis"
            :custom-actions="actions">

    <template #cabecalho>
      <li class="list-inline-item" v-if="multiplos">
        <a @click.prevent="selecionarTodos()" title="Selecionar Todos" class="icon">
          <i class="fal fa-check-circle"></i>
        </a>
      </li>

      <li class="list-inline-item" v-if="multiplos">
        <a @click.prevent="selecionarTodos(true)" title="Limpar Seleção" class="icon">
          <i class="fal fa-circle"></i>
        </a>
      </li>
    </template>

    <template #filtros>
      <filtrar-consulta class="w-100" :options="opcoesConsulta" :dto="filtros">
      </filtrar-consulta>
    </template>

    <template #row="{ row }">
      <tr>
        <td width="50px" class="text-center" v-if="multiplos">
          <input :id="`check-${row.id}`" class="form-check-input" @click="selectRegistro(row)"
                 style="margin: -5px 0 0 -6px;position: relative;" type="checkbox" v-model="row.selecionado">
        </td>

        <td width="50px" class="text-center">
          <a href class="icon" @click.prevent.stop="detalhes(row)">
            <i class="fal fa-briefcase"></i>
          </a>
        </td>

        <td width="180px" class="pointer icon" @click="detalhes(row)">{{ row.codigo | inteiro }}</td>
        <td width="140px">{{ row.tipoBem}}</td>
        <td width="140px">{{ row.bemMovel}}</td>
        <td width="140px">{{ row.identificacao}}</td>
        <td width="140px">{{ row.fabricante}}</td>
        <td width="100px">{{ row.dataReferencia | data}}</td>
        <td width="180px">{{ row.localizacao}}</td>
        <td width="180px">{{ row.valor | moeda}}</td>
        <td width="180px">{{ row.notaFiscal}}</td>
        <td width="200px">{{ row.detalhes}}</td>
      </tr>

    </template>

  </consulta>
</template>
<script>
import Consulta from "@/components/Consultas/Consulta.vue";
import FiltrarConsulta from "@/components/Consultas/components/FiltrarConsulta.vue";
import TipoBemBusiness from "@/business/crud/TipoBemBusiness";
import Utils from "@/commons/Utils";
import FrontBusiness from "@/business/FrontBusiness";
import FormBemMovel from "@/components/BemMovel/Form/FormBemMovel.vue";
import DetalhesBemMovel from "@/components/BemMovel/Detalhes/DetalhesBemMovel.vue";
import BemMovelBusiness from "@/business/crud/BemMovelBusiness";
import UsuarioBusiness from "@/business/crud/UsuarioBusiness";

export default {
  name: 'ConsultarBensMoveis',
  components: {FiltrarConsulta, Consulta},
  props:{
    atribuicao: String,
    seleciona : {type : Boolean, default : true},
    multiplos : {type : Boolean, default : false},
  },

  data(){
    return {
      marcados: [],
      filtros: {},
      opcoesConsulta: [],
      options:{
        tipos: []
      }
    }
  },

  async mounted(){
    await this.load();
  },

  computed: {
    columns(){
      return [
        {label: '', show: this.multiplos, field: 'selecionar', sortable: false, name: 'selecionar'},
        {label: '', field: 'id', headerClass: 'column__botao', sortable: false, name: 'id'},
        {label: 'Bem Móvel', field: 'codigo', name: 'bem_movel', minWidth: '180px' },
        {label: 'Tipo', field: 'tb.nome', name: 'tipo', minWidth: '140px'},
        {label: 'Nome', field: 'bm.nome', name: 'nome', minWidth: '200px'},
        {label: 'Identificação', field: 'bm.identificacao', name: 'identificacao', minWidth: '180px'},
        {label: 'Fabricante', field: 'bm.fabricante', name: 'fabricante', minWidth: '180px'},
        {label: 'Data de Referência', field: 'bm.data_referencia', name: 'dataReferencia', minWidth: '200px'},
        {label: 'Localização', field: 'bm.localizacao', name: 'localizacao', minWidth: '180px'},
        {label: 'Valor', field: 'bm.valor', name: 'valor', minWidth: '180px'},
        {label: 'Nota Fiscal', field: 'bm.nota_fiscal', name: 'notaFiscal', minWidth: '180'},
        {label: 'Detalhes', field: 'bm.detalhes', name: 'detalhes', minWidth: '200px'},
      ]
    },

    actions() {
      let actions = [];
      actions.push({
        ordem: 4,
        class: 'at-options',
        icon: 'far fa-plus-square mr-1',
        label: 'Cadastrar Bem Móvel',
        action: this.cadastrar
      });
      return  Utils.orderBy(actions, 'ordem');
    }
  },

  methods:{

    async load(){
      this.opcoesConsulta.push({unificado: true, livro: null, status: ['ABERTA','CANCELADA','ENCERRADA'],id: 'usuario_alteracao_manual', nome: 'Usuário da Alteração Manual', type: 'list', options: await UsuarioBusiness.getAllSimpleList()},)

      let p1 = TipoBemBusiness.getSimpleList().then(l => this.options.tipos = Utils.orderBy(l, 'nome'));

      await Promise.all([p1]);

      let options = [
        {id: 'tipo_bem_id', nome: 'Tipo', type: 'list', options: this.options.tipos},
        {id: 'nome', nome: 'Nome', type: 'text'},
        {id: 'identificacao', nome: 'Identificação', type: 'text'},
        {id: 'fabricante', nome: 'Fabricante', type: 'text'},
        {id: 'data_referencia', nome: 'Data de Referência', type: 'date'},
        {id: 'localizacao', nome: 'Localização', type: 'text'},
        {id: 'valor', nome: 'Valor', type: 'currency'},
        {id: 'nota_fiscal', nome: 'Nota Fiscal', type: 'text'},
        {id: 'detalhes', nome: 'Detalhes', type: 'text'},
        {id: 'codigo', nome: 'Código', type: 'number'},
      ]
      this.opcoesConsulta = Utils.orderBy(options, 'nome')
    },

    selectRegistro(ficha) {
      let index = this.marcados.findIndex(e => e.id === ficha.id);
      if (index >= 0) {
        this.marcados.splice(index, 1)
      } else {
        this.marcados.push(ficha)
      }
    },

    selecionarTodos(limpar = false) {
      this.marcados = [];
      this.$refs?.modal?.$refs?.paginator?.rows.forEach(r => {
        let input = document.getElementById(`check-${r.id}`)
        input.checked = !limpar;
        if (!limpar) this.marcados.push(r)
      })
    },

    detalhes(bem){
      if(this.seleciona) {
        FrontBusiness.closeModal(bem);
      } else {
        FrontBusiness.openModal(DetalhesBemMovel, {id: bem.id})
      }
    },

    cadastrar(){
      FrontBusiness.openModal(FormBemMovel, {atribuicao: this.atribuicao})
    },

    async process(sort_by, sort_dir, page_length, page_number){
      let filtros = [...this.filtros?.filtros].filter(e => e.id);

      return BemMovelBusiness.paginationV2(sort_by, sort_dir, page_number, page_length, {filtros})
        .then(result => ({rows: result.pagina, total: result.quantidade}));

    }
  }
}
</script>
