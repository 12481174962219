<template>

  <modal id="detalhes-indicador-real" class="modal-detalhes-entidade" :titulo="titulo" :loading="loading" :check="indicadorValido">
    <template #title-extra>
      <div v-if="ficha && ficha.status !== 'ABERTA'" class="badge text-center" :class="classStatus">
        {{ficha.status}}
      </div>
    </template>
    <template #actions>

      <template v-if="!usuario.auditor && ficha.status !== 'PENDENTE' && !preview">

        <li class="list-inline-item" :class="{active : aba == 'anexos'}" >
          <a @click.prevent="aba = 'anexos'" title="Documentação & Anexos" href>
            <i class="fal fa-paperclip"></i>
          </a>
        </li>

        <li class="list-inline-item" :class="{active : aba == 'historico'}" >
          <a @click.prevent="aba = 'historico'" title="Histórico" href>
            <i class="fas fa-history"></i>
          </a>
        </li>

        <li class="list-inline-item" :class="{active : aba == 'versoes'}">
          <a @click.prevent="aba = 'versoes'" title="Dados anteriores" href>
            <i class="fal fa-user-tag"></i>
          </a>
        </li>

      </template>

        <li class="list-inline-item">
          <router-link :to="{ name: 'digitalizacao', params: { id: id, entidade : 'INDICADOR_REAL', tipoEntidade : getTipoEntidade(ficha) }}" target="_blank" title="Digitalização">
            <i class="fal fa-file-image"></i>
          </router-link>
        </li>

      <li class="list-inline-item" v-if="permitirImprimir">
        <a @click.prevent.stop="imprimir(ficha)" title="Imprimir" href>
          <i class="fal fa-print"></i>
        </a>
      </li>

      <li class="list-inline-item" v-if="(!usuario.auditor && !preview)">
        <a @click.prevent.stop="editarIndicador(ficha)" title="Editar Indicador"  href>
          <i class="fal fa-edit"></i>
        </a>
      </li>

      <li class="list-inline-item" v-if="(!usuario.auditor && !preview)">
        <a @click.prevent.stop="importarIndicador(ficha)" title="Importar Indicador"  href>
          <i class="fal fa-file-import"></i>
        </a>
      </li>

      <li class="list-inline-item" v-if="mostrarIa">
        <a @click.prevent.stop="odinMatricula(ficha)" title="Odin - Processar Matrícula" href>
          <i class="fal fa-microchip-ai"></i>
        </a>
      </li>

    </template>

    <template #header>

      <ul class="nav nav-tabs">

        <template  v-for="a in abas">
          <li class="nav-item" :key="a.id" v-if="a.exibir">
            <a @click.prevent="aba = a.id" :title="a.title" class="nav-link py-1 px-2 small"
               href :class="{'active' : aba == a.id}">
              {{a.nome}}
            </a>
          </li>
        </template>

      </ul>

    </template>

    <template v-if="ficha.id">

      <template v-if="!usuario.auditor">

        <alert-indisponibilidade :imovel="ficha.id" tipo="alert-banner" />
        <alert-ocorrencia :imovel="ficha.id" tipo="alert-banner" :banner="ocorrenciaBloqueante ? 'alert-danger' : 'alert-warning'" />

        <template v-for="(alerta, index) in alertas" >
          <AlertBanner v-if="alerta.mostrar" :key="index" :message="alerta.msg" :type="alerta.tipo" />
        </template>

        <template v-if="(componente === 'aba-atos' || ficha.status == 'PENDENTE') && ficha.textoLegado">
          <a @click.prevent="vizualizarTextoLegado(ficha)" title="Vizualizar Texto" href>
            <AlertBanner message="Indicador possui um texto importado!"/>
          </a>
        </template>

      </template>

    </template>
    <component v-if="!loading" :is="componente" :ficha="ficha" :id="ficha.id" :preview="preview" @load="load"/>
    <template #footer>
      <template v-if="permitirExcluir">
        <v-button :run="excluir" :popover="true" position="dropupleft" class="btn btn-outline-danger ml-auto">Excluir</v-button>
      </template>
    </template>
  </modal>

</template>

<script>

import AuditoriaBusiness from "@/business/AuditoriaBusiness.js";
import FrontBusiness from "@/business/FrontBusiness";
import IndicadorBusiness from "@/business/indicadores/IndicadorBusiness";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
import EventBus from "@/commons/EventBus";
import AlertBanner from "@/components/alert/AlertBanner";
import AlertMessage from "@/components/alert/AlertConstMessage";
import AlertIndisponibilidade from "@/components/alert/AlertIndisponibilidade.vue";
import AlertOcorrencia from "@/components/alert/AlertOcorrencia.vue";
import RegistrosPosteriores from "@/components/IndicadorGeneric/Detalhes/RegistrosPosteriores.vue";
import Anexos from "@/components/IndicadorReal/Detalhes/Anexos";
import Atos from "@/components/IndicadorReal/Detalhes/Atos";
import Dados from "@/components/IndicadorReal/Detalhes/Dados";
import ExProprietarios from "@/components/IndicadorReal/Detalhes/ExProprietarios";
import Historico from "@/components/IndicadorReal/Detalhes/Historico";
import Indisponibilidades from "@/components/IndicadorReal/Detalhes/Indisponibilidades";
import Ocorrencias from "@/components/IndicadorReal/Detalhes/Ocorrencias.vue";
import Protocolos from "@/components/IndicadorReal/Detalhes/Protocolos";
import Resumo from "@/components/IndicadorReal/Detalhes/Resumo.vue";
import Sigef from "@/components/IndicadorReal/Detalhes/Sigef.vue";
import Versoes from "@/components/IndicadorReal/Detalhes/Versoes.vue";
import FormIndicadorReal from "@/components/IndicadorReal/Form/FormIndicadorReal.vue";
import ImprimirFicha from "@/components/Livros/ImprimirFicha.vue";
import Modal from "@/components/Modal";
import ModalOdinProcessamentoAto from "@/views/Odin/modais/ModalOdinProcessamentoAto.vue";
import ModalOdinProcessamentoMatricula from "@/views/Odin/modais/ModalOdinProcessamentoMatricula.vue";
import TextoLegado from "@/views/Protocolo/Atos/TextoLegado";
import OcorrenciaBusiness from "@/business/ocorrencia/OcorrenciaBusiness";
import ImportarIndicadorReal from "@/components/IndicadorReal/Detalhes/ImportarIndicadorReal.vue";

export default {

    name: "DetalhesIndicadorReal",
    components: {
      AlertOcorrencia,
      AlertIndisponibilidade,
      AlertBanner,
      Modal,
      "aba-dados" : Dados,
      "aba-versoes" : Versoes,
      "aba-resumo" : Resumo,
      "aba-unidades" : RegistrosPosteriores,
      "aba-ex-proprietarios" : ExProprietarios,
      "aba-atos" : Atos,
      "aba-sigef" : Sigef,
      "aba-protocolos" : Protocolos,
      "aba-ocorrencias" : Ocorrencias,
      "aba-anexos" : Anexos,
      "aba-historico" : Historico,
      "aba-indisponibilidades" : Indisponibilidades
    },

    props: {
      id : String,
      preview: Object,
      abaInicial: String
    },

    modal: {
      width: 1200
    },

    async mounted(){
      await this.load();
      await this.possuiOcorrenciasBloqueantes();
      AuditoriaBusiness.auditarTela({id: this.id, entidade: 'INDICADOR_REAL', descricao: `Tela: Detalhes do Indicador` })
        .catch(e => null);

      EventBus.$on('ATOS-LOAD', this.load);
    },

    computed:{
      mostrarIa(){
        return this.$root.isVhlUser;
      },
      titulo(){
        return this.ficha?.livro ? FrontBusiness.nomearFicha(this.ficha) : '';
      },
      componente(){
        return `aba-${this.aba}`;
      },
      alertas() {
          return [
              // {mostrar: this.ficha.indisponibilidades?.length, msg: AlertMessage.indicadorPossuiIndisponibilidade(), tipo : 'alert-danger'},
              // {mostrar: this.ficha.ocorrenciasAtivas, msg: AlertMessage.indicadorPossuiOcorrencias(), tipo : 'alert-danger'},
              {mostrar: this.impressaoDesatualizada, msg: AlertMessage.impressaoDesatualizada(), tipo : 'alert-warning'},
              {mostrar: this.digitalizacaoDesatualizada, msg: AlertMessage.digitalizacaoDesatualizada(), tipo : 'alert-warning'},
              {mostrar: this.protocolosAtivos, msg: AlertMessage.indicadorPossuiProtocolos(), tipo : 'alert-warning'},
          ];
      },

      usuario() {
        return this.$root.usuarioLogado;
      },

      permitirImprimir(){
        return !this.usuario.auditor && !this.preview
          && (this.ficha.status != 'PENDENTE' || this.$root.config?.indicadorReal?.permitirRegistroPreMatricula)
      },

      isAuditor() {
        return this.$root.usuarioLogado?.auditor;
      },

      permitirExcluir(){
        return this.ficha.livro === 'TRANSCRICAO' || this.$root.getPermission({id: 'PERMITIR_EXCLUIR_INDICADOR_REAL'});
      },

      classStatus() {
        if (this.ficha.status == 'ENCERRADA') {
          return 'badge-primary-soft';
        } else if (this.ficha.status == 'CANCELADA') {
          return 'badge-danger-soft';
        }
        return 'badge-success-soft'
      },
    },

    data() {

      return {
        ficha : {},
        aba : 'dados',
        protocolosAtivos: false,
        impressaoDesatualizada : false,
        digitalizacaoDesatualizada : false,
        indicadorValido: false,
        abas:[],
        loading: true,
        ocorrenciaBloqueante: false
      }
    },

    methods:{
      showError(e) {
        FrontBusiness.showError('', e.message);
      },

      async load(loading = true){
        this.loading = loading;
        if (!!this.preview) {
          this.ficha = this.preview
        } else {
          this.ficha = await IndicadorRealBusiness.getById(this.id);

          let protocolos = [];
          const auditor = this.usuario.auditor;

          await Promise.all([
            // await IndicadorRealBusiness.buscarAtosRegistrados(this.ficha.id).then(r => this.atos = r),
            !auditor ? IndicadorRealBusiness.buscarProtocolosAtivos(this.ficha.id).then(r => protocolos = r) : null,
            !auditor ? this.checarImpressao() : null,
            this.indicadorRealValidado()
          ]);

          this.protocolosAtivos = protocolos.length > 0;

        }

        let exibir = !this.usuario.auditor && !this.preview;
        let isPendente = this.ficha.status == 'PENDENTE';
        let permiteRegistroAto = this.$root.config?.indicadorReal?.permitirRegistroPreMatricula;
        let isRuralUrbano = this.ficha.categoria == 'RURAL' || this.ficha.categoria == 'URBANO';
        let configuracao = this.$root.config;

        this.abas = [
          {id: 'dados', nome: 'Dados', exibir: true, title: 'Dados Principais'},
          {id: 'atos', nome: 'Atos Registrados', exibir: (!this.preview && (!isPendente || permiteRegistroAto)), title: 'Atos Registrados'},
          {id: 'resumo', nome: 'Resumo', exibir: (exibir && configuracao.resumoIndicadorReal)},
          {id: 'sigef', nome: 'SIGEF', exibir: (exibir && isRuralUrbano)},
          {id: 'unidades', nome: 'Registros Posteriores', exibir: exibir && ['MATRICULA', 'TRANSCRICAO'].includes(this.ficha.livro)},
          {id: 'ex-proprietarios', nome: 'Ex-Proprietários', exibir: !this.preview && (!isPendente || permiteRegistroAto)},
          {id: 'protocolos', nome: 'Protocolos', exibir: exibir},
          {id: 'indisponibilidades', nome: 'CNIB', exibir: exibir && !isPendente},
          {id: 'ocorrencias', nome: 'Ocorrências', exibir: exibir},
          // {id: 'anexos', nome: 'Anexos', exibir: exibir, title: 'Documentação & Anexos'}
        ];

        if (this.abaInicial) {
          this.$set(this, 'aba', this.abaInicial);
        }

        this.$set(this, 'loading', false);

      },

      async possuiOcorrenciasBloqueantes(){
        let ocorrenciasIndicadorReal = await OcorrenciaBusiness.listarPorIndicadorReal(this.ficha.id);
        for (let i = 0; i < ocorrenciasIndicadorReal.length; i++) {
          if (ocorrenciasIndicadorReal[i].ocorrencia_bloqueante === "true") {
            this.ocorrenciaBloqueante = true;
          }
        }
      },

      async checarImpressao(){
        this.digitalizacaoDesatualizada = await IndicadorRealBusiness.digitalizacaoDesatualizada(this.id);
        this.impressaoDesatualizada = await IndicadorRealBusiness.impressaoDesatualizada(this.id);
      },

      async odinMatricula(ficha){
        await FrontBusiness.openModal(ModalOdinProcessamentoMatricula, {indicadorRealId: ficha.id});
        // await FrontBusiness.sleep(500);
        // await this.load();
      },

      async editarIndicador(ficha) {
        let result = await FrontBusiness.openModal(FormIndicadorReal, {id: ficha.id, livro: ficha.livro});
        if(result){
          this.$set(this, 'ficha', {});
          await this.load();
        }
      },

      async importarIndicador(ficha) {
        let result = await FrontBusiness.openModal(ImportarIndicadorReal, {ficha: ficha});
        if(result){
          this.$set(this, 'ficha', {});
          await this.load();
        }
      },

      async imprimir(ficha){
        FrontBusiness.openModal(ImprimirFicha, {id: ficha.id, tipo: ficha.livro, codigo: ficha.codigo});
        this.aba = 'dados';
      },

      async vizualizarTextoLegado(ficha){
        let retorno = await FrontBusiness.openModal(TextoLegado, {ficha: ficha, tipo: "INDICADOR_REAL"});
        if (retorno) {
          this.ficha = retorno;
        }
      },

      async indicadorRealValidado(){
        this.indicadorValido = await IndicadorRealBusiness.indicadorRealValidado(this.ficha.id);
      },

      async excluir(){
        if (this.permitirExcluir) {
          let retorno = await IndicadorRealBusiness.delete(this.ficha.id).catch(this.showError);
          if (retorno) {
            FrontBusiness.closeModal();
          }
        }
      },
      getTipoEntidade(ficha){
        return IndicadorBusiness.getTipoEntidade(ficha);
      },

    }

  }
</script>
