import RegistroTDBusiness from "@/business/indicadores/RegistroTDBusiness.js";
import DominioBusiness from "@/business/protocolo/DominioBusiness.js";
import RegraCustasBusiness from "@/business/RegraCustasBusiness.js";
import TabelaCustaBusiness from "@/business/TabelaCustaBusiness.js";
import VueBusiness from "@/business/VueBusiness.js";
import Http from "@/commons/Http";
import ProtocoloBusiness from "@/business/protocolo/ProtocoloBusiness";
import FrontBusiness from "@/business/FrontBusiness";
import RegistroAuxiliarBusiness from "@/business/indicadores/RegistroAuxiliarBusiness";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness";
import AtoBusiness from "@/business/protocolo/AtoBusiness";
import DepartamentoBusiness from "@/business/crud/DepartamentoBusiness";
import CaixaBusiness from "@/business/crud/CaixaBusiness";
import FilaAtendimentoBusiness from "@/business/crud/FilaAtendimentoBusiness";
import AnexoBusiness from "@/business/AnexoBusiness";

import moment from 'moment';
import CategoriaBusiness from "@/business/crud/CategoriaBusiness";
import EtapaBusiness from "@/business/crud/EtapaBusiness";
import ChecklistBusiness from "@/business/crud/ChecklistBusiness";
import business from "@/views/Administracao/modulos/tipo-servico/business";
import Utils from "@/commons/Utils";
import LayoutImpressaoBusiness from "@/business/crud/LayoutImpressaoBusiness";
import TipoServicoBusiness from "@/business/crud/TipoServicoBusiness";
import SiteBusiness from "@/business/SiteBusiness";
import NotaFiscalBusiness from "@/business/financeiro/NotaFiscalBusiness";
import ConfiguracaoBusiness from "@/business/ConfiguracaoBusiness";
import ChecklistRequisitoBusiness from "@/business/ChecklistRequisitoBusiness";
import TipoDocumentoBusiness from "@/business/crud/TipoDocumentoBusiness";
import ListaBusiness from "@/business/crud/ListaBusiness";
import SiscoafBusiness from "@/business/protocolo/SiscoafBusiness";
import ProtocoloFinanceiroBusiness from "@/business/protocolo/ProtocoloFinanceiroBusiness";
import AuthBusiness from "@/business/AuthBusiness";
import CertidaoBusiness from "@/business/protocolo/CertidaoBusiness";
import UsuarioBusiness from "@/business/crud/UsuarioBusiness";
import BemMovelBusiness from "@/business/crud/BemMovelBusiness";
import TipoResultadoBusiness from "@/business/crud/TipoResultadoBusiness";
import indicadorRealExternoBusiness from "@/business/indicadores/IndicadorRealExternoBusiness";
import ProtocoloCustaAdicionalBusiness from "@/business/protocolo/ProtocoloCustaAdicionalBusiness";
import AnexosModal from "@/components/Anexos/AnexosModal.vue";
import IndicadorBusiness from "@/business/indicadores/IndicadorBusiness";
import IndicadorAbstractService from "@/business/indicadores/IndicadorAbstractService";

const path = 'api/auditoria';

export default {

  operacoes: Object.freeze({
    LEGADO : 'Legado',
    CREATE : 'Cadastro',
    UPDATE : 'Edição',
    DELETE : 'Exclusão',
    UPDATE_APOS_SELADO_REGISTRADO : 'Atualização após registro',
    LOGIN : 'Login',
    DESFAZER_REGISTRO : 'Registro Desfeito',
  }),

  formattersDate(value, empty = '-') {
    if (value != null && typeof (value) === 'object' && value?.year) value = new Date(value?.year, value?.monthValue - 1, value?.dayOfMonth, value?.hour, value?.minute, value?.second);
    return !value ? empty : moment(value, "YYYY-MM-DD").format("DD/MM/YYYY");
  },

  async pagination(sort, direction, page, perPage, filters = {}){
    return Http.post(`${path}/pagination?sort=${sort || 'a.cadastro'},${direction || 'desc'}&page=${page || 1}&size=${perPage || 10}`, filters);
  },

  async getById(id){
    return Http.get(`${path}/${id}`);
  },

  async getEntityById(entidade, business, campo = 'nome', empty = '-') {
    let value = entidade?.[campo];
    if(value){
      return value;
    }
    let id = entidade?.id || entidade;
    if (!Utils.testUUID(id)) {
      return empty;
    }
    let retorno = await business.getById(id)?.catch(e => null);
    return retorno?.[campo] || id;
  },

  async getTipoAto(ato){
    let id = ato?.id || ato;
    if (!Utils.testUUID(id)) {
      return '-';
    }

    let tipoAto = await AtoBusiness.getById(0, ato).then(response =>{
      return response ? response : id;
    }).catch(e =>{
      return id;
    });

    return `${tipoAto.tipo == 'REGISTRO' ? 'R.' : tipoAto.tipo == 'AVERBACAO' ? 'Av.' : 'M.'}` + `${tipoAto?.codigo}` + ` - ${tipoAto?.titulo}`

  },

  async getAuditorias(entidade, dominio, page, size){
    return Http.get(`${path}/list/${entidade}${dominio ? `/${dominio}` : ''}?page=${page || 1}&size=${size || 10}`);
  },

  async nomearDepartamento(id) {
    id = id?.id || id;
    if (!id) {
      return '-';
    }
    let departamento = await DepartamentoBusiness.getById(id).catch(e => null);
    if (!departamento) {
      return id;
    }
    return departamento?.nome;
  },

  async nomearEtapa(id) {
    id = id?.id || id;
    if (!id) {
      return '-';
    }
    let etapa = await EtapaBusiness.getById(id).then(response =>{
      return response ? response : id;
    }).catch(e =>{
      return id;
    });
    return etapa?.nome;
  },

  async nomearCategoria(id) {
    id = id?.id || id;
    if (!id) {
      return '-';
    }
    let categoria = await CategoriaBusiness.getById(id).then(response =>{
      return response ? response : id;
    }).catch(e =>{
      return id;
    });
    return categoria?.nome;
  },

  async nomearCaixa(id) {
    id = id?.id || id;
    if (!id) {
      return '-';
    }
    let caixa = await CaixaBusiness.getById(id).then(response =>{
      return response ? response : id;
    }).catch(e =>{
      return id;
    });
    return caixa?.nome;
  },

  async getAnexo(anexoOrIdAnexo) {
    let url = await AnexoBusiness.ver(anexoOrIdAnexo);
    if (!url) {
      return anexoOrIdAnexo;
    }
    return url ? `<img style="width: 150px;" src="${url}">` : '-';
  },

  configuracaoAcesso(configuracaoAcesso) {
    if (!configuracaoAcesso) {
      return '-'
    }
    let html = '';
    if (!Utils.isEmptyNullOrUndefined(configuracaoAcesso?.doi)) {
      html += `<b>DOI:</b> ${configuracaoAcesso.doi ? 'Sim' : 'Não'} </br>`
    }
    if (!Utils.isEmptyNullOrUndefined(configuracaoAcesso?.cpai)) {
      html += `<b>CPAI:</b> ${configuracaoAcesso.cpai ? 'Sim' : 'Não'} </br>`
    }
    if (!Utils.isEmptyNullOrUndefined(configuracaoAcesso?.livroReceitaDespesa)) {
      html += `<b>Livro Diário Auxiliar de Receitas e Despesas:</b> ${configuracaoAcesso.livroReceitaDespesa ? 'Sim' : 'Não'} </br>`
    }
    if (!Utils.isEmptyNullOrUndefined(configuracaoAcesso?.livroDepositoPrevio)) {
      html += `<b>Livro Controle de Depósito Prévio:</b> ${configuracaoAcesso.livroDepositoPrevio ? 'Sim' : 'Não'} </br>`
    }
    if (!Utils.isEmptyNullOrUndefined(configuracaoAcesso?.livroProtocolo)) {
      html += `<b>Livro Protocolo:</b> ${configuracaoAcesso.livroProtocolo ? 'Sim' : 'Não'} </br>`
    }
    return html
  },

  async filaAtendimento(lista = []) {
    if (!lista.length) {
      return '-'
    }
    let filas = await FilaAtendimentoBusiness.getSimpleList();
    let retorno = '';
    lista.forEach(l => {
      let nome = filas.find(f => f.id === l)?.nome;
      retorno += `<li class="m-0 p-0 mb-2" style="list-style: none;overflow: hidden;">${nome}</li>`
    })

    return retorno.length ? retorno : '-';
  },

  formataData(value, formato = 'DD/MM/YYYY') {
    if (value != null && typeof (value) === 'object') {
      value = new Date(value?.year || 2000, (value?.monthValue || 1) - 1, value?.dayOfMonth || 1, value?.hour || 0, value?.minute || 0, value?.second || 0);
    }
    return !['', null, undefined].includes(value) ? moment(value).format(formato) || '-' :  '-';
  },

  async listaBloqueio(listaBloqueio = []) {
    if (!listaBloqueio.length) {
      return '-'
    }
    let retorno = '';
    let dias_semana =[
      {id: 'SUNDAY', nome:'Domingo'},
      {id: 'MONDAY', nome:'Segunda-feira '},
      {id: 'TUESDAY', nome:'Terça-feira '},
      {id: 'WEDNESDAY', nome:'Quarta-feira '},
      {id: 'THURSDAY', nome:'Quinta-feira '},
      {id: 'FRIDAY', nome:'Sexta-feira '},
      {id: 'SATURDAY', nome:'Sábado'}]
    listaBloqueio.forEach(l => {
      if (l.diaSemana) {
        retorno += `<b>${dias_semana.find(d => d.id === l.diaSemana)?.nome}</b>`;
      }
      if (l.dataInicio) {
        retorno += `<b>${this.formataData(l.dataInicio)}</b>`;
      }
      if (l.dataFim) {
        retorno += `<b> - ${this.formataData(l.dataFim)}</b>`;
      }
      if (l.horarioInicio) {
        retorno += ` ${this.formataData(l.horarioInicio, 'HH:mm')} `;
      }
      if (l.horarioFim) {
        retorno += ` - ${this.formataData(l.horarioFim, 'HH:mm')}`;
      }
      retorno += '</br>'
    })
    return retorno.length ? retorno : '-';
  },

  async nomearCertidao(certidao) {
    let id = certidao?.id || certidao;
    if (!id) {
      return '-';
    }
    let certidaoSelo = await CertidaoBusiness.getById(0, id).then(response =>{
      return response ? response : id;
    }).catch(e =>{
      return id;
    })

    return `Certidão [${FrontBusiness.formatarCodigo(certidaoSelo.pedido)}] ${(certidaoSelo.ficha ? FrontBusiness.nomearFicha(certidaoSelo.ficha, true, true) : '')}`;
  },

  async nomearCustaAdicional(item) {
    let id = item?.id || item;
    if (!id) {
      return '-';
    }

    let itemCompleto = await ProtocoloCustaAdicionalBusiness.getById(0, id).then(response =>{
        if (!response) {
            return id;
        } else {
          return response;
        }
    }).catch(e =>{
      return id;
    })

    function nome(custaAdicional) {
      let nome = '';
      if (custaAdicional?.descricao) {
        nome = custaAdicional?.descricao;
        if (custaAdicional?.tipoServico?.nome && custaAdicional?.tipoServico?.nome != custaAdicional?.descricao) {
          nome += ` [${custaAdicional?.tipoServico?.nome}]`;
        }
      } else if (custaAdicional?.tipoServico?.nome) {
        nome = custaAdicional?.tipoServico?.nome;
      } else {
        nome = "Custas Adicionais";
      }
      return nome
    }

      return `[${itemCompleto.quantidade}] ` + nome(itemCompleto);
  },

  async nomearPrenotacao(item) {
    let id = item?.id || item;
    if (!id) {
      return '-';
    }
    let itemCompleto = await ProtocoloBusiness.getPrenotacaoById(0, id);
    if (!itemCompleto) {
      return id;
    }

    return FrontBusiness.getLabel(itemCompleto.tipoRegistro, TabelaCustaBusiness.listTiposRegistro());
  },

  async nomearProtocolo(indicador) {
    let id = indicador?.id || indicador;
    if (!id) {
      return '-';
    }
    let protocolo = await ProtocoloBusiness.getById(id).then(response =>{
      return response ? response : id;
    }).catch(e =>{
      return id;
    });

    return FrontBusiness.nomearProtocolo(protocolo.dominio, protocolo.tipoProtocolo, protocolo.codigo);
  },

  async nomearFicha(indicador, tipo) {
    let id = indicador?.id || indicador;
    let business;
    let businessNomearFicha = FrontBusiness;
    switch (tipo) {
      case 'registroAuxiliar':
        business = RegistroAuxiliarBusiness;
        break;
      case 'registroTitulo':
        business = RegistroTDBusiness;
        break;
      case 'indicadorRealExterno':
        business = indicadorRealExternoBusiness;
        businessNomearFicha = business;
        break;
      case 'indicador':
        business = IndicadorAbstractService;
        break;
      case 'bemMovel':
        business = BemMovelBusiness;
        break;
      default:
        business = IndicadorRealBusiness;
    }

    if (!id) {
      return '-';
    }
    let ficha = await business.getById(id).then(response =>{
      return response ? response : id;
    }).catch(e =>{
      return id;
    });
    return businessNomearFicha.nomearFicha(ficha);
  },

  async getTipoAtoTribunalByCodigo(codigo, dominio) {
    if (!codigo) {
      return '-';
    }

    let lista = await TipoServicoBusiness.getTiposAtoTribunal(dominio);
    let item = (lista || []).find(e => e.codigo == codigo);
    if (!item) {
      return codigo;
    }

    return item.nome;
  },

  async usuarioLogado(usuario){
    let id = usuario?.id || usuario
    if (!Utils.testUUID(id)) {
      return '-';
    }

    let usuarioLogado = await AuthBusiness.getLoggedUser(id).catch(e => null);

    if(!usuarioLogado){
      return id
    }

    return `${usuarioLogado.nome}`;
  },

  async getUsuario(pessoa){
    let id = pessoa?.id || pessoa;
    if (!Utils.testUUID(id)) {
      return '-';
    }

    let usuario = await UsuarioBusiness.getById(id).then(response =>{
      return response ? response : id;
    }).catch(e =>{
      return id;
    });

    return usuario.nome
  },

  async indicadorPessoal(pessoa) {
    let id = pessoa?.id || pessoa;
    if (!Utils.testUUID(id)) {
      return '-';
    }

    let indicadorPessoal = await IndicadorPessoalBusiness.getVersaoById(id).catch(e => null);
    if (!indicadorPessoal) {
      return id;
    }
    return `${indicadorPessoal.nome}${indicadorPessoal.documento ? ` - ${indicadorPessoal.documento}` : ''}${indicadorPessoal.versao ? ` (Versão: ${indicadorPessoal.versao})` : ''}`;
  },

  async indicadorDocumentos(documentos = []) {
    let promises = [];
    for (const documento of documentos) {
      let p = this.getEntityById(documento.tipoDocumento, TipoDocumentoBusiness).then(async (tipo) => {
        return `
                <li>
                   <b>${tipo}</b>
                   <i class="fal fa-paperclip anexos-documentos pointer" data-documento-id="${documento.id}"></i>
                   <br>
                   <div class="row">
                     ${documento.dataDocumento ? `<div class="w-100 small"><b>Data do Documento: </b>${moment(documento.dataDocumento).format('DD/MM/YYYY')}</div>` : ''}
                     ${documento.numeroDocumento ? `<div class="w-100 small"><b>Nº do Documento: </b>${documento.numeroDocumento}</div>` : ''}
                     ${documento.quantidadeFolhas ? `<div class="w-100 small"><b>Quantidade de Folhas: </b>${documento.quantidadeFolhas}</div>` : ''}
                     ${documento.quantidadeArquivamento && !VueBusiness.getVueRoot().isEstadoMT ? `<div class="w-100 small"><b>Quantidade de Arquivamento: </b>${documento.quantidadeArquivamento}</div>` : ''}
                     ${documento.observacao ? `<div class="w-100 small"><b>Observações: </b>${documento.observacao}</div>` : ''}
                   </div>
                </li>`;
      });

      promises.push(p);
    }
    let html = await Promise.all(promises);
    return documentos?.length ? `<ul>${html.join('')}</ul>` : null;
  },

  async envolvidos(params = {}) {
    let {envolvidos, notificacao, ocorrencia, documentacao, doi} = params;

    let promises = [];
    envolvidos = Utils.orderBy(envolvidos || [], 'ordem');

    const participacao = (e, doi) => {
      if (!doi) return null;
      if(!['ADQUIRENTE','CESSIONARIO','PROMITENTE_COMPRADOR','TRANSMITENTE','CEDENTE','PROMITENTE_VENDEDOR'].includes(e.papel)) return null;

      let participacao = (e.participacaoDoi || 0);
      return Utils.truncateNumber(participacao, 2);
    }

    for (let envolvido of envolvidos) {
      if (envolvido) {
        let p = this.indicadorPessoal(envolvido?.indicadorPessoalVersao?.id || envolvido?.indicadorPessoalVersao).then(async (pessoa) => {
          let inventariante = envolvido?.inventarianteVersao ? await this.indicadorPessoal(envolvido?.inventarianteVersao) : null;
          let medidaArea = envolvido?.medidaArea ? FrontBusiness.getLabel(envolvido.medidaArea, IndicadorRealBusiness.getMedidas()) : '';
          let exibirFracao = ['ADQUIRENTE', 'TRANSMITENTE', 'DEVEDOR', 'GARANTIDOR', 'CREDOR', 'CEDENTE', 'CESSIONARIO', 'PROMITENTE_VENDEDOR',
            'PROMITENTE_COMPRADOR', 'FIEL_DEPOSITARIO'].includes(envolvido?.papel);
          let participacaoDoi = participacao(envolvido, doi);
          let htmlRepresentante = await this.getHtmlRepresentante(envolvido.representantes);
          const h = `<li>
                   <b>${pessoa}</b><br>
                   <div class="row">
                     ${envolvido.papel ? `<div class="w-100 small"><b>Papel: </b>${IndicadorRealBusiness.getPapeisEnvolvidos({notificacao, ocorrencia, documentacao}).find(e => e.id === envolvido.papel)?.nome}</div>` : ''}
                     ${envolvido.area ? `<div class="w-50 small"><b>${medidaArea}: </b>${envolvido.area}</div>` : ''}
                     ${envolvido.fracao && exibirFracao ? `<div class="w-50 small"><b>Fração: </b>${envolvido.fracao}%</div>` : ''}
                     ${participacaoDoi && exibirFracao ? `<div class="w-50 small"><b>Participação DOI: </b>${participacaoDoi}%</div>` : ''}
                     ${htmlRepresentante}
                     ${envolvido?.indicadorPessoalVersao?.falecido && envolvido.inventariante ? `<div class="w-100 small"><b>Inventariante: </b>${inventariante ? inventariante : envolvido.inventariante}</div>` : ''}
                     ${envolvido.consultaCnib ? `<div class="w-100 small text-teal"><b>CNIB: </b>${envolvido.consultaCnib}</div>` : ''}
                   </div>
                </li>`;

          return h;
        });
        promises.push(p);
      }
    }

    let html = await Promise.all(promises);
    return envolvidos?.length ? `<ul>${html.join('')}</ul>` : null;
  },

  async getHtmlRepresentante(representantes) {
    let html ='';
    if(representantes) {
      for (const r of representantes) {
        let label ='';
        if(r.indicadorPessoalVersaoId){
          label = await this.indicadorPessoal(r.indicadorPessoalVersaoId);
        }else{
          label = r.documento || '';
        }
        html +=`<div class="w-100 small"><b>Repres. : </b>${label}</div>`;
      }
    }
    return html;
  },

  async proprietarios(proprietarios) {
    let promises = [];
    let valoresProprietarios = Object.values(proprietarios)[0];

    if (!valoresProprietarios) return null;

    let proprietariosSimples = valoresProprietarios.map(proprietario => ({
      area: proprietario.area,
      fracao: proprietario.fracao,
      indicadorPessoalVersao: proprietario.indicadorPessoalVersao.id,
      medidaArea: proprietario.medidaArea,
      ordem: proprietario.ordem
    }));

    if (proprietariosSimples.length > 1) {
      proprietariosSimples = Utils.orderBy(proprietariosSimples, 'ordem');
    }

    for (let subProprietario of proprietariosSimples) {
      let p = this.indicadorPessoal(subProprietario.indicadorPessoalVersao).then(async (pessoa) => {
        let medidaArea = subProprietario.medidaArea ? FrontBusiness.getLabel(subProprietario.medidaArea, IndicadorRealBusiness.getMedidas()) : '';
        const h = `<li>
                  <b>${pessoa}</b><br>
                  <div class="row">
                    ${subProprietario.area ? `<div class="w-50 small"><b>${medidaArea}: </b>${subProprietario.area}</div>` : ''}
                    ${subProprietario.fracao ? `<div class="w-50 small"><b>Fração: </b>${subProprietario.fracao}%</div>` : ''}
                  </div>
                </li>`;
        return h;
      });
      promises.push(p);
    }

    let html = await Promise.all(promises);
    return proprietariosSimples.length ? `<ul>${html.join('')}</ul>` : null;
  },


  async enderecos(enderecos = [], notificacao = false) {
    let promises = [];
    enderecos = Utils.orderBy(enderecos || [], 'ordem');
    let html = '';
    for (const e of enderecos) {
      html += `<li><b>${e.cep ? e.cep + ', ' : ''}${e.logradouro || ''}${e.numero ? ', ' + e.numero : ''}${e.quadra ? ', ' + e.quadra : ''}${e.lote ? ', ' + e.lote : ''}${e.bairro ? ', ' + e.bairro : ''}${e.cidade ? ', ' + e.cidade : ''}${e.estado ? ', ' + e.estado : ''}${e.complemento ? ', ' + e.complemento : ''}</b>`;
        if(e.diligencias?.length) {
          const diligencias = Utils.orderBy(e.diligencias || [], 'ordem');
          html += '<ol>';
          for (const d of diligencias) {
            const usuarioNotificador = d.usuarioNotificador ? await this.getUsuario(d.usuarioNotificador) : null;
            const tipoResultado = await this.getEntityById(d.tipoResultado, TipoResultadoBusiness);
            html += `<li class="small px-2">
                    Tentativa realizada em ${moment(d.data).format('DD/MM/YYYY HH:mm:ss')} através de ${d.formaNotificacao === 'NOTIFICADOR' ? d.notificador || usuarioNotificador : 'Carta AR'}
                    com resultado ${tipoResultado}. ${d.detalhes || ''}
                  </li>`
          }
          html += '</ol>';
        }
        html += '</li>';
    }
    return enderecos?.length ? `<ul>${html}</ul>` : null;
  },

  async indicadorGarantias(indicadorGarantias = []) {
    let promises = [];
    for (const indicadorGarantia of indicadorGarantias) {
      let id = indicadorGarantia?.bemMovel?.id || indicadorGarantia?.bemMovel;

      let p = BemMovelBusiness.getById(id).then(async (bemMovel) => {
        const h = `<li>
                   <b>${FrontBusiness.nomearFicha(bemMovel)} - ${bemMovel.tipoBem.nome} - ${bemMovel.nome}</b><br>
                   <div class="row">
                     ${indicadorGarantia.valor ? `<div class="w-100 small"><b>Valor: </b>${Utils.formatMoney(indicadorGarantia.valor)}</div>` : ''}
                   </div>
                </li>`;

        return h;
      });

      promises.push(p);

    }

    let html = await Promise.all(promises);
    return indicadorGarantias?.length ? `<ul>${html.join('')}</ul>` : null;
  },

  async envolvidosSiscoaf(envolvidos = []) {
    let promises = [];
    for (const envolvido of (envolvidos || [])) {

      let p = await this.indicadorPessoal(envolvido?.indicadorPessoalVersao?.id || envolvido?.indicadorPessoalVersao || envolvido?.indicadorPessoalVersaoId).then(async (pessoa) => {

        return `<li>
                   <b>${pessoa}</b><br>
                   <div class="row">
                     ${envolvido.papel ? `<div class="w-50 small"><b>Papel: </b>${SiscoafBusiness.papeis().find(e => e.id === envolvido.papel)?.nome || 'Nenhum'}</div>` : ''}
                   </div>
                </li>`;

      });

      promises.push(p);

    }

    let html = await Promise.all(promises);
    return envolvidos?.length ? `<ul>${html.join('')}</ul>` : null;
  },


  async atoRetificado(ato) {
    if (!ato) return null;
    let a = await AtoBusiness.getById(null, ato);
    return AtoBusiness.getCodigo(a) + ' - ' + a.tipoServico.nome;
  },

  async recolhimentos(recolhimentos = []) {
    let html = [];
    for (const recolhimento of recolhimentos) {
      html.push(`<li>
                   <b>${recolhimento.tipo}</b><br>
                   <div class="row">
                     <div class="w-50 small"><b>Nº do Documento: </b>${recolhimento.numero}</div>
                     <div class="w-50 small"><b>Data de Emissão: </b>${moment(recolhimento.emissao).format('DD/MM/YYYY')}</div>
                     <div class="w-50 small"><b>Data do Pagamento: </b>${moment(recolhimento.pagamento).format('DD/MM/YYYY')}</div>
                     <div class="w-50 small"><b>Valor Pago: </b>${recolhimento.valorPago}</div>
                   </div>
                </li>`);

    }
    return recolhimentos?.length ? `<ul>${html.join('')}</ul>` : null;
  },

  async matriculas(matriculas = [], tipo = 'MATRICULA') {
    let html = [];
    for (const matricula of (matriculas || [])) {
      let nomeMatricula = await this.nomearFicha(matricula.id || matricula, tipo);
      const h = `<li>
                   <b>${nomeMatricula}</b>
                </li>`
      html.push(h);
    }
    return matriculas?.length ? `<ul>${html.join('')}</ul>` : null;
  },

  async protocolos(protocolos = []) {
    let html = [];
    for (const protocolo of (protocolos || [])) {
      let nomeProtocolo = await this.nomearProtocolo(protocolo.id || protocolo);
      const h = `<li>
                   <b>${nomeProtocolo}</b>
                </li>`
      html.push(h);
    }
    return protocolos?.length ? `<ul>${html.join('')}</ul>` : null;
  },

  async checklists(checklists = []) {
    let html = '';
    let listaChecklist = await ChecklistBusiness.getSimpleList();
    for (let checklist of (checklists || [])) {
      let id = checklist?.id || checklist?.checklist?.id
      let nome = checklist?.nome || checklist?.checklist?.nome
      if (!nome) {
        checklist = listaChecklist.find(c => c.id === id);
        nome = checklist?.nome;
      }
      html += `<li class="m-0 p-0 mb-2" style="list-style: none;overflow: hidden;">${nome}</li>`;
    }
    return html;
  },

  async opcoes(opcoes, dominio) {
    let html = '';
    if (Utils.isEmptyNullOrUndefined(opcoes)) {
      return '-'
    }
    if (dominio === 'PROTOCOLO_RI') {
      if (!Utils.isEmptyNullOrUndefined(opcoes?.editarMinuta)) {
        html += `<b>Permitir Edição da Minuta:</b> ${opcoes.editarMinuta ? 'Sim' : 'Não'} </br>`
      }
      if (!Utils.isEmptyNullOrUndefined(opcoes?.exibirMultiplasCobrancas)) {
        html += `<b>Permitir Múltiplas Cobranças:</b> ${opcoes.exibirMultiplasCobrancas ? 'Sim' : 'Não'} </br>`;
      }
      if (!Utils.isEmptyNullOrUndefined(opcoes?.titulo)) {
        html += `<b>Título (Natureza Formal):</b> ${opcoes.titulo} </br>`
      }
      if (!Utils.isEmptyNullOrUndefined(opcoes?.livros) && opcoes?.livros.length) {
        let livros = ''
        let lista = business.livros();
        opcoes.livros.forEach(livro => {
          livros += lista.find(l => l.id === livro)?.nome + '; ';
        })
        html += `<b>Livros:</b> ${livros} </br>`
      }
      if (!Utils.isEmptyNullOrUndefined(opcoes?.tiposDocumento) && opcoes?.tiposDocumento.length) {
        let tipos = ''
        let lista = [
          {id: 'NORMAL', nome: 'Normal / Exame e Cálculo / Orçamento'},
          {id: 'PROCESSO_INTERNO', nome: 'Processo Interno'}
        ];
        opcoes.tiposDocumento.forEach(tipo => {
          tipos += lista.find(l => l.id === tipo)?.nome + '; ';
        })
        html += `<b>Tipos de documento:</b> ${tipos} </br>`
      }
      if (!Utils.isEmptyNullOrUndefined(opcoes?.tipoAto)) {
        html += `<b>Tipo de Ato:</b> ${business.tiposAto().find(t => t.id === opcoes.tipoAto)?.nome} </br>`;
      }
      if (!Utils.isEmptyNullOrUndefined(opcoes?.prenotacao)) {
        html += `<b>Seguir Prenotação:</b> ${opcoes.prenotacao ? 'Sim' : 'Não'} </br>`;
      }
      if (!Utils.isEmptyNullOrUndefined(opcoes?.cobrarBusca)) {
        html += `<b>Cobrar Busca:</b> ${opcoes.cobrarBusca ? 'Sim' : 'Não'} </br>`;
      }
      if (!Utils.isEmptyNullOrUndefined(opcoes?.gerenciarEnvolvidos)) {
        html += `<b>Gerenciar envolvidos:</b> ${opcoes.gerenciarEnvolvidos ? 'Sim' : 'Não'} </br>`;
      }
      if (!Utils.isEmptyNullOrUndefined(opcoes?.gerenciarBensGarantias)) {
        html += `<b>Gerenciar bens e garantias:</b> ${opcoes.gerenciarBensGarantias ? 'Sim' : 'Não'} </br>`;
      }
      if (!Utils.isEmptyNullOrUndefined(opcoes?.papeisObrigatorios) && opcoes?.papeisObrigatorios.length) {
        let papeis = ''
        let lista = IndicadorRealBusiness.getPapeisEnvolvidos();
        opcoes.papeisObrigatorios.forEach(papel => {
          papeis += lista.find(l => l.id === papel)?.nome + '; ';
        })
        html += `<b>Papéis Ativos (Obrigatórios):</b> ${papeis} </br>`
      }
      if (!Utils.isEmptyNullOrUndefined(opcoes?.papeisProibidos) && opcoes?.papeisProibidos.length) {
        let papeis = ''
        let lista = IndicadorRealBusiness.getPapeisEnvolvidos();
        opcoes.papeisProibidos.forEach(papel => {
          papeis += lista.find(l => l.id === papel)?.nome + '; ';
        })
        html += `<b>Papéis Ativos (Proibidos):</b> ${papeis} </br>`
      }

      opcoes.livros = opcoes?.livros || [];

      let tiposOperacaoFicha = [
        {id: 'CANCELAMENTO', nome: 'Cancelamento'},
        {id: 'ENCERRAMENTO', nome: 'Encerramento'},
        {id: 'NENHUM', nome: 'Nenhum'},
      ];
      if (!opcoes.livros.includes('TRANSCRICAO')) {
        tiposOperacaoFicha.unshift({id: 'REATIVAR', nome: 'Reativar'});
        tiposOperacaoFicha.unshift({id: 'ABERTURA', nome: 'Abertura'});
      }

      if (!(opcoes.livros.includes('REGISTRO_AUXILIAR') && opcoes.livros.length === 1) && opcoes.livros.length > 0) {
        if (!Utils.isEmptyNullOrUndefined(opcoes?.alterarProprietarios)) {
          html += `<b>Alterar proprietário:</b> ${opcoes.alterarProprietarios ? 'Sim' : 'Não'} </br>`;
        }
        if (!Utils.isEmptyNullOrUndefined(opcoes?.editarIndicadorReal)) {
          html += `<b>Editar Indicador Real:</b> ${opcoes.editarIndicadorReal ? 'Sim' : 'Não'} </br>`;
        }
        if (!Utils.isEmptyNullOrUndefined(opcoes?.exibirBaseCalculo)) {
          html += `<b>Informar valores base de cálculo:</b> ${opcoes.exibirBaseCalculo ? 'Sim' : 'Não'} </br>`;
        }
        if (!Utils.isEmptyNullOrUndefined(opcoes?.camposBaseCalculo) && opcoes?.camposBaseCalculo.length) {
          let bases = ''
          let lista = business.camposBaseCalculo(dominio);
          opcoes.camposBaseCalculo.forEach(base => {
            bases += lista.find(l => l.id === base)?.nome + '; ';
          })
          html += `<b>Base Cálculo:</b> ${bases} </br>`
        }

        if (!Utils.isEmptyNullOrUndefined(opcoes?.operacaoIndicadorReal)) {
          html += `<b>Operação da Ficha (Indicador Real):</b> ${(tiposOperacaoFicha || []).find(t => t.id === opcoes.operacaoIndicadorReal)?.nome} </br>`;
        }
        if (!Utils.isEmptyNullOrUndefined(opcoes?.operacaoCoaf)) {
          html += `<b>Operação Siscoaf:</b> ${business.operacoesCoaf().find(t => t.id === opcoes.operacaoCoaf)?.nome} </br>`;
        }
        if (!Utils.isEmptyNullOrUndefined(opcoes?.tipoTransacaoDoi)) {
          html += `<b>Operação DOI:</b> ${business.tiposDoi(true).find(t => t.id === opcoes.tipoTransacaoDoi)?.nome} </br>`;
        }
        if (!Utils.isEmptyNullOrUndefined(opcoes?.doiDescricao) && opcoes.tipoTransacaoDoi === 'OUTROS') {
          html += `<b>Descrição DOI:</b> ${opcoes.doiDescricao} </br>`
        }
        if (opcoes?.permitirEnvolvidoSemDocumento !== null && opcoes.tipoTransacaoDoi !== 'NENHUM') {
          html += `<b>Permitir registro sem número de CPF ou representante do envolvido:</b> ${opcoes.permitirEnvolvidoSemDocumento ? 'Sim' : 'Não'} </br>`;
        }
      }

      if (opcoes.livros.includes('REGISTRO_AUXILIAR')) {
        if (!Utils.isEmptyNullOrUndefined(opcoes?.tipoRegistro)) {
          html += `<b>Tipo de Registro Auxiliar:</b> ${business.tiposRegistro().find(t => t.id === opcoes.tipoRegistro)?.nome} </br>`;
        }
        if (!Utils.isEmptyNullOrUndefined(opcoes?.operacaoRegistroAuxiliar)) {
          html += `<b>Operação da Ficha (Registro Auxiliar):</b> ${(tiposOperacaoFicha || []).find(t => t.id === opcoes?.operacaoRegistroAuxiliar)?.nome} </br>`;
        }
        if (!Utils.isEmptyNullOrUndefined(opcoes?.editarRegistroAuxiliar)) {
          html += `<b>Editar Registro Auxiliar:</b> ${opcoes.editarRegistroAuxiliar ? 'Sim' : 'Não'} </br>`;
        }
      }
      if (!Utils.isEmptyNullOrUndefined(opcoes?.restricaoRestritiva)) {
        html += `<b>Permitir Restrição Restritiva:</b> ${opcoes.restricaoRestritiva ? 'Sim' : 'Não'} </br>`;
      }
      if (!Utils.isEmptyNullOrUndefined(opcoes?.restricaoOpcional)) {
        html += `<b>Criar restrição de forma opcional:</b> ${opcoes.restricaoOpcional ? 'Sim' : 'Não'} </br>`;
      }
      if (!Utils.isEmptyNullOrUndefined(opcoes?.mostrarVencimentoRestricao)) {
        html += `<b>Configurar Vencimento da Restrição:</b> ${opcoes.mostrarVencimentoRestricao ? 'Sim' : 'Não'} </br>`;
      }
      let lista = business.restricoes();
      if (!Utils.isEmptyNullOrUndefined(opcoes?.criarRestricao) && opcoes?.criarRestricao.length) {
        let restricoes = ''
        opcoes.criarRestricao.forEach(restricao => {
          restricoes += lista.find(l => l.id === restricao)?.nome + '; ';
        })
        html += `<b>Criar Restrições:</b> ${restricoes} </br>`
      }
      if (!Utils.isEmptyNullOrUndefined(opcoes?.cancelarRestricao) && opcoes?.cancelarRestricao.length) {
        let restricoes = ''
        opcoes.cancelarRestricao.forEach(restricao => {
          restricoes += lista.find(l => l.id === restricao)?.nome + '; ';
        })
        html += `<b>Cancelar Restrições:</b> ${restricoes} </br>`
      }
    }
    let layoutImpressao = await LayoutImpressaoBusiness.getAll();
    if (DominioBusiness.isPedidoCertidao(dominio)) {
      if (!Utils.isEmptyNullOrUndefined(opcoes?.tipoCertidao)) {
        html += `<b>Tipo de Certidão:</b> ${business.tiposCertidao().find(t => t.id === opcoes.tipoCertidao)?.nome} </br>`;
      }
      html += `<b>Livro:</b> ${business.livros().find(t => t.id === opcoes.livro)?.nome || 'Não Vincular'} </br>`;
      if (!Utils.isEmptyNullOrUndefined(opcoes?.prenotacao)) {
        html += `<b>Seguir Prenotação:</b> ${opcoes.prenotacao ? 'Sim' : 'Não'} </br>`;
      }
      if (!Utils.isEmptyNullOrUndefined(opcoes?.layout)) {
        html += `<b>Layout de Impressão:</b> ${(layoutImpressao.filter(l => l.tipo === 'CERTIDAO')).find(t => t.id === opcoes.layout)?.nome || '-'} </br>`;
      }
    }

    if (dominio === 'OFICIO') {
      if (!Utils.isEmptyNullOrUndefined(opcoes?.prazo)) {
        html += `<b>Prazo:</b> ${opcoes.prazo} </br>`
      }
      if (!Utils.isEmptyNullOrUndefined(opcoes?.layoutImpressao)) {
        html += `<b>Layout de Impressão:</b> ${(layoutImpressao.filter(l => l.tipo === 'OFICIO')).find(t => t.id === opcoes.layoutImpressao)?.nome || '-'} </br>`;
      }
      if (!Utils.isEmptyNullOrUndefined(opcoes?.diasCorridos)) {
        html += `<b>Usar dias corridos:</b> ${opcoes.diasCorridos ? 'Sim' : 'Não'} </br>`;
      }
      if (!Utils.isEmptyNullOrUndefined(opcoes?.hoje)) {
        html += `<b>Calcular a partir da entrada:</b> ${opcoes.hoje ? 'Sim' : 'Não'} </br>`;
      }
    }

    if (dominio === 'NOTIFICACAO') {
      if (!Utils.isEmptyNullOrUndefined(opcoes?.layoutImpressao)) {
        html += `<b>Layout de Impressão:</b> ${(layoutImpressao.filter(l => l.tipo === 'NOTIFICACAO')).find(t => t.id === opcoes.layoutImpressao)?.nome || '-'} </br>`;
      }
    }

    if (dominio === 'PERSONALIZADO') {
      if (!Utils.isEmptyNullOrUndefined(opcoes?.vincularInteressadoSolicitante)) {
        html += `<b>Permitir vincular “Interessado” e “Solicitante”?:</b> ${opcoes.vincularInteressadoSolicitante ? 'Sim' : 'Não'} </br>`;
      }
    }

    return html || '-';
  },

  async tempoParado(opcoes = {}) {
    let html = '';
    if (Utils.isEmptyNullOrUndefined(opcoes)) {
      return '-'
    }
    if (opcoes?.tipo) {
      html += `<b>Alerta para o tempo parado de protocolos com prioridade urgente:</b> ${([
        {id: 'DIAS', nome: 'Dias'},
        {id: 'HORAS', nome: 'Horas'},
      ]).find(t => t.id === opcoes.tipo)?.nome} </br>`;
      if (opcoes?.tipo === 'DIAS') {
        if (opcoes?.dias) {
          html += `<b>Quantidade de Dias:</b> ${opcoes.dias} </br>`
        }
      } else {
        if (!Utils.isEmptyNullOrUndefined(opcoes?.horas)) {
          html += `<b>Quantidade de Horas:</b> ${opcoes.horas} </br>`
        }
        if (!Utils.isEmptyNullOrUndefined(opcoes?.permanencia)) {
          html += `<b>Permanência em horas úteis:</b> ${opcoes.permanencia ? 'Sim' : 'Não'} </br>`;
        }
      }
    }
    return html;
  },

  formatBoolean(data) {
    return data ? 'Sim' : 'Não'
  },

  formatId(data, lista = []) {
    if (!data) {
      return '-'
    }
    let item = lista.find(t => t.id === data)
    return item?.nome || '-'
  },

  async cartorio (data = {}, tipo, listas = {}) {
    let html = '';
    if (Utils.isEmptyNullOrUndefined(data)) {
      return '-'
    }
    switch (tipo) {
      case 'detalhesCartorio':
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Dados do cartório</h3>`
        html += `<b>Nome da Serventia (Usado para livros e recibos):</b> ${data.nomeServentia || '-'} </br>`;
        html += `<b>Nome do Oficial:</b> ${data.nome || '-'} </br>`;
        html += `<b>Substituto(s):</b> ${data.substitutos || '-'} </br>`;
        html += `<b>Nome Fantasia:</b> ${data.nomeFantasia || '-'} </br>`;
        html += `<b>CNPJ:</b> ${data.cnpj || '-'} </br>`;
        html += `<b>CPF:</b> ${data.cpf || '-'} </br>`;
        html += `<b>CNS:</b> ${data.cns || '-'} </br>`;
        html += `<b>Código do Município:</b> ${data.codigoMunicipio || '-'} </br>`;
        html += `<b>Inscrição municipal:</b> ${data.inscricaoMunicipal || '-'} </br>`;
        html += `<b>Horário (Início):</b> ${data.horarioInicio ? data.horarioInicio : '-'} </br>`;
        html += `<b>Horário (Fim):</b> ${data.horarioFim ? data.horarioFim :  '-'} </br>`;
        /* ocultar da auditoria
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Certificado A1</h3>`
        html += `${data.certificadoA1 ? "Certificado A1" : '-'}  </br>`;
        if (data.certificadoA1) html += `Senha: ${data.certificadoSenha || '-'}  </br>`;
        */
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Siscoaf</h3>`
        html += `<b>CPF/CNPJ Pessoa Obrigada:</b> ${data.pessoaObrigadaCoaf || '-'} </br>`;
        html += `<b>Quantidade de dias entre as transações:</b> ${data.analiseSiscoaf.diasTransacoes || '-'} </br>`;
        html += `<b>Porcentagem de diferença da alienação:</b> ${data.analiseSiscoaf.porcentagemAlienacao || '-'} </br>`;
        if (data.horarioFuncionamento) {
          html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Horário de funcionamento</h3>`;
          html += `<div class="pl-2">`;
          (data.horarioFuncionamento || {}).forEach(dia => {
            html += `${dia.label}:`;
            if (dia.ativo) {
              if (dia.intervaloAtivo) {
                html += ` Das ${dia.inicio} às ${dia.intervaloInicio} e das ${dia.intervaloFim} às ${dia.fim}`;
              } else {
                html += ` Das ${dia.inicio} às ${dia.fim}`;
              }
            } else {
              html += ` - `;
            }
            html += `</br>`;
          })
          html += `</div>`;
        }
        break;
      case 'email':
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Configuração de envio de email</h3>`
        html += `<b>E-mail:</b> ${data.login || '-'} </br>`
        //html += `<b>Senha:</b> ${data.senha || '-'} </br>`
        html += `<b>Porta:</b> ${data.porta || '-'} </br>`
        html += `<b>Servidor:</b> ${data.servidor || '-'} </br>`
        html += `<b>Usar SSL/TLS:</b> ${this.formatBoolean(data.usarSslTls)} </br>`;
        html += `<b>E-mail resposta:</b> ${data.emailResposta || '-'} </br>`
        html += `<b>E-mail cópia:</b> ${data.emailCopia || '-'} </br>`
        html += `<b>Enviar e-mail no cadastro do protocolo:</b> ${this.formatBoolean(data.enviarEmailProtocolo)} </br>`;
        break;
      case 'selo':
        if(VueBusiness.getVueRoot().isEstadoSC || VueBusiness.getVueRoot().isEstadoMG){
          html += `<b>Usuário:</b> ${data.usuario || '-'} </br>`
        }
        if(VueBusiness.getVueRoot().isEstadoMG){
          html += `<b>Código do Cartório:</b> ${data.hash || '-'} </br>`
        }
        if(VueBusiness.getVueRoot().isEstadoGO){
          html += `<b>E-mail:</b> ${data.email || '-'} </br>`
          html += `<b>Hash do Cartório:</b> ${data.hash || '-'} </br>`
          html += `<b>Selos por Lote:</b> ${data.lote || '-'} </br>`
          html += `<b>Ativar Redimensionamento Automático:</b> ${this.formatBoolean(data.redimensionamentoAutomatico)} </br>`;
        }

        html += `<b>Ativar Cron de Selagem:</b> ${this.formatBoolean(data.ativo)} </br>`;
        html += `<b>Pré-Validar Selos:</b> ${this.formatBoolean(data.validar)} </br>`;
        html += `<b>Delay do envio automático (min):</b> ${data.delayEnvio} </br>`;

        if(VueBusiness.getVueRoot().isEstadoSC || VueBusiness.getVueRoot().isEstadoPE || VueBusiness.getVueRoot().isEstadoMG){
          html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Alertas de Estoque</h3>`
        }

        if(VueBusiness.getVueRoot().isEstadoSC){
          html += `<b>Selo Isento (Estoque Mínimo):</b> ${data.alertaEstoque?.isento || '-'} </br>`
          html += `<b>Selo Normal (Estoque Mínimo):</b> ${data.alertaEstoque?.normal || '-'} </br>`
        }

        if(VueBusiness.getVueRoot().isEstadoPE || VueBusiness.getVueRoot().isEstadoMG){
          html += `<b>Selo (Estoque Mínimo):</b> ${data.alertaEstoque?.geral || '-'} </br>`
        }
        break;
      case 'indisponibilidade':
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Integração</h3>`
        html += `<b>Token:</b> ${data.token || '-'} </br>`
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Importação XML</h3>`
        html += `<b>Tipo de protocolo:</b> ${this.formatId(data.tipoProtocolo, ProtocoloBusiness.getTiposProtocolo())} </br>`
        html += `<b>Natureza:</b> ${this.formatId(data.natureza, listas?.naturezas)} </br>`
        let tiposRegistro = [
          {id : 'NORMAL', nome : 'Normal'},
          {id : 'PAGAMENTO_POSTERIOR', nome : 'Pagamento Posterior'},
          {id : 'ISENTO', nome : 'Prenotação Isenta'},
        ];
        html += `<b>Prenotação Inclusão:</b> ${this.formatId(data.tipoRegistroInclusao, tiposRegistro)} </br>`
        html += `<b>Prenotação Cancelamento:</b> ${this.formatId(data.tipoRegistroCancelamento, tiposRegistro)} </br>`
        html += `<b>Ato Inclusão:</b> ${this.formatId(data.tipoServico, listas?.tipoServico)} </br>`
        html += `<b>Ato Cancelamento:</b> ${this.formatId(data.cancelamento, listas?.tipoServico)} </br>`
        html += `<b>Separar Envolvidos por Ato:</b> ${this.formatBoolean(data.separarEnvolvidosPorAto)} </br>`;
        html += `<b>Consultar indicadores pela raiz do CNPJ:</b> ${this.formatBoolean(data.consultarIndicadoresRaizCNPJ)} </br>`;
        html += `<b>Consultar Restrições: </b> <br>${business.formatAuditoriaRestricoes(data)} </br>`;
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Configuração para Gestores</h3>`
        html += `<b>Usuário gestor deve solicitar permissão para registro:</b> ${this.formatBoolean(data.registroIndisponibilidadeGestor)} </br>`;
        break;
      case 'notaFiscal':
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">ISS</h3>`
        html += `<b>ISS (%):</b> ${data.iss != null ? Utils.formatMoney(data.iss) : '-'} </br>`
        html += `<b>ISS (Emolumento Bruto):</b> ${this.formatBoolean(data.issEmolumentosBruto)}} </br>`
        html += `<b>Repassar ISS:</b> ${this.formatBoolean(data.repassarIss)} </br>`;
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Nota Fiscal</h3>`
        html += `<b>Módulo de Nota Fiscal Ativo:</b> ${this.formatBoolean(data.habilitada)} </br>`;
        if (data.habilitada) {
          html += `<b>Código Atividade Econômica:</b> ${data.codigoAtividadeEconomica || '-'} </br>`
          html += `<b>Natureza de Operação:</b> ${this.formatId(data.naturezaOperacao, NotaFiscalBusiness.getNaturezasDeOperacao())} </br>`
          html += `<b>ISS (%):</b> ${data.iss != null ? Utils.formatMoney(data.iss) : '-'} </br>`
          html += `<b>Repassar ISS:</b> ${this.formatBoolean(data.repassarIss)} </br>`;
          html += `<b>Serie RPS:</b> ${data.serieRps || '-'} </br>`
          html += `<b>Simples Nacional:</b> ${this.formatBoolean(data.simplesNacional)} </br>`
          html += `<b>Incentivador Cultural:</b> ${this.formatBoolean(data.incentivadorCultural)} </br>`
          html += `<b>Código CNAE:</b> ${data.codigoCnae || '-'} </br>`
          html += `<b>Código do Item:</b> ${data.codigoItem || '-'} </br>`
          html += `<b>Descrição do Item:</b> ${data.descricaoItem || '-'} </br>`
          html += `<b>Chave de Parceiro (Invoicy):</b> ${data.chaveParceiro || '-'} </br>`
          html += `<b>Chave de Acesso (Invoicy):</b> ${data.chaveAcesso || '-'} </br>`
          html += `<b>Quantidade de dias para geração:</b> ${data.quantidadeDiasGeracao || '-'} </br>`
          html += `<b>Ativar Cron de Nota Fiscal:</b> ${this.formatBoolean(data.cronNotaFiscalAtiva)} </br>`;
        }
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">E-mail</h3>`
        html += `<b>Modelo de E-mail:</b> ${data.email ? `<div class="form-group"><div class="input-group"><textarea class="form-control form-control-xs" style="height: 180px" readonly>${data.email}</textarea></div></div>` : '-'} </br>`
        break;
      case 'exigencia':
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Configuração de exigência</h3>`
        html += `<b>Enviar e-mail automático:</b> ${this.formatBoolean(data.enviarEmailAutomatico)} </br>`;
        html += `<b>Exibição no Acompanhamento Online:</b> ${this.formatId(data.acompanhamentoOnline, listas.acompanhamentoOnline)}</br>`;
        html += `<b>Template PDF:</b> ${data.templatePdf ? `<div class="form-group"><div class="input-group"><textarea class="form-control form-control-xs" style="height: 180px" readonly>${data.templatePdf}</textarea></div></div>` : '-'} </br>`
        html += `<b>Template E-mail:</b> ${data.templateEmail ? `<div class="form-group"><div class="input-group"><textarea class="form-control form-control-xs" style="height: 180px" readonly>${data.templateEmail}</textarea></div></div>` : '-'} </br>`
        html += `<b>Permitir Editar Exigência Concluída:</b> ${this.formatBoolean(data.permitirEditar)} </br>`;
        html += `<b>Permitir Editar Exigência no Checklist:</b> ${this.formatBoolean(data.permitirEditarChecklist)} </br>`;
        break;
      case 'site':
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Informações Entregues</h3>`;
        html += `<b>Tag dos Anexos:</b> ${data.tagAnexo || '-'} </br>`;
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Validação de Assinatura</h3>`;
        html += `<b>Incluir validação nas certidões assinadas:</b>
                 ${data.validacaoCertidao ? `<div class="form-group"><div class="input-group">
                                               <textarea class="form-control form-control-xs" style="height: 180px" readonly>${data.validacaoCertidao}</textarea>
                                            </div></div>` : '-'} </br>`;
        if (VueBusiness.getVueRoot().isCartorio1RIGO) {
          html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">E-certidão</h3>`;
          html += `<div class="pl-2">`;
          html += `<b>Pedido de Certidão:</b> ${this.formatId(data.pedidoCertidao, listas?.tipoServicoCertidao)} </br>`
          html += `<b>Atendente:</b> ${this.formatId(data.atendente, listas?.usuarios)} </br>`
          html += `<b>Etapa Inicial:</b> ${this.formatId(data.etapa, listas?.etapas)} </br>`
          html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Etapas</h3>`
          html += `<b>Aguardando Aprovação:</b> ${this.formatId(data.aguardandoAprovacao, listas?.etapas)} </br>`
          html += `<b>Orçamento Aprovado:</b> ${this.formatId(data.aprovado, listas?.etapas)} </br>`
          html += `<b>Orçamento Recusado:</b> ${this.formatId(data.recusado, listas?.etapas)} </br>`
          html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Tipo de Certidão</h3>`
          let certidoesSite = SiteBusiness.tiposVinculo();
          (data.tipoCertidao || []).map((m) => {
            html += `<p style="white-space: pre-line;"><b>${m.titulo} (${this.formatId(m.tipoVinculo, certidoesSite)}):</b> ${this.formatId(m.tipoServico, listas?.tipoServicoCertidao)}  </p>`
          })
          html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Templates</h3>`
          html += `<b>Cadastro:</b> ${data.templateRecibo ? `<div class="form-group"><div class="input-group"><textarea class="form-control form-control-xs" style="height: 180px" readonly>${data.templateRecibo}</textarea></div></div>` : '-'} </br>`
          html += `<b>Nova Senha:</b> ${data.templateNovaSenha ? `<div class="form-group"><div class="input-group"><textarea class="form-control form-control-xs" style="height: 180px" readonly>${data.templateNovaSenha}</textarea></div></div>` : '-'} </br>`
          html += `<b>Nova Resposta:</b> ${data.templateNovaResposta ? `<div class="form-group"><div class="input-group"><textarea class="form-control form-control-xs" style="height: 180px" readonly>${data.templateNovaResposta}</textarea></div></div>` : '-'} </br>`
          html += `<b>Andamentos:</b> ${data.templateMovimentacao ? `<div class="form-group"><div class="input-group"><textarea class="form-control form-control-xs" style="height: 180px" readonly>${data.templateMovimentacao}</textarea></div></div>` : '-'} </br>`
          html += `</div>`;
        } else {
          html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">E-certidão</h3>`;
          html += `<div class="pl-2">`;
          html += `<b>Ativar Pedido Online:</b> ${this.formatBoolean(data.ativarPedidoOnline)} </br>`
          if (data.ativarPedidoOnline) {
            html += `<b>Pedido de Certidão:</b> ${this.formatId(data.pedidoCertidao, listas?.tipoServicoCertidao)} </br>`
            html += `<b>Atendente:</b> ${this.formatId(data.atendente, listas?.usuarios)} </br>`
            html += `<b>Etapa Inicial:</b> ${this.formatId(data.etapa, listas?.etapas)} </br>`
            html += `<b>Formas de Pagamento:</b> ${this.formatId(data.formasPagamentoUUID, listas?.listas)} </br>`
            html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Tipo de Certidão</h3>`
            let certidoesSite = SiteBusiness.tiposVinculo();
            (data.tipoCertidao || []).map((m) => {
              html += `<p style="white-space: pre-line;"><b>${m.titulo} (${this.formatId(m.tipoVinculo, certidoesSite)}):</b> ${this.formatId(m.tipoServico, listas?.tipoServicoCertidao)}  </p>`
            })
            html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Templates</h3>`
            html += `<b>Cadastro:</b> ${data.templateRecibo ? `<div class="form-group"><div class="input-group"><textarea class="form-control form-control-xs" style="height: 180px" readonly>${data.templateRecibo}</textarea></div></div>` : '-'} </br>`
            html += `<b>Nova Senha:</b> ${data.templateNovaSenha ? `<div class="form-group"><div class="input-group"><textarea class="form-control form-control-xs" style="height: 180px" readonly>${data.templateNovaSenha}</textarea></div></div>` : '-'} </br>`
            html += `<b>Nova Resposta:</b> ${data.templateNovaResposta ? `<div class="form-group"><div class="input-group"><textarea class="form-control form-control-xs" style="height: 180px" readonly>${data.templateNovaResposta}</textarea></div></div>` : '-'} </br>`
            html += `<b>Andamentos:</b> ${data.templateMovimentacao ? `<div class="form-group"><div class="input-group"><textarea class="form-control form-control-xs" style="height: 180px" readonly>${data.templateMovimentacao}</textarea></div></div>` : '-'} </br>`
          }
          html += `</div>`;
        }
        break;
      case 'sinter':
        html += `<b>CNS:</b> ${data.cns || '-'} </br>`
        html += `<b>CPF:</b> ${data.cpf || '-'} </br>`
        html += `<b>Hash do Cartório:</b> ${data.hash || '-'} </br>`
        break;
      case 'corisc':
        if(VueBusiness.getVueRoot().isEstadoSC){
          html += `<b>Ativar integração:</b> ${this.formatBoolean(data.ativar)} </br>`
          html += `<b>Bloquear URL em 3 dias":</b> ${this.formatBoolean(data.timeoutUrl)} </br>`
          html += `<b>Login:</b> ${data.login || '-'} </br>`
          html += `<b>Client Id:</b> ${data.clientId || '-'} </br>`
          html += `<b>Client Secret:</b> ${data.clienteSecret || '-'} </br>`
        }
        break;
      case 'centralEstadual':
        if(VueBusiness.getVueRoot().isEstadoMG){
          html += `<b>Ativar integração:</b> ${this.formatBoolean(data.ativar)} </br>`;
          html += `<b>Identificação:</b> ${data.identificacao || '-'} </br>`;
          html += `<b>Login:</b> ${data.login || '-'} </br>`;
          html += `<b>Etapa Inicial (Certidão):</b> ${this.formatId(data.etapaCertidao, listas?.etapas)} </br>`;
          html += `<b>Certidão Principal:</b> ${this.formatId(data.tipoCertidao, listas?.tipoServicoCertidao)} </br>`;
        }

        break;
      case 'arisp':
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">WS Ofício (E-protocolo & E-certidão)</h3>`
        html += `<b>Utilizar Assinador ONR:</b> ${this.formatBoolean(data.usarAssinador)} </br>`;
        html += `<b>Código:</b> ${data.codigo || '-'} </br>`
        html += `<b>Chave de Integração:</b> ${data.chave || '-'} </br>`
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">WS BDL e WS Matrícula</h3>`
        html += `<b>Token de Integração WS BDL:</b> ${data.tokenWs || '-'} </br>`
        html += `<b>Token de Integração WS Imagem:</b> ${data.tokenWsImagem || '-'} </br>`
        html += `<b>Enviar transcrição? (BDL - Indicadores ONR):</b> ${this.formatBoolean(data.enviarTranscricao)} </br>`;
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">E-protocolo</h3>`
        html += `<b>Enviar número de prenotação do protocolo para ONR:</b> ${this.formatBoolean(data.ativarPrenotacaoProtocolo)} </br>`;
        html += `<b>Importar apenas protocolos da ONR que utilizam XML estruturado:</b> ${this.formatBoolean(data.apenasProtocoloPeer)} </br>`;
        html += `<div class="pl-2">`;
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Etapas E-protocolo</h3>`
        html += `<b>Etapa Inicial:</b> ${this.formatId(data.eprotocoloInicial, listas?.etapas)} </br>`
        html += `<b>Aguardando Pagamento:</b> ${this.formatId(data.eprotocoloOrcamento, listas?.etapas)} </br>`
        html += `<b>Retorno Exigência:</b> ${this.formatId(data.eprotocoloRetorno, listas?.etapas)} </br>`
        html += `<b>Retorno Pagamento:</b> ${this.formatId(data.eprotocoloPago, listas?.etapas)} </br>`
        html += `</div>`;
        let arisp = ConfiguracaoBusiness.getContratoArisp();
        if (!data.apenasProtocoloPeer) {
          (arisp.tipoDocumento || []).forEach(e => {
            html += `<div class="pl-2">`;
            html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Documento: ${e.nome}</h3>`
            html += `<b>Ato Principal:</b> ${this.formatId(data.atos?.[e.id], listas?.tipoServico)} </br>`
            html += `<b>Natureza:</b> ${this.formatId(data.naturezas?.[e.id], listas?.naturezas)} </br>`
            html += `</div>`;
          })
        }
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">E-certidão</h3>`
        html += `<div class="pl-2">`;
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Etapas E-certidão</h3>`
        html += `<b>Etapa Inicial:</b> ${this.formatId(data.ecertidaoInicial, listas?.etapas)} </br>`
        html += `<b>Aguardando Pagamento:</b> ${this.formatId(data.ecertidaoOrcamento, listas?.etapas)} </br>`
        html += `<b>Retorno do Pagamento:</b> ${this.formatId(data.ecertidaoRetorno, listas?.etapas)} </br>`
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Tipos de Certidão</h3>`;
        (arisp.certidoesArisp || []).forEach(e => {
          html += `<b>${e.nome}:</b> ${this.formatId(data.certidoes?.[e.id], listas?.tipoServicoCertidao)} </br>`;
        })
        html += `</div>`;
        break;
      case 'atendimento':
        let tiposProtocolo = [
          {id: 'NORMAL', nome: 'Normal'},
          {id: 'ORCAMENTO', nome: 'Orçamento'}
        ]
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Cadastros</h3>`;
        html += `<b>Tipo Padrão (Protocolo):</b> ${this.formatId(data.tipoCadastroProtocolo, tiposProtocolo)} </br>`;
        html += `<b>Tipo Padrão (Pedido de Certidão):</b> ${this.formatId(data.tipoCadastroPedidoCertidao, tiposProtocolo)} </br>`
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Atendimento</h3>`;
        html += `<b>Horário:</b> ${data.horario || '-'} </br>`
        html += `<b>Telefone:</b> ${data.telefone || '-'} </br>`
        html += `<b>Impressão Remota:</b> ${this.formatBoolean(data.impressaoRemota)} </br>`;
        html += `<b>Liberar senha expressa:</b> ${this.formatBoolean(data.senhaExpressa)} </br>`;
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Solicitação de Senha</h3>`;
        html += `<b>Permitir a inclusão de nome do solicitante:</b> ${this.formatBoolean(data.inclusaoNomeSolicitante)} </br>`;
        html += `<b>Será obrigatória a inclusão do nome do solicitante:</b> ${this.formatBoolean(data.obrigatoriedadeNomeSolicitante)} </br>`;
        html += `<b>Exibir a mensagem de confirmação de geração da senha:</b> ${this.formatBoolean(data.exibirMensagemSenha)} </br>`;
        html += `<b>Duração da Mensagem (Segs):</b> ${data.duracaoMensagemSucesso} </br>`;
        html += `<b>Imprimir o comprovante da senha:</b> ${this.formatBoolean(data.imprimirComprovanteSenha)} </br>`;
        html += `<b>Exibir cursor do mouse na solicitação de senhas:</b> ${this.formatBoolean(data.exibirCursor)} </br>`;
        html += `<b>Cabeçalho da Recepção:</b> ${data.cabecalhoRecepcao ? `<div class="form-group"><div class="input-group"><textarea class="form-control form-control-xs" style="height: 180px" readonly>${data.cabecalhoRecepcao}</textarea></div></div>` : '-'} </br>`
        html += `<b>Cabeçalho da Senha:</b> ${data.cabecalhoSenha ? `<div class="form-group"><div class="input-group"><textarea class="form-control form-control-xs" style="height: 180px" readonly>${data.cabecalhoSenha}</textarea></div></div>` : '-'} </br>`
        html += `<b>Rodapé da Senha:</b> ${data.rodapeSenha ? `<div class="form-group"><div class="input-group"><textarea class="form-control form-control-xs" style="height: 180px" readonly>${data.rodapeSenha}</textarea></div></div>` : '-'} </br>`
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Painel de Atendimento</h3>`;
        html += `<div style="display: flex; gap: 10px; align-items: center;"><b>Cor Principal:</b> ${data.corPrincipal ? '<div style="background: '+data.corPrincipal+'; width: 100px; height: 20px;" ></div>' : '-'}</div>`;
        html += `<b>Mostrar Últimas Senhas:</b> ${this.formatBoolean(data.mostrarUltimasSenhas)} </br>`;
        html += `<b>Mostrar as senhas em espera:</b> ${this.formatBoolean(data.mostrarSenhaEspera)} </br>`;
        html += `<b>Exibir horário de emissão da senha:</b> ${this.formatBoolean(data.exibirHorarioSenha)} </br>`;
        html += `<div style="display: flex; gap: 10px; align-items: center;"><b>Cor Senha Priotitária:</b> ${data.corSenhaPrioritaria ? '<div style="background: '+data.corSenhaPrioritaria+'; width: 100px; height: 20px;" ></div>' : '-'}</div>`;
        html += `<b>Falar Senha:</b> ${this.formatBoolean(data.falarSenha)} </br>`;
        html += `<b>Mostrar Video:</b> ${this.formatBoolean(data.mostrarVideo)} </br>`;
        if (data.mostrarVideo) {
          html += `<b>URL:</b> ${data.url || '-'} </br>`
          html += `<b>Reload Video (Segs):</b> ${data.refreshVideo || '-'} </br>`
        }
        html += `<b>Largura da Senha Atual:</b> ${data.larguraSenhaAtual || '-'} </br>`
        html += `<b>Fonte - Senha Atual:</b> ${data.fontSenhaAtual || '-'} </br>`
        if (data.mostrarUltimasSenhas) {
          html += `<b>Altura da Barra Inferior:</b> ${data.alturaBarraInferior || '-'} </br>`
        }
        break;
      case 'debito':
        html += `<b>Ativar meio de cobrança:</b> ${this.formatBoolean(data.ativarMeioDeCobranca)} </br>`;
        html += `<b>Conta/caixa:</b> ${this.formatId(data.caixa, listas?.caixas)} </br>`
        html += `<b>Taxa (%):</b> ${data.taxa != null ? Utils.formatMoney(data.taxa) : '-'} </br>`
        html += `<b>Repassar taxa:</b> ${this.formatBoolean(data.repassarTaxa)} </br>`;
        html += `<b>Calcular Valor Bruto:</b> ${this.formatBoolean(data.calcularValorBruto)} </br>`;
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Descrição da Máquina</h3>`;
        html += `<b>Descrição:</b> ${data.descricao || '-'} </br>`;
        html += `<b>Calcular Valor Bruto:</b> ${this.formatBoolean(data.calcularValorBruto)} </br>`;
        html += `<b>Máquina Ativa:</b> ${this.formatBoolean(data.ativo)} </br>`;
        html += `<b>Parcela:</b> ${data.quantidade || '-'} </br>`;
        html += `<b>Taxa (%):</b> ${data.taxa != null ? Utils.formatMoney(data.taxa) : '-'} </br>`
        break;
      case 'credito':
        html += `<b>Ativar meio de cobrança:</b> ${this.formatBoolean(data.ativarMeioDeCobranca)} </br>`;
        html += `<b>Conta/caixa:</b> ${this.formatId(data.caixa, listas?.caixas)} </br>`
        html += `<b>Taxa (%):</b> ${data.taxa != null ? Utils.formatMoney(data.taxa) : '-'} </br>`
        html += `<b>Repassar taxa:</b> ${this.formatBoolean(data.repassarTaxa)} </br>`;
        html += `<b>Calcular Valor Bruto:</b> ${this.formatBoolean(data.calcularValorBruto)} </br>`;
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Descrição da Máquina</h3>`;
        html += `<b>Descrição:</b> ${data.descricao || '-'} </br>`;
        html += `<b>Calcular Valor Bruto:</b> ${this.formatBoolean(data.calcularValorBruto)} </br>`;
        html += `<b>Máquina Ativa:</b> ${this.formatBoolean(data.ativo)} </br>`;
        html += `<b>Parcela:</b> ${data.quantidade || '-'} </br>`;
        html += `<b>Taxa (%):</b> ${data.taxa != null ? Utils.formatMoney(data.taxa) : '-'} </br>`
        break;
      case 'deposito':
        html += `<b>Ativar meio de cobrança:</b> ${this.formatBoolean(data.ativarMeioDeCobranca)} </br>`;
        html += `<b>Conta/caixa:</b> ${this.formatId(data.caixa, listas?.caixas)} </br>`
        break;
      case 'pix':
        html += `<b>Ativar meio de cobrança:</b> ${this.formatBoolean(data.ativarMeioDeCobranca)} </br>`;
        html += `<b>Conta/caixa:</b> ${this.formatId(data.caixa, listas?.caixas)} </br>`
        break;
      case 'dinheiro':
        html += `<b>Ativar meio de cobrança:</b> ${this.formatBoolean(data.ativarMeioDeCobranca)} </br>`;
        html += `<b>Conta/caixa:</b> ${this.formatId(data.caixa, listas?.caixas)} </br>`
        break;
      case 'cheque':
        html += `<b>Ativar meio de cobrança:</b> ${this.formatBoolean(data.ativarMeioDeCobranca)} </br>`;
        html += `<b>Conta/caixa:</b> ${this.formatId(data.caixa, listas?.caixas)} </br>`
        break;
      case 'financeiro':
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Centrais Estaduais</h3>`;
        html += `<b>Conta/caixa E-Protocolo:</b> ${this.formatId(data.caixaEprotocolo, listas.caixas)} </br>`
        html += `<b>Conta/caixa E-Certidão:</b> ${this.formatId(data.caixaEcertidao, listas.caixas)} </br>`
        html += `<b>Conta/caixa E-Intimação:</b> ${this.formatId(data.caixaEintimacao, listas.caixas)} </br>`
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Conciliação Automática</h3>`;
        html += `<b>Depósito:</b> ${this.formatBoolean(data.conciliarDeposito)} </br>`;
        html += `<b>PIX:</b> ${this.formatBoolean(data.conciliarPix)} </br>`;
        html += `<b>Cheque:</b> ${this.formatBoolean(data.conciliarCheque)} </br>`;
        html += `<b>Débito:</b> ${this.formatBoolean(data.conciliarDebito)} </br>`;
        html += `<b>Crédito:</b> ${this.formatBoolean(data.conciliarCredito)} </br>`;
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Configuração Boleto</h3>`;
        html += `<b>Conciliar Automaticamente:</b> ${this.formatBoolean(data.conciliarBoleto)} </br>`;
        html += `<b>Conta/caixa Recebimento:</b> ${this.formatId(data.caixaBoleto, listas?.caixas)} </br>`
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Reprotocolo</h3>`;
        html += `<b>Bloquear Reprotocolo com Saldo Negativo:</b> ${this.formatBoolean(data.bloquearReprotocoloDevedor)} </br>`;
        html += `<b>Transferir saldo no reprotocolo:</b> ${this.formatBoolean(data.transferirSaldoReprotocolo)} </br>`;
        if (data.transferirSaldoReprotocolo) {
          html += `<b>Reter custo ao transferir saldo:</b> ${this.formatBoolean(data.reterCustoReprotocolo)} </br>`;
        }
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Apresentar os valores no acompanhamento online</h3>`;
        html += `<b>Cancelado:</b> ${this.formatBoolean(data.apresentarCanceladoAcompanhamento)} </br>`;
        html += `<b>Finalizado:</b> ${this.formatBoolean(data.apresentarFinalizadoAcompanhamento)} </br>`;
        html += `<b>Registrado:</b> ${this.formatBoolean(data.apresentarRegistradoAcompanhamento)} </br>`;
        html += `<b>Aguardando Pagamento:</b> ${this.formatBoolean(data.apresentarAguardandoPagamentoAcompanhamento)} </br>`;
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Protocolo</h3>`;
        html += `<b>Permitir lançamento de saída quando o protocolo não possuir saldo:</b> ${this.formatBoolean(data.permitirSaidaNegativa)} </br>`;
        html += `<b>Cobrar certidão com base na data de registro do protocolo:</b> ${this.formatBoolean(data.certidaoSeguirProtocolo)} </br>`;
        // html += `<b>Confirmar automaticamente o repasse:</b> ${this.formatBoolean(data.confirmarAutomaticamenteRepasse)} </br>`;
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Recibos - Protocolo</h3>`;
        html += `<b>Antecipação:</b> ${this.formatId(data.reciboAntecipacaoProtocolo, listas.extrato)} </br>`
        html += `<b>Complementar:</b> ${this.formatId(data.reciboComplementarProtocolo, listas.extrato)} </br>`
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Recibos - Pedido de Certidão</h3>`;
        html += `<b>Antecipação:</b> ${this.formatId(data.reciboAntecipacaoCertidao, listas.extrato)} </br>`
        html += `<b>Complementar:</b> ${this.formatId(data.reciboComplementarCertidao, listas.extrato)} </br>`
        let possuiGuiaEstadual = VueBusiness.getVueRoot().isEstadoPE;
        let tituloGuiaEstadual = 'Sicase';
        if (possuiGuiaEstadual) {
          html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Caixa Para Recebimento</h3>`;
          html += `<b>Conta/caixa Recebimento:</b> ${this.formatId(data.caixaGuiaPagamentoEstadual, listas.caixas)} </br>`;
          html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">${tituloGuiaEstadual} - Protocolo</h3>`;
          html += `<b>Etapa Pós-pagamento:</b> ${this.formatId(data.etapaGuiaPagaProtocolo, listas.etapas)} </br>`;
          html += `<b>Usuário:</b> ${this.formatId(data.usuarioGuiaPagaProtocolo, listas.usuarios)} </br>`;
          html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">${tituloGuiaEstadual} - Pedido de Certidão</h3>`;
          html += `<b>Etapa Pós-pagamento:</b> ${this.formatId(data.etapaGuiaPagaCertidao, listas.etapas)} </br>`;
          html += `<b>Usuário:</b> ${this.formatId(data.usuarioGuiaPagaCertidao, listas.usuarios)} </br>`;
        }
        break;
      case 'outrosTemplates':
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">PDF</h3>`;
        html += `<b>Usar PDF/A:</b> ${this.formatBoolean(data.usarPdfa)} </br>`;
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Assinatura Digital</h3>`;
        html += `<b>Remover Brasão dos Ofícios Assinados:</b> ${this.formatBoolean(data.removerMarcaOficioAssinado)} </br>`;
        html += `<b>Texto Lateral do Ofício:</b> ${data.textoAssinaturaOficio ? `<div class="form-group"><div class="input-group"><textarea class="form-control form-control-xs" style="height: 180px" readonly>${data.textoAssinaturaOficio}</textarea></div></div>` : '-'} </br>`
        html += `<b>Remover Brasão das Certidões Assinadas:</b> ${this.formatBoolean(data.removerMarcaCertidaoAssinado)} </br>`;
        html += `<b>Remover Brasão dos Recibos Assinados:</b> ${this.formatBoolean(data.removerMarcaReciboAssinado)} </br>`;
        html += `<b>Remover Brasão das Exigências Assinadas:</b> ${this.formatBoolean(data.removerMarcaExigenciaAssinado)} </br>`;
        html += `<b>Texto Lateral da Exigência:</b> ${data.textoAssinaturaExigencia ? `<div class="form-group"><div class="input-group"><textarea class="form-control form-control-xs" style="height: 180px" readonly>${data.textoAssinaturaExigencia}</textarea></div></div>` : '-'} </br>`
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Anexos</h3>`;
        html += `<b>Assinatura de anexos:</b> ${data.templateAssinaturaAnexo ? `<div class="form-group"><div class="input-group"><textarea class="form-control form-control-xs" style="height: 180px" readonly>${data.templateAssinaturaAnexo}</textarea></div></div>` : '-'} </br>`
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">E-mails Diversos</h3>`;
        html += `<b>E-mail de Cadastro de Protocolo:</b> ${data.emailCadastroProtocolo ? `<div class="form-group"><div class="input-group"><textarea class="form-control form-control-xs" style="height: 180px" readonly>${data.emailCadastroProtocolo}</textarea></div></div>` : '-'} </br>`
        html += `<b>E-mail de Finalização de Protocolo:</b> ${data.emailFinalizacaoProtocolo ? `<div class="form-group"><div class="input-group"><textarea class="form-control form-control-xs" style="height: 180px" readonly>${data.emailFinalizacaoProtocolo}</textarea></div></div>` : '-'} </br>`
        html += `<b>E-mail de Reprotocolo:</b> ${data.emailReprotocolo ? `<div class="form-group"><div class="input-group"><textarea class="form-control form-control-xs" style="height: 180px" readonly>${data.emailReprotocolo}</textarea></div></div>` : '-'} </br>`
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Siscoaf</h3>`;
        html += `<b>Modelo Siscoaf:</b> ${data.modeloSiscoaf ? `<div class="form-group"><div class="input-group"><textarea class="form-control form-control-xs" style="height: 180px" readonly>${data.modeloSiscoaf}</textarea></div></div>` : '-'} </br>`
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">Indicadores</h3>`;
        html += `<b>Qualificação Indicador Pessoal:</b> ${data.qualificacaoIndividuo ? `<div class="form-group"><div class="input-group"><textarea class="form-control form-control-xs" style="height: 180px" readonly>${data.qualificacaoIndividuo}</textarea></div></div>` : '-'} </br>`
        html += `<b>Especificação Indicador Real:</b> ${data.especificaoImovel ? `<div class="form-group"><div class="input-group"><textarea class="form-control form-control-xs" style="height: 180px" readonly>${data.especificaoImovel}</textarea></div></div>` : '-'} </br>`
        html += `<b>Texto Lateral dos Livros e Relatórios:</b> ${data.textoLateralLivrosRelatorios ? `<div class="form-group"><div class="input-group"><textarea class="form-control form-control-xs" style="height: 180px" readonly>${data.textoLateralLivrosRelatorios}</textarea></div></div>` : '-'} </br>`
        break;
      case 'peerBanco':
        let peerBancos = ConfiguracaoBusiness.getPeerBancos();

        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">
                    Qualificação
                 </h3>`
        for (let i in peerBancos.papeis) {
          let papel = peerBancos.papeis[i];
          html += `<b>${papel.nome}:</b> ${this.formatId(data.papeis?.[papel.id], IndicadorRealBusiness.getPapeisEnvolvidos())} </br>`
        }

        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">
                    Tipo de Ato
                 </h3>`
        for (let i in peerBancos.atos) {
          let ato = peerBancos.atos[i];
          html += `<b>${ato.nome}:</b> ${this.formatId(data.atos?.[ato.id], listas?.tipoServico)} </br>`
        }

        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">
                    Natureza
                 </h3>`
        for (let i in peerBancos.naturezas) {
          let natureza = peerBancos.naturezas[i];
          html += `<b>${natureza.nome}:</b> ${this.formatId(data.naturezas?.[natureza.id], listas?.naturezas)} </br>`
        }

        if(VueBusiness.getVueRoot().isCartorio1RIGO){
          html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">
                    Documento
                 </h3>`
          for (let i in peerBancos.documentos) {
            let documento = peerBancos.documentos[i];
            html += `<b>${documento.nome}:</b> ${this.formatId(data.documentos?.[documento.id], listas?.documentos)} </br>`
          }

          html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">
                    Índices
                 </h3>`
          for (let i in peerBancos.indices) {
            let indice = peerBancos.indices[i];
            html += `<b>${indice.nome}:</b> ${data.indices?.[indice.id] || '-'} </br>`
          }
        }
        break;
      case 'livros':
        let suspensoes = ProtocoloBusiness.getTipoCancelamentoAutomatico('').filter(e => e.id != 'CANCELAR')
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">
                    Livro Matrículas
                 </h3>`
        html += `<b>Folhas:</b> ${data.livrMatricula?.folhas || '-'} </br>`
        html += `<b>Frente e Verso:</b> ${this.formatBoolean(data.livrMatricula?.frenteVerso)} </br>`;
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">
                    Livro Registro Auxiliar
                 </h3>`
        html += `<b>Folhas:</b> ${data.livroRegistroAuxiliar?.folhas || '-'} </br>`
        html += `<b>Frente e Verso:</b> ${this.formatBoolean(data.livroRegistroAuxiliar?.frenteVerso)} </br>`;
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">
                    Livro Diário Auxiliar
                 </h3>`
        html += `<b>Folhas:</b> ${data.livroDiarioAuxiliar?.folhas || '-'} </br>`
        html += `<b>Frente e Verso:</b> ${this.formatBoolean(data.livroDiarioAuxiliar?.frenteVerso)} </br>`;
        // html += `<b>ISS (Decimal - Livro Caixa):</b> ${data.iss ? Utils.formatMoney(data.iss) : '-'} </br>`
        html += `<b>Remuneração Responsável:</b> ${this.formatId(data?.livroDiarioAuxiliar?.remuneracaoUUID, listas?.categorias)} </br>`;
        html += `<b>IRPF:</b> ${this.formatId(data?.livroDiarioAuxiliar?.irpfUUID, listas?.categorias)} </br>`;
        html += `<b>Recolhimento Tribunal:</b> ${this.formatId(data?.livroDiarioAuxiliar?.recolhimentoTribunalUUID, listas?.categorias)} </br>`;
        html += `<b>Ressarcimento Atos:</b> ${this.formatId(data?.livroDiarioAuxiliar?.ressarcimentoUUID, listas?.categorias)} </br>`;
        html += `<b>Renda Mínima:</b> ${this.formatId(data?.livroDiarioAuxiliar?.ajudaCustoUUID, listas?.categorias)} </br>`;
        html += `<b>INSS (Interino):</b> ${this.formatId(data?.livroDiarioAuxiliar?.interinoInssUUID, listas?.categorias)} </br>`;
        html += `<b>IRPF (Interino):</b> ${this.formatId(data?.livroDiarioAuxiliar?.interinoIrpfUUID, listas?.categorias)} </br>`;
        html += `<b>Remuneração (Interino):</b> ${this.formatId(data?.livroDiarioAuxiliar?.interinoRemuneracaoUUID, listas?.categorias)} </br>`;
        html += `<b>Rendimento de aplicações financeiras:</b> ${this.formatId(data?.livroDiarioAuxiliar?.rendimentoAplicacoesFinanceirasUUID, listas?.categorias)} </br>`;
        html += `<b>Receita excedente depositada em conta judicial:</b> ${this.formatId(data?.livroDiarioAuxiliar?.receitaExcedenteContaJudicialUUID, listas?.categorias)} </br>`;
        html += `<b>Receita excedente depositada para delegatário afastado:</b> ${this.formatId(data?.livroDiarioAuxiliar?.receitaExcendenteDelegatarioAfastadoUUID, listas?.categorias)} </br>`;
        html += `<b>Receita Excedente Transferida ao Poder Judiciário:</b> ${this.formatId(data?.livroDiarioAuxiliar?.receitaExcedenteTransferidaPoderJudiciarioUUID, listas?.categorias)} </br>`;
        html += `<b>FRJ pago:</b> ${this.formatId(data?.livroDiarioAuxiliar?.frjPagoUUID, listas?.categorias)} </br>`;
        html += `<b>ISS pago:</b> ${this.formatId(data?.livroDiarioAuxiliar?.issPagoUUID, listas?.categorias)} </br>`;
        html += `<b>Outros repasses pagos:</b> ${this.formatId(data?.livroDiarioAuxiliar?.outrosRepassesPagosUUID, listas?.categorias)} </br>`;
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">
                    Livro Depósito Prévio
                 </h3>`
        html += `<b>Folhas:</b> ${data.livroDepositoPrevio?.folhas || '-'} </br>`
        html += `<b>Frente e Verso:</b> ${this.formatBoolean(data.livroDepositoPrevio?.frenteVerso)} </br>`;
        html += `<b>Data de Início:</b> ${this.formataData(data.livroDepositoPrevio?.dataInicial) || '-'} </br>`
        html += `<b>Saldo inicial:</b> ${Utils.formatMoney(data.livroDepositoPrevio?.saldoInicial )|| '-'} </br>`
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">
                    Livro Protocolo
                 </h3>`
        html += `<b>Folhas:</b> ${data.livroProtocolo?.folhas || '-'} </br>`
        html += `<b>Frente e Verso:</b> ${this.formatBoolean(data.livroProtocolo?.frenteVerso)} </br>`;
        html += `<b>Cancelamento automático (Decurso de Prazo):</b> ${this.formatBoolean(data.cancelarProtocolos)} </br>`;
        html += `<b>Cancelamento automático (Aguardando Pagamento):</b> ${this.formatBoolean(data.cancelarProtocolosAguardandoPagamento)} </br>`;
        html += `<b>Alertar sobre o vencimento da prenotação ao avançar para etapa do tipo Exigência:</b> ${this.formatBoolean(data.alertaVencimentoPrenotacao)} </br>`;
        html += `<b>Alocar o termo de encerramento ao lado da assinatura:</b> ${this.formatBoolean(data.termoEncerramento)} </br>`;
        if (data?.cancelarProtocolos) {
          html += `<b>Usuário:</b> ${this.formatId(data?.usuarioCancelamento, listas?.usuarios)} </br>`;
        }
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">
                    Livro Protocolo - Registrar ocorrências
                 </h3>`
        html += `<b>Registrar Cancelamento por Decurso de Prazo:</b> ${this.formatBoolean(data.registrarDecursoPrazo)} </br>`;
        html += `<b>Registrar Reativação de Protocolo Cancelado:</b> ${this.formatBoolean(data.registrarReativacaoProtocolo)} </br>`;
        html += `<b>Registrar anotação na ocorrência "Reingressado":</b> ${this.formatBoolean(data.registrarAnotacaoReingressado)} </br>`;
        html += `<b>Registrar anotação na ocorrência ao entrar em etapa do tipo "Aguardando Pagamento":</b> ${this.formatBoolean(data.registrarAnotacaoEntrandoEtapaAguardandoPagamento)} </br>`;
        html += `<b>Registrar anotação na ocorrência ao sair de etapa do tipo "Aguardando Pagamento":</b> ${this.formatBoolean(data.registrarAnotacaoSaindoEtapaAguardandoPagamento)} </br>`;
        let registrarOcorrencia = [
          {id: 'AMBAS', nome:'Suspensão & Reingresso'},
          {id: 'SUSPENSAO', nome:'Suspensão'},
          {id: 'REINGRESSO', nome:'Reingresso'},
          {id: 'NAO_REGISTRAR', nome:'Não Registrar'}
        ]
        for (let i in suspensoes) {
          let suspenso = suspensoes[i];
          html += `<b>${suspenso.nome}:</b> ${this.formatId(data.ocorrencias?.[suspenso.id], registrarOcorrencia)} </br>`
        }
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">
                    Outras Configurações
                 </h3>`
        // html += `<b>ONR no Livro Caixa (Carnê Leão):</b> ${this.formatBoolean(data.onrCarneLeao)} </br>`;
        html += `<b>Livro Carnê Leão:</b> ${data.livroCarneLeao === 'MOVIMENTACAO_FINANCEIRA' ? 'Movimentação Financeira' : 'Receitas' } </br>`;
        if (data.livroCarneLeao === 'MOVIMENTACAO_FINANCEIRA') {
          html += `<b>Subtrair valor dos selos:</b> ${this.formatBoolean(data.subtrairValorSelos)} </br>`;
          html += `<b>Discriminar as transferências na totalização mensal:</b> ${this.formatBoolean(data.discriminarTransferencia)} </br>`;
        }
        break;
      case 'arquivoMorto':
        html += `<b>Utilizar Arquivo Morto:</b> ${this.formatBoolean(data.arquivoMorto)} </br>`
        break;
      case 'geral':
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">
                    Indicador Pessoal
                 </h3>`
        html += `<b>Desativar Versionamento:</b> ${this.formatBoolean(data.desativarVersionamentoPessoas)} </br>`
        html += `<b>Permitir cadastro de indicador pessoal com CPF/CNPJ inválido:</b> ${this.formatBoolean(data.permitirPessoasDocumentoInvalido)} </br>`
        html += `<b>Adicionar indicador pessoal por:</b> ${this.formatId(data?.tipoSelecaoIndicadorPessoal, listas?.tipoSelecaoIndicadorPessoal)} </br>`
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">
                    Configurações de Ato
                 </h3>`
        html += `<b>Desativar Calculadora do Cidadão:</b> ${this.formatBoolean(data.desativarCalculadorCidadao)} </br>`
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">
                    Regras de Emolumentos - Considerar valor mínimo da tabela de emolumentos
                 </h3>`
        html += `<b>Primeira aquisição - SFH:</b> ${this.formatBoolean(data.valorMinimoSFH)} </br>`
        html += `<b>FAR/PAR:</b> ${this.formatBoolean(data.valorMinimoFAR)} </br>`
        html += `<b>Constrições Judiciais:</b> ${this.formatBoolean(data.valorMinimoConstricoesJudiciais)} </br>`
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">
                    Primeira Aquisição - SFH
                 </h3>`
        html += `<b>Considerar apenas o valor do negócio para o cálculo do desconto (Compra e Venda):</b> ${data.apenasValorAlienacao ? 'Sim' : 'Não'} </br>`
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">
                    Certidão
                 </h3>`
        html += `<b>Prazo da Certidão em Dias Úteis:</b> ${this.formatBoolean(data.prazoCertidaoDiasUteis)} </br>`
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">
                    Configurações de Ato & Certidão
                 </h3>`
        html += `<b>Exibir data base de cálculo:</b> ${this.formatBoolean(data.exibirDataBase)} </br>`
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">
                    Acesso Externo
                 </h3>`
        html += `<b>IPs de Acesso (Separado por vírgula):</b> ${data.ips || '-'} </br>`
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">
                    Protocolo
                 </h3>`
        html += `<b>Alerta de protocolo vencido deve considerar o vencimento após reingresso:</b> ${this.formatBoolean(data.considerarVencimentoAposReingresso)} </br>`
        break;
      case 'calculadoraValorVenal':
        html += `<b>URL:</b> ${data.url || '-'} </br>`
        break;
      case 'listaRegistroAnterior':
        html += `${this.formatId(data?.id, listas?.listas)} </br>`;
        break;
      case 'resumoIndicadorReal':
        html += `${this.formatId(data?.id, listas?.fragmentos)} </br>`;
        break;
      case 'pdf':
        html += `<b>Usar versão nova:</b> ${this.formatBoolean(data.novaLinhaProtecao)} </br>`
        if (data.novaLinhaProtecao) {
          html += `<b>Recuo Superior:</b> ${data.recuoSuperiorLinhaProtecao || '-'} </br>`
          html += `<b>Recuo Inferior:</b> ${data.recuoInferiorLinhaProtecao || '-'} </br>`
          html += `<b>Recuo Lateral:</b> ${data.recuoLateralLinhaProtecao || '-'} </br>`
        }
        break;
      case 'configuracaoFracaoTransferenciaImovel':
        html += `<b>Permitir transferência com frações diferentes:</b> ${this.formatBoolean(data.habilitarMargem)} </br>`
        if (data.habilitarMargem) {
          html += `<b>Margem percentual aceitável (%):</b> ${data.margemTransferencia ? Utils.formatMoney(data.margemTransferencia) : '-'} </br>`
        }
        break;
      case 'indicadorReal':
        html += `<b>Permitir cadastrar/editar indicador real sem informar os proprietários:</b> ${this.formatBoolean(data.salvarSemProprietarios)} </br>`
        break;
      default:
        break;
    }
    return html || '-';
  },

  async checklist(opcoes = []) {
    if (Utils.isEmptyNullOrUndefined(opcoes) || !opcoes.length) {
      return '-';
    }
    let html = '';
    html += `<div class="ml-1">`
    let ordem = 0;
    for (const requisito of opcoes) {
      ordem++;
      html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">
                    Requisito ${ordem}
                 </h3>`
      html += `<b>Pergunta:</b> ${requisito.pergunta || '-'} </br>`
      let campos = ChecklistRequisitoBusiness.campos();
      html += `<b>Tipo:</b> ${(campos.find(c => c.id === requisito.tipo))?.nome || '-'} </br>`
      html += `<b>Índice:</b> ${requisito.indice || '-'} </br>`
      if (!['GROUP'].includes(requisito.tipo)) {
        html += `<b>Tamanho (2 a 12):</b> ${requisito.tamanho || '-'} </br>`
        html += `<b>Recuo (0 a 6):</b> ${requisito.recuo || '-'} </br>`
      }
      let mascaras = ChecklistRequisitoBusiness.mascaras()
      let multiplicador = ChecklistRequisitoBusiness.multiplicador()
      let vinculos = ChecklistRequisitoBusiness.vinculos()
      let apresentacao = ChecklistRequisitoBusiness.apresentacao()
      let titulo = ChecklistRequisitoBusiness.titulo()
      let apresentacaoTitulo = ChecklistRequisitoBusiness.apresentacaoTitulo()
      let tiposTitulo =  await TipoDocumentoBusiness.getSimpleList()
      let papeis = IndicadorRealBusiness.getPapeisEnvolvidos()
      let exigencia = ChecklistRequisitoBusiness.exigencia();
      let automacao = ChecklistRequisitoBusiness.automacao();
      let apresentacaoSingular = ChecklistRequisitoBusiness.apresentacaoSingular();
      let listas = await ListaBusiness.getAll();
      let checklists = await ChecklistBusiness.getSimpleList();

      if (requisito.tipo === 'INPUT') {
        html += `<b>Máscara:</b> ${(mascaras.find(m => m.id === requisito.mascara))?.nome || '-'} </br>`
      }
      if (requisito.tipo === 'GROUP') {
        html += `<b>Multiplicador:</b> ${(multiplicador.find(m => m.id === requisito.mascara))?.multiplicador || '-'} </br>`
      }
      if (requisito.tipo === 'VINCULO') {
        html += `<b>Tipo de Vínculo:</b> ${(vinculos.find(m => m.id === requisito.vinculo))?.nome || '-'} </br>`;
        html += `<b>Múltipla Escolha:</b> ${requisito.multiplo ? 'Sim' : 'Não'} </br>`;
      }
      if (requisito.tipo === 'SELECT') {
        html += `<b>Tipo de Apresentação:</b> ${(apresentacao.find(m => m.id === requisito.apresentacao))?.nome || '-'} </br>`
      }
      if (requisito.tipo === 'LABEL') {
        html += `<b>Tipo de Apresentação:</b> ${(titulo.find(m => m.id === requisito.apresentacao))?.nome || '-'} </br>`;
        html += `<b>Cor:</b> ${requisito.cor || '-'} </br>`;
        html += `<b>Mensagem:</b> ${requisito.mensagem || '-'} </br>`;
      }
      if (requisito.tipo === 'VINCULO' && requisito.vinculo === 'TITULO') {
        html += `<b>Tipo de Apresentação:</b> ${(apresentacaoTitulo.find(m => m.id === requisito.apresentacao))?.nome || '-'} </br>`;
        if ((requisito.tipos || []).length) {
          html += `<b>Tipos:</b>`;
          (requisito.tipos || []).forEach(t => {
            html += (tiposTitulo.find(m => m.id === t))?.nome + '; '
          })
          html += `</br>`;
        } else {
          html += `<b>Tipos:</b>-</br>`;
        }
      }

      if (requisito.tipo === 'GROUP' && requisito.multiplicador === 'envolvido') {
        if ((requisito.papeis || []).length) {
          html += `<b>Papéis:</b>`;
          (requisito.papeis || []).forEach(t => {
            html += (papeis.find(m => m.id === t))?.nome + '; '
          })
          html += `</br>`;
        } else {
          html += `<b>Papéis:</b>-</br>`;
        }
      }

      if (requisito.tipo === 'SELECT' && requisito.apresentacao === 'checkbox') {
        html += `<b>Tipo de Exigência:</b> ${(exigencia.find(m => m.id === requisito.tipoExigencia))?.nome || '-'} </br>`;
        if (requisito.tipo === 'SELECT' && requisito.apresentacao === 'checkbox' && (requisito.tipoExigencia === 'vazio' || requisito.tipoExigencia === 'incompleto')) {
          html += `<b>Exigência:</b> ${requisito.exigencia ? `<textarea class="w-100" style="height: 180px" readonly>${requisito.exigencia}</textarea>` : '-'} </br>`
        }
      }

      if (requisito.tipo === 'AUTOMATICO') {
        html += `<b>Condição Automática:</b> ${(automacao.find(m => m.id === requisito.chave))?.nome || '-'} </br>`;
        html += `<b>Tipo de Apresentação:</b> ${(apresentacaoSingular.find(m => m.id === requisito.apresentacao))?.nome || '-'} </br>`;
      }

      if (requisito.tipo === 'LIST') {
        html += `<b>Lista:</b> ${(listas.find(m => m.id === requisito.lista))?.nome || '-'} </br>`;
      }

      if (requisito.tipo === 'CHECKLIST') {
        html += `<b>Checklist:</b> ${(checklists.find(m => m.id === requisito.checklist))?.nome || '-'} </br>`;
      }

      html += `<b>Texto de Ajuda:</b> ${requisito.ajuda || '-'} </br>`

      let isCampoSimNao = requisito.tipo ===  'BOOLEAN';
      if (requisito.tipo === 'SELECT' || isCampoSimNao || (requisito.tipo === 'VINCULO' && requisito.vinculo === 'TITULO') && requisito.condicoes.length) {
        html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">
                    Condições
                 </h3>`
        for (const condicao of requisito.condicoes) {
          html += `<div class="ml-2">`
          html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 pt-2 m-0 mt-2" style="border-color: #636363 !important;">
                    ${condicao.valor}
                 </h3>`
          if (condicao.exigencia) {
            html += `<b>Exigência:</b> ${condicao.exigencia || '-'} </br>`
          }
          if ((condicao.requisitos || []).length) {
            html += await this.checklist(condicao.requisitos)
          }
          html += `</div>`
        }
      }

    }
    html += `</div>`
    return html;
  },

  documentos(documentos = []) {

    let html = '';

    for (let i in documentos) {
      let obj = documentos[i];

      html += `<b>${obj.documento.nome}</b> </br>`
    }

    return html;
  },

  multiplasCobrancas(dto) {
    let html = '', multiplasCobrancas = dto?.multiplasCobrancas;
    if (!!!(multiplasCobrancas)) {
      return '';
    }
    html += `<li class="ml-2 p-0" ><b>Incluir Mútiplas Cobranças:</b> ${multiplasCobrancas ? 'Sim' : 'Não'}</li>`;
    if (dto?.valoresMultiplasCobrancas?.length) {
      dto?.valoresMultiplasCobrancas.forEach(v => {
        html += `<li class="ml-3 p-0 small" style="overflow: hidden;">
                        <label class="w-50 float-left"><b>Quantidade:</b> ${v.quantidade} </label>
                        <label  class="w-50 float-left"><b>Base Cálculo:</b> R$ ${Utils.formatMoney(v.valorBase)}</label>
                     </li>`;
      });
    }

    return `<ul>${html}</ul>`;
  },

  registroAuxiliar(dto) {
    let htmls = [], indicador = dto?.indicador || {};

    if (!Utils.isEmptyNullOrUndefined(dto?.tipoCedula)) {
      htmls.push(`<li><b>Tipo da cédula:</b> ${RegistroAuxiliarBusiness.getTiposCedulas().find(m => m.id === dto?.tipoCedula)?.nome}</li>`)
    }
    if (!Utils.isEmptyNullOrUndefined(dto?.valoresBaseCalculo?.valorRegistro)) {
      htmls.push(`<li><b>Valor da cédula:</b> ${Utils.formatMoney(dto?.valoresBaseCalculo?.valorRegistro)}</li>`)
    }
    if (!Utils.isEmptyNullOrUndefined(dto?.vencimento)) {
      htmls.push(`<li><b>Vencimento:</b> ${this.formattersDate(dto?.vencimento)}</li>`)
    }
    if (!Utils.isEmptyNullOrUndefined(dto?.pacto)) {
      htmls.push(`<li><b>Pacto:</b> ${this.formattersDate(dto?.pacto)}</li>`)
    }
    if (!Utils.isEmptyNullOrUndefined(dto?.condominio)) {
      htmls.push(`<li><b>Nome do condomínio:</b> ${dto?.condominio}</li>`)
    }
    if (!Utils.isEmptyNullOrUndefined(dto?.texto)) {
      htmls.push(`<li><b>Teor:</b> ${dto?.texto}</li>`)
    }
    if (!Utils.isEmptyNullOrUndefined(dto?.observacoes)) {
      htmls.push(`<li><b>Observações:</b> ${dto?.observacoes}</li>`)
    }
    if (!Utils.isEmptyNullOrUndefined(dto?.indicadorReal)) {
      htmls.push(`<li><b>Matrículas Relacionadas:</b></li>`)
      dto?.indicadorReal.forEach(a => {
        htmls.push(`<li class="ml-2 small" style="overflow: hidden;">${FrontBusiness.nomearFicha(a)}</li>`)
      });
    }
    if (!Utils.isEmptyNullOrUndefined(dto?.descricao)) {
      htmls.push(`<li><b>Descrição:</b> ${dto?.descricao}</li>`)
    }
    if (!Utils.isEmptyNullOrUndefined(dto?.livroLetra)) {
      htmls.push(`<li><b>Livro (Letra):</b> ${dto?.livroLetra}</li>`)
    }
    if (!Object.keys(indicador).length && !htmls.length) {
      return '-';
    }

    return htmls?.length ? `<ul>${htmls.join('') || '-'}</ul>` : '-';
  },

  indicadorReal(dto) {
    let htmls = [], indicador = dto || {};

    if (!Object.keys(indicador).length) {
      return '-';
    }

    Object.keys(indicador).forEach(key => {
      let html = '';
      switch (key) {
        case 'localizacao':
          html += `<li><b>Localização:</b> ${indicador[key]}</li>`
          break;
        case 'cadastroImobiliario':
          html += `<li><b>Cadastro Imobiliário:</b> ${indicador[key]}</li>`
          break;
        case 'cadastroImobiliarioBrasileiro':
          html += `<li><b>Cadastro Imobiliário Brasileiro (CIB):</b> ${indicador[key]}</li>`
          break;
        case 'medidaAreaDoi':
          html += `<li><b>Medida área DOI:</b> ${indicador[key]}</li>`
          break;
        case 'areaTotalDoi':
          html += `<li><b>Área total DOI:</b> ${indicador[key]}</li>`
          break;
        case 'cep':
          html += `<li><b>CEP:</b> ${indicador[key]}</li>`
          break;
        case 'logradouro':
          html += `<li><b>Logradouro (Rua):</b> ${indicador[key]}</li>`
          break;
        case 'numero':
          html += `<li><b>Número:</b> ${indicador[key]}</li>`
          break;
        case 'bairro':
          html += `<li><b>Bairro (Setor):</b> ${indicador[key]}</li>`
          break;
        case 'camposArea':
          let medidaArea = IndicadorRealBusiness.getMedidas().find(m => m.id === indicador[key].medidaArea)?.nome;
          html += `<li><b>Área</b></li>`
          html += `<li class="ml-2 small" style="overflow: hidden;">
                            <label class="w-50 float-left"><b>Medida Área:</b> ${medidaArea} </label>
                            <label class="w-50 float-left"><b>Área Total:</b> ${Utils.formatMoney(indicador[key].area, {min : 2, max : 10})}</label>
                            <label class="w-50 float-left"><b>Fração solo${medidaArea ? ` (${medidaArea})` : ''}:</b> ${indicador[key].fracaoSolo ? Utils.formatMoney(indicador[key].fracaoSolo,{min : 2, max : 10}) : '-'}</label>
                            <label class="w-50 float-left"><b>Fração solo (%):</b> ${indicador[key].fracaoSoloPercentual ? Utils.formatMoney(indicador[key].fracaoSoloPercentual,{min : 2, max : 10}) : '-'}</label>
                            <label class="w-100 float-left"><b>Coeficiente de proporcionalidade:</b> ${indicador[key].coeficienteProporcionalidade ? Utils.formatMoney(indicador[key].coeficienteProporcionalidade,{min : '0', max : 10}) : '-'}</label>
                         </li>`;
          break;

        case 'camposAreaEdificada':
          html += `<li><b>Áreas Edificadas</b></li>`
          indicador[key].forEach(a => {
            html += `<li class="ml-2 small" style="overflow: hidden;">
                            <label class="w-50 float-left"><b>Descrição:</b> ${a.descricao} </label>
                            <label class="w-50 float-left"><b>Área:</b> ${Utils.formatMoney(a.area)}</label>
                         </li>`;
          })
          break;
        case 'camposUnidadesAcessorias':
          html += `<li><b>Unidades Acessórias</b></li>`
          indicador[key].forEach(a => {
            html += `<li class="ml-2 small" style="overflow: hidden;">
                            <label style="width: 25%" class="float-left"><b>Descrição:</b> ${a.descricao} </label>
                            <label style="width: 25%" class="float-left"><b>Unidade:</b> ${a.unidade}</label>
                            <label style="width: 25%" class="float-left"><b>Complemento:</b> ${a.complemento}</label>
                            <label style="width: 25%" class="float-left"><b>Área:</b> ${Utils.formatMoney(a.area)}</label>
                         </li>`;
          })
          break;
        case 'especificacao':
          html += `<li><b>Especificações do Imóvel:</b> ${indicador[key]}</li>`
          break;
        case 'imovelUniao':
          html += `<li><b>Imóvel da União:</b> ${indicador[key] ? 'Sim' : 'Não'}</li>`
          break;
        case 'marinha':
          html += `<li><b>Imóvel da Marinha:</b> ${indicador[key] ? 'Sim' : 'Não'}</li>`
          break;
        case 'fronteira':
          html += `<li><b>Imóvel de Fronteira:</b> ${indicador[key] ? 'Sim' : 'Não'}</li>`
          break;
        case 'denominacao':
          html += `<li><b>Denominação da Área Rural:</b> ${indicador[key]}</li>`
          break;
        case 'certificacaoIncra':
          html += `<li><b>Certificação no SIGEF:</b> ${indicador[key]}</li>`
          break;
        case 'incra':
          html += `<li><b>INCRA (CCIR):</b> ${indicador[key]}</li>`
          break;
        case 'registroAnterior':
          html += `<li><b>Registro Anterior</b></li>`
          indicador[key].forEach(a => {
            html += `<li class="ml-2 small" style="overflow: hidden;">${IndicadorRealBusiness.getLabelRegistroAnterior(a)}</li>`;
          })
          break;
        case 'livroLetra':
          html += `<li><b>Livro (Letra):</b> ${indicador[key]}</li>`
          break;
        case 'camposTipoImovel':
          html += `<li><b>Tipo Imóvel:</b> ${this.trataEnumTipoImovel(indicador[key]['tipoImovel'])} ${indicador[key]['descricaoTipoImovelOutros'] ? indicador[key]['descricaoTipoImovelOutros'] : ''}</li>`
          break;
        case 'registrosVinculados':
          html += `<li><b>Registro Anterior Vinculados</b></li>`
          indicador[key].forEach(a => {
            html += `<li class="ml-2 small" style="overflow: hidden;">${FrontBusiness.nomearFicha(a)}</li>`;
          })
          break;
        case 'referencias':
          html += `<li><b>Referências</b></li>`
          indicador[key].forEach(a => {
            html += `<li class="ml-2 small" style="overflow: hidden;">${FrontBusiness.nomearFicha(a.referenciaDetalhes)}</li>`;
          })
          break;
        default:
          html += `<li><b>${key.substring(0,1).toUpperCase().concat(key.substring(1))}:</b> ${indicador[key]}</li>`
          break;
      }
      htmls.push(html);
    });

    return htmls?.length ? `<ul>${htmls.join('') || '-'}</ul>` : '-';
  },

  configuracaoRestricao(dto) {
    let html = '', configuracaoRestricao = dto?.configuracaoRestricao;
    if (!(configuracaoRestricao?.restricoesSelecionadas || []).length) {
      return '-';
    }

    html += `<li class="p-0" style="list-style: none; margin-left: -20px"><b>Criar Restrições: </b>`;
    let v;
    (configuracaoRestricao?.restricoesSelecionadas || []).forEach(r => {
      v = (v ? ', ' : ' ');
      html += v + `${business.getLabelRestricao(r)}`;
    })
    html += '</li>'
    if (configuracaoRestricao?.vencimento) {
      html += `<li class="p-0" style="list-style: none; margin-left: -20px"><b>Vencimento: </b>${moment(configuracaoRestricao.vencimento).format('DD/MM/YYYY')}</li>`;
    }
    let restricoes = dto?.restricoes || [];
    if (configuracaoRestricao?.atoOrigem) {
      let ato = restricoes.find(a => JSON.parse(a.descricaoRestricao)?.id === configuracaoRestricao?.atoOrigem);
      if (ato) {
        html += `<li class="p-0" style="list-style: none; margin-left: -20px"><b>Vincular Restrição Anterior: </b> ${AtoBusiness.descricaoAtoRestricaoCompleta(JSON.parse(ato.descricaoRestricao))}</li>`
      }
    }
    if ((dto?.restricoesCanceladas || []).length) {
      html += `<h3 class="card-title small border-sidebar font-weight-500 border-bottom pt-2 p-0" style="border-color: #636363 !important;margin-left: -20px;">Restrições Canceladas</h3>`;
      restricoes.forEach(r => {
        let status = (dto?.restricoesCanceladas || []).includes(r.id) ? 'Cancelada' : null;
        if (status) {
          html += `<li class=" p-0 mb-2" style="list-style: none;overflow: hidden;margin-left: -20px;"><b>${AtoBusiness.descricaoAtoRestricaoCompleta(JSON.parse(r.descricaoRestricao))}</b><br>
                      ${r.nome ? `<label class="w-100 float-left small"><b>Pessoa: </b>${r.nome} ${r.documento ? ` (${r.documento})` : ''}</label>` : ''}
                      ${r.restricao ? `<label class="w-50 float-left small"><b>Restrições: </b>${FrontBusiness.getLabel(r.restricao, business.restricoes())}</label>` : ''}
                  </li>`
        }
      });
    }

    return `<ul>${html}</ul>`;
  },

  async formattersFinanceiro(auditoria) {
    let formatters = [];

    formatters = [
      {label: 'Meio de Pagamento', key: 'meioPagamento'},
      {labelFnc: (auditoria) => auditoria.movimento === 'ENTRADA' ? 'Forma de Pagamento' : 'Forma de Devolução', key: 'formaPagamento', type: 'enum', lista: ProtocoloFinanceiroBusiness.getFormasPagamento()},
      {label: 'Pagamento com Data Diferente', key: 'pagamentoDataDiferente', type: 'boolean'},
      {label: 'Data Pagamento', key: 'dataPagamento', type: 'date'},
      {label: 'Data Competência', key: 'competencia', type: 'date'},
      {label: 'Data Vencimento', key: 'vencimento', type: 'date'},
      {label: 'Valor', key: 'valor', type: 'currency'},
      {labelFnc: (auditoria) => auditoria.movimento === 'ENTRADA' ? 'Recebido de' : 'Devolvido a', key: 'parte'},
      {label: 'Descrição', key: 'descricao', type: 'string'},
      {labelFnc: (auditoria) => 'Número '+(['SICASE', 'SICASE_COMPLEMENTAR', 'DAJE', 'DAJE_COMPLEMENTAR'].includes(auditoria.formaPagamento) ? 'da Guia' : 'de Controle'), key: 'numeroControle', type: 'string'},
      {label: 'Banco Emissor', key: 'bancoEmissor', type: 'enum', lista: await ConfiguracaoBusiness.getLstBancoEmissor()},
      {label: 'Agência', key: 'agencia', type: 'string'},
      {label: 'Conta de Origem (TED/Emissão)', key: 'conta', type: 'string'},
      {label: 'Emissão', key: 'emissao', type: 'date'},
      {labelFnc: (auditoria) =>  ['DEPOSITO', 'PIX'].includes(auditoria.formaPagamento) ? `Conta que ${auditoria.movimento === 'ENTRADA' ? 'recebeu' : 'enviou'} o ${auditoria.formaPagamento === 'PIX' ? 'pix' : 'depósito'}` : 'Caixa', key: 'caixa', formatter: async (value) => value?.nome ? value?.nome : await this.nomearCaixa(value)},
      {label: 'Origem', key: 'origem', type: 'json'},
      {label: 'Destino', key: 'destino', type: 'json'},
      {label: 'Conciliado', key: 'conciliado', type: 'boolean'},
      {label: 'Agência', key: 'agencia', type: 'string'},
      {label: 'CPF', key: 'documentoEmitente', type: 'string'},
      {label: 'Nome', key: 'nomeEmitente', type: 'string'},
      {label: 'ID Transação Bancária', key: 'idTransacaoBancaria', type: 'string'},
      {label: 'Observação', key: 'observacao', type: 'string'},
      {label: 'Taxa do Cartão', key: 'taxa', type: 'string'},
      {label: 'QR Code na máquina', key: 'envio', type: 'string', formatter: (value, auditoria) => {
          if (value && auditoria && auditoria.formaPagamento === "PIX" && auditoria.meioPagamento === "Parcela Express") {
            return value?.qrcodeMaquina ? 'Sim' : 'Não';
          }
        }},
      {label: 'Terminal', key: 'envio', type: 'string', formatter: (value, auditoria) => {
          if (value && auditoria && auditoria.meioPagamento === "Parcela Express") {
            return value?.terminal;
          }
        }},
      {label: 'Parcelas', key: 'envio', type: 'string', formatter: (value, auditoria) => {
          if (value && auditoria && auditoria.meioPagamento === "Parcela Express") {
            return value?.parcela;
          }
      }},
    ];

    return formatters;
  },

  async formattersAtos(auditoria) {
    let formatters = [];
    let tipo = 'Tipo do Ato';
    if(auditoria?.entidade === 'CERTIDAO'){
      tipo = 'Tipo do Certidão';
    }

    formatters = [
      /**********************************************/

      {label: 'Registro Auxiliar', key: 'registroAuxiliar', formatter: (value) => this.nomearFicha(value, 'registroAuxiliar')},
      {label: 'Registro de Título', key: 'registroTitulo', formatter: (value) => this.nomearFicha(value, 'registroTitulo')},
      {label: 'Indicador Real', key: 'indicadorReal', formatter: (value) => this.nomearFicha(value)},
      {label: 'Indicador', key: 'indicador', formatter: (value) => this.nomearFicha(value, 'indicador')},
      {label: 'Bem Móvel', key: 'bemMovel', formatter: (value) => this.nomearFicha(value, 'bemMovel')},
      {label: 'Protocolo', key: 'protocolo', formatter: (value) => this.nomearProtocolo(value)},
      {label: 'Ato', key: 'ato', formatter: (value) => this.getTipoAto(value)},
      {label: 'Etapa', key: 'etapa', formatter: (value) => this.getEntityById(value, EtapaBusiness)},
      {label: tipo, key: 'tipoServico', formatter: (value) => this.getEntityById(value, TipoServicoBusiness)},

      {label: 'Registro', key: 'registro', type: 'datetime'},
      {label: 'Titulo', key: 'titulo'},

      {label: 'Selado', key: 'selado', type: 'datetime'},
      {label: 'Selado', key: 'selada', type: 'datetime'},

      {label: 'Código', key: 'codigo', type: 'integer'},
      {label: 'Pedido', key: 'pedido', type: 'integer'},
      {label: 'Ordem', key: 'ordem', type: 'decimal'},
      {label: 'Principal', key: 'principal', type: 'boolean'},
      {label: 'Custas Manuais', key: 'custasManual', type: 'boolean'},
      {label: 'Número da CAT', key: 'numeroCat'},
      {label: 'Ato Digital',key:'digital',type: 'boolean',}
    ];

    if(auditoria?.entidade == 'PROTOCOLO_PRENOTACAO'){
      formatters.push({label: 'Tipo Registro', key: 'tipoRegistro', type: 'enum', lista: TabelaCustaBusiness.listTiposRegistro()});
    }

    if(VueBusiness.getVueRoot().isEstadoBA || VueBusiness.getVueRoot().isEstadoSE){
      formatters.push({label: 'Número da Guia DAJE', key: 'guia', type: 'text', group: 'DAJE'})
    }

    if(VueBusiness.getVueRoot().isEstadoPE){
      formatters.push({label: 'Número da Guia SICASE', key: 'guia', type: 'text', group: 'SICASE'})
      formatters.push({label: 'Natureza SICASE', key: 'codigoTj', formatter: (value) => this.getTipoAtoTribunalByCodigo(value), group: 'SICASE'})
      formatters.push({label: 'Data Base de Cobrança', key: 'dataBase', type: 'date', group: 'Emolumentos & Base de Cálculo'})
      formatters.push({label: 'Idade da Matrícula', key: 'dto.camposAdicionais.IDADE_FICHA', type: 'integer', group: 'Emolumentos & Base de Cálculo'})
    }

    let descontoUnidadeAutonoma = 'Aplicar cobrança de 2/3 referente Unidade Autônoma';
    if(VueBusiness.getVueRoot().isEstadoPR){
      descontoUnidadeAutonoma = 'Aplicar cobrança de 80% referente Unidade Autônoma';
    }

    formatters = [...formatters, ...[
      {label: 'Valor de Emolumentos', key: 'emolumentos', type: 'currency', group: 'Emolumentos & Base de Cálculo'},
      {label: 'Valor Total', key: 'valor', type: 'currency', group: 'Emolumentos & Base de Cálculo'},

      {label: 'Custa Diferida', key: 'diferido', type: 'boolean', group: 'Emolumentos & Base de Cálculo'},
      {label: 'Motivo Diferimento', key: 'motivoDiferimento', type: 'enum', group: 'Emolumentos & Base de Cálculo', lista: RegraCustasBusiness.getMotivosIsencao(VueBusiness.getVueRoot().config.estado),},

      {label: 'Tipo Registro', key: 'dto.camposAdicionais.TIPO_REGISTRO', type: 'enum', lista: TabelaCustaBusiness.listTiposRegistro(), group: 'Emolumentos & Base de Cálculo'},
      {label: 'Tipo Registro', key: 'tipoRegistro', type: 'enum', lista: TabelaCustaBusiness.listTiposRegistro(), group: 'Emolumentos & Base de Cálculo'},
      {label: 'Quantidade', key: 'quantidade', type: 'integer', group: 'Emolumentos & Base de Cálculo'},
      {label: 'Isento', key: 'isento', type: 'boolean', group: 'Emolumentos & Base de Cálculo'},

      {label: 'Motivo da Isenção', key: 'ressarcimento.motivoIsencao', type: 'enum', lista: RegraCustasBusiness.getMotivosIsencao(VueBusiness.getVueRoot().config.estado), group: 'Emolumentos & Base de Cálculo'},
      {label: 'Tipo de processo', key: 'ressarcimento.tipoProcesso', type: 'enum', lista: RegraCustasBusiness.getTiposProcessoRessarcimento(VueBusiness.getVueRoot().config.estado), group: 'Emolumentos & Base de Cálculo'},
      {label: 'Número do processo/ofício', key: 'ressarcimento.numeroProcesso', type: 'string', group: 'Emolumentos & Base de Cálculo'},

      {label: 'Valor de Negócio/Alienação', key: 'dto.valoresBaseCalculo.alienacao', type: 'currency', group: 'Base de Cálculo'},
      {label: 'Valor pago em espécie ou título ao portador', key: 'dto.alienacaoPagoEspecie', type: 'boolean', group: 'Base de Cálculo'},
      {label: 'Valor de Avaliação Fiscal', key: 'dto.valoresBaseCalculo.valorAvaliacao', type: 'currency', group: 'Base de Cálculo'},
      {label: 'Valor da Dívida', key: 'dto.valoresBaseCalculo.valorDivida', type: 'currency', group: 'Base de Cálculo'},
      {label: 'Valor Atribuído ao Imóvel', key: 'dto.valoresBaseCalculo.valorImovel', type: 'currency', group: 'Base de Cálculo'},
      {label: 'Valor de Mercado', key: 'dto.valoresBaseCalculo.valorMercado', type: 'currency', group: 'Base de Cálculo'},
      {label: 'Valor Venal', key: 'dto.valoresBaseCalculo.valorVenal', type: 'currency', group: 'Base de Cálculo'},
      {label: 'Valor meação', key: 'dto.valoresBaseCalculo.valorMeacao', type: 'currency', group: 'Base de Cálculo'},
      {label: 'Valor leilão', key: 'dto.valoresBaseCalculo.valorLeilao', type: 'currency', group: 'Base de Cálculo'},
      {label: 'Recursos Próprios', key: 'dto.valoresBaseCalculo.recursosProprios', type: 'currency', group: 'Base de Cálculo'},
      {label: 'FGTS', key: 'dto.valoresBaseCalculo.fgts', type: 'currency', group: 'Base de Cálculo'},
      {label: 'FGTS (Desconto)', key: 'dto.valoresBaseCalculo.fgtsDesconto', type: 'currency', group: 'Base de Cálculo'},
      {label: 'Valores atualizados', key: 'dto.camposAdicionais.VALORES_ATUALIZADOS', type: 'boolean', group: 'Base de Cálculo'},


      {label: 'Moeda', key: 'dto.camposAdicionais.MOEDA_ALIENACAO', type: 'enum', lista: ProtocoloBusiness.getMoedas(), group: 'Valores atualizados - Negócio/Alienação'},
      {label: 'Índice de Atualização', key: 'dto.camposAdicionais.INDICE_ALIENACAO', type: 'enum', lista: ProtocoloBusiness.getIndiceCorrecao(), group: 'Valores atualizados - Negócio/Alienação'},
      {label: 'Início', key: 'dto.camposAdicionais.DATA_INICIO_ALIENACAO', type: 'date', group: 'Valores atualizados - Negócio/Alienação'},
      {label: 'Fim', key: 'dto.camposAdicionais.DATA_FIM_ALIENACAO', type: 'date', group: 'Valores atualizados - Negócio/Alienação'},
      {label: 'Valor atualizado', key: 'dto.valoresBaseCalculo.alienacaoAtualizado', type: 'currency', group: 'Valores atualizados - Negócio/Alienação'},

      {label: 'Moeda', key: 'dto.camposAdicionais.MOEDA_AVALIACAO', type: 'enum', lista: ProtocoloBusiness.getMoedas(), group: 'Valores atualizados - Avaliação Fiscal'},
      {label: 'Índice de Atualização', key: 'dto.camposAdicionais.INDICE_AVALIACAO', type: 'enum', lista: ProtocoloBusiness.getIndiceCorrecao(), group: 'Valores atualizados - Avaliação Fiscal'},
      {label: 'Início', key: 'dto.camposAdicionais.DATA_INICIO_AVALIACAO', type: 'date', group: 'Valores atualizados - Avaliação Fiscal'},
      {label: 'Fim', key: 'dto.camposAdicionais.DATA_FIM_AVALIACAO', type: 'date', group: 'Valores atualizados - Avaliação Fiscal'},
      {label: 'Valor atualizado', key: 'dto.valoresBaseCalculo.valorAvaliacaoAtualizado', type: 'currency', group: 'Valores atualizados - Avaliação Fiscal'},

      {label: 'Moeda', key: 'dto.camposAdicionais.MOEDA_DIVIDA', type: 'enum', lista: ProtocoloBusiness.getMoedas(), group: 'Valores atualizados - Dívida'},
      {label: 'Índice de Atualização', key: 'dto.camposAdicionais.INDICE_DIVIDA', type: 'enum', lista: ProtocoloBusiness.getIndiceCorrecao(), group: 'Valores atualizados - Dívida'},
      {label: 'Início', key: 'dto.camposAdicionais.DATA_INICIO_DIVIDA', type: 'date', group: 'Valores atualizados - Dívida'},
      {label: 'Fim', key: 'dto.camposAdicionais.DATA_FIM_DIVIDA', type: 'date', group: 'Valores atualizados - Dívida'},
      {label: 'Valor atualizado', key: 'dto.valoresBaseCalculo.valorDividaAtualizado', type: 'currency', group: 'Valores atualizados - Dívida'},

      {label: descontoUnidadeAutonoma, key: 'dto.camposAdicionais.DESCONTO_UNIDADE_AUTONOMA', type: 'boolean', group: 'Emolumentos & Base de Cálculo'},
      {label: 'Cobrar informação eletrônica?', key: 'dto.camposAdicionais.COBRAR_INFORMACAO_ELETRONICA', type: 'boolean', group: 'Emolumentos & Base de Cálculo'},
      {label: 'Compra e venda do terreno com mútuo para construção', key: 'dto.camposAdicionais.MUTUO_CONSTRUCAO', type: 'boolean', group: 'Emolumentos & Base de Cálculo'},

      {label: 'Quantidade de unidades', key: 'dto.camposAdicionais.QUANTIDADE_UNIDADE', type: 'integer', group: 'Emolumentos & Base de Cálculo'},
      {label: 'Quilômetros percorridos', key: 'dto.camposAdicionais.QUILOMETROS_PERCORRIDOS', type: 'integer', group: 'Emolumentos & Base de Cálculo'},
      {label: 'Quantidade de páginas', key: 'dto.camposAdicionais.QUANTIDADE_PAGINAS', type: 'integer', group: 'Emolumentos & Base de Cálculo'},
      {label: 'Quantidade de folhas', key: 'dto.camposAdicionais.QUANTIDADE_FOLHAS', type: 'integer', group: 'Emolumentos & Base de Cálculo'},
      {label: 'Quantidade de cópias', key: 'dto.camposAdicionais.QUANTIDADE_COPIAS', type: 'integer', group: 'Emolumentos & Base de Cálculo'},
      {label: 'Quantidade lote/gleba', key: 'dto.camposAdicionais.QUANTIDADE_LOTE_GLEBA', type: 'integer', group: 'Emolumentos & Base de Cálculo'},
      {label: 'Quantidade de atos', key: 'dto.camposAdicionais.QUANTIDADE_ATOS', type: 'integer', group: 'Emolumentos & Base de Cálculo'},
      {label: 'Quantidade de quesitos', key: 'dto.camposAdicionais.QUANTIDADE_QUESITOS', type: 'integer', group: 'Emolumentos & Base de Cálculo'},
      {label: 'Quantidade de informação verbal e busca', key: 'dto.camposAdicionais.QUANTIDADE_BUSCAS', type: 'integer', group: 'Emolumentos & Base de Cálculo'},

      {label: 'Data da incorporação', key: 'dto.camposAdicionais.DATA_INCORPORACAO', type: 'date', group: 'Emolumentos & Base de Cálculo'},
      {label: 'Área total', key: 'dto.camposAdicionais.AREA_TOTAL', type: 'decimal', group: 'Emolumentos & Base de Cálculo'},
      {label: 'Emolumentos cobrados por unidade no registro?', key: 'dto.camposAdicionais.EMOLUMENTOS_COBRADOS_POR_UNIDADE', type: 'boolean', group: 'Emolumentos & Base de Cálculo'},

      /**********************************************/

      {label: 'Tributos e Recolhimentos', key: 'recolhimentos', type: 'string', formatter: (value) => this.recolhimentos(value)},

      /**********************************************/

      {label: 'Ato Retificado', key: 'atoRetificado', type: 'string', formatter: (value) => this.atoRetificado(value)},

      /**********************************************/

      {label: 'Situação da construção', key: 'doi.situacaoConstrucao', type: 'enum', lista: ProtocoloBusiness.getSituacoesConstrucao(), group: 'DOI'},
      {label: 'Forma da alienação', key: 'doi.formaAlienacao', type: 'enum', lista: ProtocoloBusiness.getFormasAlienacao(), group: 'DOI'},
      {label: 'Data da alienação', key: 'doi.dataAlienacao', type: 'date', group: 'DOI'},
      {label: 'Valor da alienação', key: 'doi.valorAlienacao', type: 'currency', group: 'DOI'},
      {label: 'Valor da avaliação', key: 'doi.valorAvaliacao', type: 'currency', group: 'DOI'},

      {label: 'Forma da alienação', key: 'doi.areaConsta', type: 'enum', lista: ProtocoloBusiness.getFormasAlienacao(), group: 'DOI'},
      {label: 'Área', key: 'doi.area', type: 'decimal', group: 'DOI'},
      {label: 'Descrição do Imóvel', key: 'doi.descricaoImovel', type: 'string', group: 'DOI'},
      {label: 'O imóvel foi objeto de alienação fiduciária', key: 'doi.objetoAlienacaoFiduciaria', type: 'boolean', group: 'DOI'},
      {label: 'Última Parcela', key: 'doi.ultimaParcela', type: 'date', group: 'DOI'},
      {label: 'Valor pago até data do ato', key: 'doi.valorPago', type: 'currency', group: 'DOI'},
      {label: 'Valor pago em moeda corrente', key: 'doi.valorPagoMoedaCorrente', type: 'currency', group: 'DOI'},
      {label: 'Área Construída (m²)', key: 'doi.areaConstruida', type: 'decimalSimples', group: 'DOI'},
      {label: 'DOI já foi emitida no título que está sendo registrado', key: 'doi.emitida', type: 'boolean', group: 'DOI'},

      {label: 'DOITU', key: 'doi.tipoTransacaoDoitu', type: 'enum', lista: ProtocoloBusiness.getTiposTransacao(), group: 'DOI'},

      /*
      "areaConsta": "AREA_ESTA_SENDO_INFORMADA",
       */

      /**********************************************/

      {label: 'Número do Livro', key: 'numeroControleLivro', group: 'Informações do Livro'},
      {label: 'Quantidade de Folhas', key: 'quantidadeFolhas', group: 'Informações do Livro'},
      {label: 'Folha Inicial', key: 'numeroControleFolha', group: 'Informações do Livro'},
      {label: 'Lado Inicial', key: 'ladoPaginaControleInicial', group: 'Informações do Livro'},
      {label: 'Folha Final', key: 'numeroControleFolhaFinal', group: 'Informações do Livro'},
      {label: 'Lado Inicial', key: 'ladoPaginaControleFinal', group: 'Informações do Livro'},

      /**********************************************/

      {label: 'Incluir no livro estrangeiro?', key: 'incluirAtoLivroEstrangeiro', type: 'boolean', group: 'Livro Estrangeiro'},
      {label: 'Código do Livro Estrangeiro', key: 'livroEstrangeiro', type: 'integer', group: 'Livro Estrangeiro'},
      {label: 'Descrição do Livro Estrangeiro', key: 'observacaoLivroEstrangeiro', type: 'string', group: 'Livro Estrangeiro'},

      /**********************************************/

      {label: 'Documentos', key: 'indicadorDocumentos', type: 'string', formatter: (value) => this.indicadorDocumentos(value)},

      /**********************************************/

      {label: 'Envolvidos', key: 'envolvidos', type: 'string', formatter: (value) => this.envolvidos({envolvidos: value})},

      /**********************************************/

      {label: 'Bens e Garantias', key: 'indicadorGarantias', type: 'string', formatter: (value) => this.indicadorGarantias(value)},

      /**********************************************/

      {label: 'Indicador Real', key: 'dto.indicador', formatter: (value) => this.indicadorReal(value), group: 'Alterações no Indicador'},
      {label: 'Registro Auxiliar', key: 'dto', formatter: (value) => this.registroAuxiliar(value), group: 'Alterações no Indicador'},
      {label: 'Restrições', key: 'dto', formatter: (value) => this.configuracaoRestricao(value), group: 'Restrições'},
      {label: 'Múltiplas Cobranças', key: 'dto', formatter: (value) => this.multiplasCobrancas(value), group: 'Emolumentos & Base de Cálculo'},

      /**********************************************/

      {label: 'Índices', key: 'indices', type: 'checklist', group: 'Checklist'},
      {label: 'Exigências', key: 'exigencias', type: 'listaHtml', group: 'Exigências'},

      {label: 'Texto', key: 'texto', type: 'string', group: 'Minuta'},
    ]];
    return formatters
  },

  async auditarTela(params = {}){
    let {entidade, id, descricao} = params;
    if(!Utils.testUUID(id) || !entidade){
      return;
    }
    return Http.post(`${path}/tela/${entidade}/${id}`, {descricao});
  },

  async auditarAcao(params = {}){
    let {entidade, id, descricao} = params;
    if(!entidade){
      return;
    }
    return Http.post(`${path}/execucao/${entidade}${id ? '/'+id : ''}`, {descricao});
  },

  enumEntidadeExecucao(params = {}) {
    let {dominio, entidade} = params;
    if (dominio) {
      entidade += `_${dominio}`;
    }
    return entidade;
  },

  trataEnumTipoImovel(row){
    let texto;
    switch (row) {
      case 'APARTAMENTO':
        texto = 'Apartamento';
        break;
      case 'LOTE':
        texto = 'Lote';
        break;
      case 'TERRENO':
        texto = 'Terreno';
        break;
      case 'CASA':
        texto = 'Casa';
        break;
      case 'FAZENDA':
        texto = 'Fazenda';
        break;
      case 'SITIO':
        texto = 'Sítio';
        break;
      case 'CHACARA':
        texto = 'Chácara';
        break;
      case 'GALPAO':
        texto = 'Galpão';
        break;
      case 'PREDIO_COMERCIAL':
        texto = 'Prédio Comercial';
        break;
      case 'PREDIO_RESIDENCIAL':
        texto = 'Prédio Residencial';
        break;
      case 'SALA_COMERCIAL':
        texto = 'Sala Comercial';
        break;
      case 'LOJA':
        texto = 'Loja';
        break;
      case 'ESCANINHO':
        texto = 'Escaninho';
        break;
      case 'HOBBY_BOX':
        texto = 'Hobby Box';
        break;
      case 'VAGA_GARAGEM':
        texto = 'Vaga  de Garagem';
        break;
      case 'BOXE_GARAGEM':
        texto = 'Box de Garagem';
        break;
      case 'OUTROS':
        texto = 'Outros';
        break;
      case 'SALA':
        texto = 'Sala';
        break;
      case 'CONJUNTO_SALAS':
        texto = 'Conjunto de Salas';
        break;
      case 'SOBRELOJA':
        texto = 'Sobreloja';
        break;
      case 'SALA_CONJUNTO':
        texto = 'Sala Conjunto';
        break;
      case 'CONSTRUCOES':
        texto = 'Construções';
        break;
      case 'DESDOBRAMENTO':
        texto = 'Desdobramento';
        break;
      case 'LAJE':
        texto = 'Laje';
        break;
      case 'ESTACIONAMENTO':
        texto = 'Estacionamento';
        break;
      case 'BARRACO':
        texto = 'Barraco';
        break;
      default:
        texto = row;
    }
    return texto;
  },

}
