
export default {
  validateLongArray: (params = {values, max, min}) => {
    const {values = [], max = 9223372036854775807, min = -9223372036854775808} = params;

    return values.every((value) => {
      const numero = Number(value);
      return !Number.isNaN(numero) && typeof numero === "number"
        && Number.isFinite(numero) && Number.isSafeInteger(numero)
        && numero >= min && numero <= max;
    });
  }
}
